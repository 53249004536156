@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,200;9..40,300;9..40,400;9..40,500;9..40,600&family=Frank+Ruhl+Libre:wght@300;400;600&family=Roboto:wght@300;400;500;700&display=swap");

:root {
	--off-white: #f2f2f2;
	--bg-white: #ffffff;
	--static-white: #ffffff;
	--primary: #000;
	--semi-primary: #333;
	--theme-yellow: #c29958;
	--shape-bg: #f8f8f8;
	--title-color: #2c2c2c;
	--ribbon: #fd9c2e;
	--stoke: #111;
	--whishlist-color: #af9453;
	--theme-yellow-rgb: rgba(194, 153, 88, 0.3);
	--theme-yellow-rgb-light: rgba(194, 153, 88, 0.1);
	--theme-light-yellow-rgb: rgba(194, 153, 88, 0.1);
	--price-tag: rgba(106, 176, 112, 0.4);
	--brand-color: hsl(46, 100%, 50%);
	--white-linear: linear-gradient(30deg, #b3b2af 0, #f7f7f7 55%, #f7f7f7 60%, #b3b2af 100%);
	--yellow-linear: linear-gradient(30deg, #e1b94f 0, #fcf1d5 55%, #fcf1d5 60%, #e1b94f 100%);
	--rose-linear: linear-gradient(30deg, #ec8a55 0, #fcede2 55%, #fcede2 60%, #ec8a55 100%);
	--white-gold-linear: linear-gradient(30deg, #b3b2af 0, #f7f7f7 55%, #f7f7f7 60%, #b3b2af 100%);
	--bg-black-linear: linear-gradient(180deg, hsla(0, 0%, 0%, 0) 0%, hsla(0, 0%, 0%, 0.3) 10%, hsl(0, 0%, 0%) 100%);

	--theme-bg-color: #edf9ff;
	--theme-title: #003f60;
	--theme-gray-color: #777777;
	--theme-yellow-color: #fabc5f;
	--theme-yellow-color-rgba: rgb(250, 188, 95, 0.2);
}

/* ======  {{ START:: search input CSS }}  ====== */

.search--box-wrapper {
	max-width: 250px;
	width: 100%;
	margin: 0 auto;
}

.search--box-wrapper .search--input {
	padding: 0px 20px;
	height: 40px;
	line-height: 34px;
	border: 1px solid var(--theme-yellow);
	background-color: rgba(194, 153, 88, 0.2);
	border-radius: 50px;
	font-size: 12px;
}

.search--box-wrapper .search--input::placeholder {
	font-size: 12px;
	text-transform: capitalize;
	color: var(--primary);
}

.search--box-wrapper .search--data-box {
	position: fixed;
	top: 81px;
	padding: 12px;
	max-width: 250px;
	width: 100%;
	height: auto;
	background-color: var(--bg-white);
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
	border-radius: 0px 0px 8px 8px;
	z-index: 12;
}

.search--box-wrapper .search--data-box a {
	display: flex;
	align-items: start;
	margin-bottom: 8px;
	padding-bottom: 8px;
	border-bottom: 1px solid var(--theme-yellow-rgb);
	padding: 8px;
	transition: all 0.3s ease-in-out;
	border-radius: 8px 8px 0px 0px;
}

.search--box-wrapper .search--data-box a:last-child {
	margin-bottom: 0px;
}

.search--box-wrapper .search--data-box a img {
	width: 50px;
	height: 50px;
	object-fit: cover;
	border-radius: 50%;
}

.search--box-wrapper .search--data-box .search--title {
	font-size: 12px;
	line-height: 20px;
	max-height: 40px;
	color: #202020;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	white-space: break-spaces;
}

.search--box-wrapper .search--data-box .seach--price {
	font-size: 14px;
	line-height: 20px;
	color: var(--theme-yellow);
	font-weight: 500;
}

.search--box-wrapper .data--not-found {
	font-size: 14px;
	text-transform: capitalize;
}

.search--box-wrapper .search--data-box a:hover .search--title {
	color: var(--theme-yellow);
}

.search--box-wrapper .search--data-box a:hover {
	background-color: var(--theme-yellow-rgb-light);
	border-bottom: 1px solid var(--theme-yellow);
}

/* ======  {{ END:: search input CSS }}  ====== */

/* ======  {{ START:: Title CSS }} ====== */
.education-container h1 {
	font-size: 26px;
	font-weight: 400;
	margin: 10px 0px 20px 0px;
	font-family: "DM Sans";
	color: var(--primary);
}

.shape-container .shape-title h1 {
	font-size: 26px;
	font-weight: 400;
	color: var(--theme-yellow);
	font-family: "DM Sans";
}

/* ======  {{ END:: Title CSS }} ====== */

/* ======  {{ START:: Hover CSS }}  ====== */
.education-container .education-card-content a:hover h3,
.shape-container .shape-content .shop-content-link li a:hover span {
	color: var(--theme-yellow);
}

/* ======  {{ END:: Hover CSS }}  ====== */

/* ======  {{ START:: Slider button css }} ====== */
.slider-container .slider-content .slick-prev,
.slider-container .slider-content .slick-next,
.style-slider-container .style-slider-content .slick-prev,
.style-slider-container .style-slider-content .slick-next {
	z-index: 1;
	top: -40px;
	width: 55px;
	height: 35px;
	border-radius: 100px;
	border: 1px solid var(--theme-light-yellow-rgb);
}

.slider-container .slider-content .slick-prev::before,
.slider-container .slider-content .slick-next::before,
.style-slider-container .style-slider-content .slick-prev::before,
.style-slider-container .style-slider-content .slick-next::before {
	content: "";
	position: absolute;
	top: 1px;
	left: 1px;
	width: 30px;
	height: 30px;
	background-color: var(--theme-yellow-color-rgba);
	border-radius: 100%;
	transition: all 0.3s ease-in-out;
}

.slider-container .slider-content .slick-prev::after,
.slider-container .slider-content .slick-next::after,
.style-slider-container .style-slider-content .slick-prev::after,
.style-slider-container .style-slider-content .slick-next::after {
	position: absolute;
	content: "";
	top: 6px;
	left: 6px;
	width: 20px;
	height: 20px;
	background-image: url(../../image/right-arrow.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	transition: all 0.3s ease-in-out;
}

.slider-container .slider-content .slick-prev:hover::before,
.slider-container .slider-content .slick-next:hover::before,
.style-slider-container .style-slider-content .slick-prev:hover::before,
.style-slider-container .style-slider-content .slick-next:hover::before {
	width: 100%;
	height: 100%;
	top: 0px;
	background-color: var(--theme-yellow);
	border-radius: 100px;
}

.slider-container .slider-content .slick-next:hover::after,
.style-slider-container .style-slider-content .slick-next:hover::after {
	transform: rotate(180deg) translateX(9px);
}

.slider-container .slider-content .slick-prev:hover::after,
.style-slider-container .style-slider-content .slick-prev:hover::after {
	transform: translateX(9px);
}

.slider-container .slider-content .slick-next::after,
.style-slider-container .style-slider-content .slick-next::after {
	transform: rotate(180deg);
}

.slider-container .slider-content .slick-next,
.style-slider-container .style-slider-content .slick-next {
	right: 5%;
}

.slider-container .slider-content .slick-prev,
.style-slider-container .style-slider-content .slick-prev {
	left: auto;
	right: 0px;
}

.slider-container .slider-content .slick-next::after,
.style-slider-container .style-slider-content .slick-next::after {
	left: auto;
	right: 6px;
}

.slider-container .slider-content .slick-next::before,
.style-slider-container .style-slider-content .slick-next::before {
	left: auto;
	right: 0px;
}

/* ======  {{ END:: Slider button css }} ====== */

/* ----- {{ START:: Hero section CSS }} ----- */
.hero-section .hero-content {
	max-width: 650px;
	width: 100%;
	height: auto;
}

.hero-section .hero-content .hero-body {
	max-width: 352px;
	width: 100%;
	margin: auto;
}

.hero-section .hero-content .hero-body h1 {
	font-size: 30px;
	font-weight: 500;
	font-family: "DM Sans";
	letter-spacing: 0.05em;
	line-height: 1.5;
	margin-bottom: 0.5em;
}

.hero-section .hero-content .hero-body p {
	font-size: 16px;
	color: var(--off-white);
	font-weight: 400;
	font-family: "DM Sans";
	margin: 0px;
}

.hero-section .hero-content .hero-body .btn-hero {
	background-color: var(--bg-white);
	padding: 0.55em 1.2em 0.65em !important;
	letter-spacing: 0.05em;
	color: var(--primary);
	font-family: "DM Sans";
	border-radius: 0px;
	max-width: 300px;
	width: 100%;
	margin-bottom: 15px;
}

.hero-section .hero-content .hero-body .btn-hero:hover {
	background-color: var(--theme-yellow);
	color: var(--off-white);
}

/* ----- {{ END:: Hero section CSS }} ----- */

/* ----- {{ START:: Shape section CSS }} ----- */
.shape-container {
	background-color: var(--theme-light-yellow-rgb);
	padding: 50px 0px 50px 0px;
	/* margin-bottom: 50px; */
}

.shape-container .shape-title {
	margin-bottom: 20px;
}

.shape-container .shape-content .shop-content-link {
	display: flex;
	align-items: center;
	justify-content: space-between;
	letter-spacing: 0.05em;
}

.shape-container .shape-content .shop-content-link li {
	text-align: center;
}

.shape-container .shape-content .shop-content-link li a span {
	text-transform: capitalize;
	font-size: 14px;
	font-family: "DM Sans";
	font-weight: 400;
	display: block;
	color: var(--theme-gray-color);
}

.shape-container .shape-content .shop-content-link li a i {
	background-repeat: no-repeat;
	background-size: 700px auto;
	transition: transform 0.2s ease-in 0.1s;
	display: block;
	min-width: 70px;
	width: 70px;
	height: 70px;
	margin: 0 auto;
	margin-bottom: 15px;
}

.shape-container .shape-content .shop-content-link li a.active span {
	position: relative;
	color: var(--theme-yellow);
}

.shape-container .shape-content .shop-content-link li a.active span::after {
	content: "";
	position: absolute;
	width: 63%;
	height: 2px;
	border-radius: 8px;
	background-color: var(--theme-yellow);
	top: auto;
	bottom: 0px;
	left: 13px;
}

.shape-container .shape-content .shop-content-link li a:hover i {
	transform: scale(1.2, 1.2);
}

.shape-container .shape-content .shop-content-link li .round i {
	background-position: 0 0;
}

.shape-container .shape-content .shop-content-link li .princess i {
	background-position: 11.1111111111% 0;
}

.shape-container .shape-content .shop-content-link li .cushion i {
	background-position: 22.2222222222% 0;
}

.shape-container .shape-content .shop-content-link li .asscher i {
	background-position: 33.3333333333% 0;
}

.shape-container .shape-content .shop-content-link li .marquise i {
	background-position: 44.4444444444% 0;
}

.shape-container .shape-content .shop-content-link li .oval i {
	background-position: 55.5555555556% 0;
}

.shape-container .shape-content .shop-content-link li .radiant i {
	background-position: 66.6666666667% 0;
}

.shape-container .shape-content .shop-content-link li .pear i {
	background-position: 77.7777777778% 0;
}

.shape-container .shape-content .shop-content-link li .emeralds i {
	background-position: 88.8888888889% 0;
}

.shape-container .shape-content .shop-content-link li .heart i {
	background-position: 100% 0;
}

.shape-container .shape-content .shop-content-link li .sapphire i {
	background-position: 0 0;
}

.shape-container .shape-content .shop-content-link li .emerald i {
	background-position: 11.1111111111% 0;
}

.shape-container .shape-content .shop-content-link li .moissanite i {
	background-position: 22.2222222222% 0;
}

.shape-container .shape-content .shop-content-link li .lab-created-colored-diamonds i {
	background-position: 33.3333333333% 0;
}

.shape-container .shape-content .shop-content-link li .aquamarine i {
	background-position: 44.4444444444% 0;
}

.shape-container .shape-content .shop-content-link li .morganite i {
	background-position: 55.5555555556% 0;
}

.shape-container .shape-content .shop-content-link li .alexandrite i {
	background-position: 66.6666666667% 0;
}

.shape-container .shape-content .shop-content-link li .ruby i {
	background-position: 77.7777777778% 0;
}

.shape-container .shape-content .shop-content-link li .natural-colored-diamonds i {
	background-position: 88.8888888889% 0;
}

.shape-container .shape-content .shop-content-link li .tourmaline i {
	background-position: 100% 0;
}

/* ----- {{ END:: Shape section CSS }} ----- */

/* ----- {{ START:: Feature section CSS }} ----- */
.feature-container {
	padding: 70px 0px;
}

.feature-container .feature-card {
	padding: 0px;
	border: 0px;
	position: relative;
	display: block;
	height: auto;
	max-width: 100%;
	line-height: 1.428571429;
	background-color: var(--bg-white);
	transition: all 0.2s ease-in-out;
}

.feature-container .feature-card .caption {
	position: absolute;
	padding: 9px 0px;
	left: 60px;
	bottom: 60px;
	max-width: 265px;
	width: 100%;
}

.feature-container .feature-card .caption h2 {
	font-size: 26px;
	font-weight: 400;
	color: var(--theme-bg-light);
	letter-spacing: 0.025em;
	margin: 10px 0px;
	font-family: "DM Sans";
	padding: 10px;
	border-radius: 4px;
}

.feature-container .feature-card .caption p {
	margin: 20px 0px 30px 0px;
	color: var(--theme-bg-color);
	font-size: 14px;
	/* opacity: 0.75; */
	font-family: "DM Sans";
	letter-spacing: 0.025em;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	padding: 10px;
	max-height: 90px;
	border-radius: 4px;
	line-height: 25px;
}

.feature-container .feature-card .caption .feature-btn .btn--feature:nth-child(1) {
	margin-bottom: 10px;
}

.feature-container .feature-card a.img--box img {
	height: 550px;
	width: 100%;
	object-fit: cover;
}

/* ----- {{ END:: Feature section CSS }} ----- */

/* ----- {{ START:: Education Section CSS }} ----- */
.education-container {
	padding: 50px 0px;
}

.education-container .education-card-content .caption {
	padding: 9px 0px;
}

.education-container .education-card-content .caption h3 {
	font-size: 14px;
	margin: 10px 0px;
	letter-spacing: 0.025em;
	text-transform: capitalize;
	font-family: "DM Sans";
	color: var(--semi-primary);
}

.education-container .education-card-content .caption p {
	letter-spacing: 0.025em;
	font-size: 14px;
	color: var(--semi-primary);
	font-family: "DM Sans";
	line-height: 20px;
}

.education-container .education-card-content a picture {
	overflow: hidden;
	display: block;
}

.education-container .education-card-content picture img {
	transition: 0.25s ease-in-out;
	width: 300px;
	height: 300px;
}

/* ----- {{ END:: Education Section CSS }} ----- */

/* =====  {{ START:: Ring slider component CSS }} ===== */
.slider-container .slider-content .slider-title-context h2,
.style-slider-container .style-slider-content .slider-title-context h2 {
	font-family: "DM Sans";
	font-size: 26px;
	font-weight: 400;
	color: var(--primary);
	text-align: left;
	text-transform: capitalize;
	letter-spacing: 0.025em;
	margin: 10px 0px;
}

.slider-container .slider-content .slider-title-context .btn--slider,
.style-slider-container .style-slider-content .slider-title-context .btn--slider {
	max-width: 180px;
	width: 100%;
	height: 34px;
	margin-left: auto;
	margin-right: 150px;
	border-radius: 100px;
	font-size: 17px;
	text-transform: capitalize;
	font-family: "DM Sans";
	position: relative;
	display: flex;
	align-items: center;
	z-index: 12;
	justify-content: center;
}

.slider-container .slider-content .slider-title-context .btn--slider svg,
.style-slider-container .style-slider-content .slider-title-context .btn--slider svg {
	position: relative;
	top: 0px;
	margin-left: 10px;
	fill: none;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke: var(--stoke);
	stroke-width: 2;
	transform: translateX(-5px);
	transition: all 0.3s ease;
	display: inline-block;
}

.slider-container .slider-content .slider-title-context .btn--slider:active,
.style-slider-container .style-slider-content .slider-title-context .btn--slider:active {
	transform: scale(0.96);
}

.slider-container .slider-content .slider-title-context .btn--slider::before,
.style-slider-container .style-slider-content .slider-title-context .btn--slider::before {
	content: "";
	position: absolute;
	top: 0px;
	left: 0px;
	display: block;
	border-radius: 30px;
	background-color: var(--theme-yellow-rgb);
	width: 35px;
	height: 35px;
	transition: all 0.3s ease;
	z-index: -1;
}

.slider-container .slider-content .slider-title-context .btn--slider:hover::before,
.style-slider-container .style-slider-content .slider-title-context .btn--slider:hover::before {
	width: 100%;
	background-color: var(--theme-yellow);
}

.slider-container .slider-content .slider-title-context .btn--slider:hover svg,
.style-slider-container .style-slider-content .slider-title-context .btn--slider:hover svg {
	transform: translateX(0);
	stroke: var(--static-white);
}

.slider-container .slider-content .slider-title-context .btn--slider:hover,
.style-slider-container .style-slider-content .slider-title-context .btn--slider:hover {
	color: var(--static-white);
}

.slider-container .slider-content .slider-inner-content .slider-content-link .slider-caption p,
.style-slider-container .style-slider-content .slider-inner-content .slider-content-link .slider-caption p {
	color: var(--primary);
	font-family: "DM Sans";
	padding: 9px 0px 15px 0px;
	letter-spacing: 0.025em;
	font-size: 14px;
	font-weight: 400;
	text-transform: capitalize;
	text-align: center;
}

.slider-container .slider-content .slider-inner-content,
.style-slider-container .style-slider-content .slider-inner-content {
	padding: 0px 4px;
}

.style-slider-container .style-slider-content .slider-inner-content .slider-content-link img {
	height: 280px;
	width: 100%;
	object-fit: cover;
}

.slider-inner-content .ring-slider--img {
	width: 100%;
	height: 280px;
	object-fit: cover;
}

.slider-container .slider-content .slick-list .slick-track .slick-slide,
.style-slider-container .style-slider-content .slick-list .slick-track .slick-slide {
	transform: scaleX(0.9) scaleY(0.9);
	transition: all 0.3s ease-in-out;
}

.slider-container .slider-content .slick-list .slick-track .slick-center,
.style-slider-container .style-slider-content .slick-list .slick-track .slick-center {
	transform: scale(1.1);
	transition: all 0.3s ease-in-out;
}

/* =====  {{ END:: Ring slider component CSS }} ===== */

/* =====  {{ START:: Pagination Component CSS }}  ===== */
.pagination {
	width: fit-content;
	margin: auto;
	border: 0.5px solid #dedede;
	padding: 4px;
	border-radius: 50px;
}

.pagination .page-item {
	width: fit-content;
	margin-right: 6px;
	text-align: center;
	overflow: hidden;
	border-radius: 5px;
	background: rgb(140, 140, 140, 0.1);
}

.pagination .page-item.active {
	width: 50px;
	background: var(--theme-yellow);
}

.pagination .page-item.active .page-link {
	color: var(--off-white) !important;
	font-size: 18px;
}

.pagination .page-item:first-child {
	width: 50px;
	border-top-left-radius: 30px;
	border-bottom-left-radius: 30px;
}

.pagination .page-item:last-child {
	width: 50px;
	margin-right: 0px;
	border-top-right-radius: 30px;
	border-bottom-right-radius: 30px;
}

.pagination .page-item .page-link {
	border: none;
	color: var(--primary);
	background: transparent;
	display: block;
}

.pagination .page-item .page-link:focus {
	box-shadow: none;
}

.pagination .page-item .page-link i {
	outline: none !important;
}

.pagination .page-item .page-link i::before {
	line-height: 19px;
}

/* =====  {{ END:: Pagination Component CSS }}  ===== */

/* =====  {{ START:: Rageslider component CSS }} ===== */
.rageslider-component {
	position: relative;
	max-width: 450px;
	width: 100%;
	margin: 0 auto;
}

.rageslider-component .noUi-horizontal {
	height: 5px;
	border-radius: 100px;
	background-color: var(--theme-yellow);
}

.rageslider-component .noUi-horizontal .noUi-handle {
	width: 18px;
	height: 18px;
	border-radius: 100%;
	top: -8px;
	cursor: pointer;
}

.rageslider-component .noUi-horizontal .noUi-handle::after,
.rageslider-component .noUi-horizontal .noUi-handle::before {
	width: 0;
	height: 0;
}

.rageslider-component .noUi-connects {
	background-color: var(--off-white);
}

.rageslider-component .noUi-connects .noUi-connect {
	background: var(--theme-yellow);
}

.rageslider-component .noUi-horizontal .noUi-tooltip {
	padding: 2px 10px;
	border-radius: 100px;
}

/* =====  {{ END:: Rageslider component CSS }} ===== */

/* ===== {{ START:: dynamic card css }} ===== */

.card-container .card {
	border-radius: 8px;
	height: 25rem;
	box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
	z-index: 12;
}

.card-container .card .ribbon {
	position: absolute;
	display: block;
	left: 10px;
	top: -3px;
	filter: drop-shadow(2px 3px 2px rgba(0, 0, 0, 0.5));
}

.card-container .card .ribbon.down > .content {
	clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), 50% 100%, 0 calc(100% - 8px));
}

.card-container .card .ribbon .content {
	color: var(--off-white);
	background: var(--theme-yellow, #2ca7d8) linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.25) 100%);
	padding: 8px 2px 4px;
}

.card-container .card .ribbon .content p {
	font-size: 12px;
	letter-spacing: 0.6px;
	margin-bottom: 2px;
}

.card-container .card .card-img {
	position: absolute;
	top: 50%;
	left: 50%;
	opacity: 0.7;
	width: 300px;
	height: 300px;
	transform: translate(-50%, -50%);
	transition: opacity 0.2s ease-out;
	z-index: -1;
	border-radius: 10px;
	object-fit: cover;
}

.card-container .card h3 {
	position: absolute;
	inset: auto auto 8px 10px;
	margin: 0;
	transition: inset 0.3s 0.3s ease-out;
	font-family: "DM Sans";
	font-size: 13px;
	font-weight: 400;
	max-width: 63%;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.card-container .card:hover h3 {
	transition: inset 0.3s ease-out;
}

.card-container .card h3:hover {
	color: var(--theme-yellow);
}

.card-container .card .content-div p,
.card-container .card .card-content .price--box,
.card-container .card .card-content .product-price--box {
	margin-bottom: 0px;
}

.card-container .card .content-div {
	position: absolute;
	width: auto;
	display: flex;
	align-items: center;
	grid-gap: 0px 4px;
	font-size: 10px;
	inset: auto auto 365px 45px;
	transition: inset 0.3s 0.3s ease-out;
	padding: 2px 6px;
	background: var(--theme-light-yellow-rgb);
	border-radius: 4px;
	color: var(--theme-yellow);
}

.card-container .card .card-content .product-price--box {
	width: max-content;
	height: 30px;
	line-height: 30px;
	padding: 0 15px;
	position: absolute;
	right: -9px;
	bottom: 10px;
	color: var(--bg-white);
	background: rgba(194, 153, 88, 0.8);
}

.card-container .card .card-content .product-price--box:before,
.card-container .card .card-content .product-price--box:after {
	content: "";
	position: absolute;
}

.card-container .card .card-content .product-price--box:before {
	height: 0;
	width: 0;
	top: -8.5px;
	right: 0.1px;
	border-bottom: 9px solid #a17735;
	border-right: 9px solid transparent;
}

.card-container .card .card-content .product-price--box:after {
	height: 0;
	width: 0;
	left: -10px;
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
	border-right: 10px solid rgba(194, 153, 88, 0.8);
}

.card-container .card .card-content .price--box {
	width: max-content;
	height: 30px;
	line-height: 30px;
	padding: 0 15px;
	position: absolute;
	right: -10px;
	bottom: 10px;
	color: var(--bg-white);
	background: rgba(194, 153, 88, 0.8);
}

.card-container .card .card-content .price--box:before,
.card-container .card .card-content .price--box:after {
	content: "";
	position: absolute;
}

.card-container .card .card-content .price--box:before {
	height: 0;
	width: 0;
	top: -8.5px;
	right: 0.1px;
	border-bottom: 9px solid #a17735;
	border-right: 9px solid transparent;
}

.card-container .card .card-content .price--box:after {
	height: 0;
	width: 0;
	left: -10px;
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
	border-right: 10px solid rgba(194, 153, 88, 0.8);
}

.card-container .btn-cart {
	display: block;
	width: 120px;
	line-height: 32px;
	border: none;
	margin: 0 auto;
	background: none;
	text-transform: capitalize;
	color: white;
	font-size: 12px;
	position: absolute;
	cursor: pointer;
	inset: auto auto 20px 30px;
	opacity: 0;
	transition: opacity 0.3s ease-out;
	font-family: "DM Sans";
}

.card-container .card:hover .btn-cart {
	opacity: 1;
	transition: opacity 0.5s 0.1s ease-in;
}

.card-container .btn-cart::before {
	content: "";
	display: block;
	width: 12px;
	height: 12px;
	position: absolute;
	border: 2px solid var(--theme-yellow);
	transform: translateX(0);
	left: 34px;
	border-radius: 50%;
	top: 5px;
	box-sizing: border-box;
}

.card-container .btn-cart::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--theme-yellow);
	transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.card-container .btn-cart:focus {
	outline: none;
}

.card-container .btn-cart:focus:before {
	animation: shift-left-circle 800ms forwards;
	animation-delay: 1200ms;
}

.card-container .btn-cart:focus:after {
	width: 20px;
	height: 20px;
	top: 12px;
	left: 30px;
	animation: shift-left 400ms forwards;
	animation-delay: 1200ms;
	transition-delay: 400ms;
}

.card-container .btn-cart:focus > span::before {
	animation: shift-left-mask 800ms forwards;
	animation-delay: 800ms;
	height: 7px;
}

.card-container .btn-cart:focus > span::after {
	transform: translate(-30%, 5px);
	transition-delay: 1600ms;
	opacity: 1;
}

.card-container .btn-cart:focus > span span {
	opacity: 0;
	transform: translateY(20px);
}

.card-container .btn-cart > span {
	position: relative;
	display: block;
}

.card-container .btn-cart > span::before {
	content: "";
	display: block;
	position: absolute;
	width: 12px;
	height: 20px;
	background: white;
	top: 5px;
	left: 34px;
	animation-timing-function: linear;
	transform: translateX(0) rotate(0deg);
	transform-origin: center bottom;
}

.card-container .btn-cart > span::after {
	content: "ADDED";
	color: green;
	position: absolute;
	z-index: 3;
	left: 20%;
	opacity: 0;
	transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transform: translate(-30%, 20px);
	transition-delay: 0;
}

.card-container .btn-cart span span {
	display: inline-block;
	position: relative;
	z-index: 2;
	transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transform: translateY(0px);
}

.card-container .card:hover img {
	transition: opacity 0.3s ease-in;
	opacity: 1;
}

.card-container .metal-container {
	position: absolute;
	top: 15px;
	left: 20px;
	transition: inset 0.4s 0.4s ease-out;
	z-index: 12;
}

.card-container .metal-container label {
	cursor: pointer;
	width: 18px;
	height: 18px;
	overflow: hidden;
	border-radius: 100%;
	position: relative;
	margin-right: 5px;
}

.card-container .metal-container label:last-child {
	margin-right: 0px;
}

.card-container .metal-container label.active {
	border: 2px solid var(--theme-yellow);
}

.card-container .metal-container label input {
	position: absolute;
	opacity: 0;
}

.card-container .metal-container label::after {
	position: absolute;
	content: "";
	width: 15px;
	height: 15px;
	border-radius: 100%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.card-container .metal-container label.White {
	background: var(--white-linear);
}

.card-container .metal-container label.Yellow {
	background: var(--yellow-linear);
}

.card-container .metal-container label.Gold {
	background: var(--white-gold-linear);
}

.card-container .metal-container label.Rose {
	background: var(--rose-linear);
}

.card-container .metal-container label input:checked ~ label {
	border: 1px solid var(--theme-yellow);
}

.card-container .whishlist--btn {
	width: 40px;
	height: 40px;
	border-radius: 100%;
	position: absolute;
	top: 0px;
	right: 0px;
	transition: inset 0.3s 0.3s ease-out;
	z-index: 1;
}

.card-container .whishlist--btn .heart-stroke {
	fill: none;
	stroke: var(--whishlist-color);
	stroke-width: 2px;
	opacity: 1;
	transform-origin: center center;
}

.card-container .whishlist--btn .heart-full {
	opacity: 0;
	transform-origin: 50% 50%;
}

.card-container .whishlist--btn .heart-lines {
	stroke-width: 2px;
	display: none;
}

.card-container .whishlist--btn.active .heart-stroke {
	opacity: 0;
}

.card-container .whishlist--btn.active .heart-full {
	opacity: 1;
}

.card-container .whishlist--btn:not(.active):hover .heart-stroke {
	animation: pulse 1s ease-out infinite;
}

.card-container .whishlist--btn.animate .heart-full {
	animation: heart 0.35s;
}

.card-container .whishlist--btn.animate .heart-lines {
	animation: lines 0.2s ease-out forwards;
	display: block;
}

/* ===== {{ END:: dynamic card css }} ===== */

/* ===== {{ START:: React select CSS }} ===== */

.css-b62m3t-container .css-1u9des2-indicatorSeparator {
	display: none;
}

.css-b62m3t-container .css-1dimb5e-singleValue {
	font-family: "DM Sans";
	color: var(--theme-yellow);
}

.css-b62m3t-container .css-1nmdiq5-menu {
	z-index: 13;
}

.css-b62m3t-container .css-13cymwt-control,
.css-b62m3t-container .css-t3ipsp-control {
	border: 1px solid var(--theme-yellow);
	border-radius: 8px;
}

.css-b62m3t-container .css-13cymwt-control:hover,
.css-b62m3t-container .css-t3ipsp-control:hover,
.css-b62m3t-container .css-13cymwt-control:active,
.css-b62m3t-container .css-t3ipsp-control:active,
.css-b62m3t-container .css-13cymwt-control:focus,
.css-b62m3t-container .css-t3ipsp-control:focus {
	border: 1px solid var(--theme-yellow);
	border-radius: 8px;
	box-shadow: none;
}

/* ===== {{ END:: React select CSS }} ===== */

/* =====  {{ START:: Multiple stepping CSS }}  ===== */

.stepping-bar {
	max-width: 890px;
	width: 100%;
	height: 45px;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	grid-gap: 0px 2px;
}

.stepping-bar .stepping-bar--inner {
	width: 45%;
	height: 100%;
	font-size: 16px;
	background-color: var(--theme-light-yellow-rgb);
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 45%;
	cursor: pointer;
}

.stepping-bar .stepping-bar--inner.stepping-bar-left--content {
	border-radius: 24px 0px 0px 24px;
}

.stepping-bar .stepping-bar--inner.stepping-bar-right--content {
	border-radius: 0px 24px 24px 0px;
}

.stepping-bar .ir346-cyo__con .ir346-cyo__icon img {
	width: 50px;
	height: 50px;
}

.stepping-bar .ir346-cyo__con:first-child {
	border-start-start-radius: 24px;
	border-end-start-radius: 24px;
	grid-gap: 0px 4px;
}

.stepping-bar .ir346-cyo__con:last-child {
	border-start-end-radius: 24px;
	border-end-end-radius: 24px;
	grid-gap: 0px 4px;
}

.stepping-bar .ir346-cyo__con .avenir-medium:nth-child(2),
.stepping-bar .ir346-cyo__con .ir346-cyo__text {
	padding: 0px 12px;
	font-size: 12px;
	color: var(--theme-title);
	text-transform: capitalize;
	font-family: "DM Sans";
	line-height: 20px;
	padding: 0 5px;
}

.stepping-bar .stepping-bar--inner .stepping-bar-span--text {
	font-size: 16px;
	font-weight: 400;
	margin-right: 5px;
	color: var(--primary);
}

.stepping-bar .stepping-bar--inner .stepping-bar-link--text,
.stepping-bar .stepping-bar--inner .stepping-bar-link-span--text {
	text-transform: capitalize;
	font-size: 14px;
	font-weight: 400;
	font-family: "DM Sans";
	color: var(--theme-gray-color);
	margin-left: 4px;
}

.stepping-bar .stepping-bar--inner .stepping-bar-link--text:hover,
.stepping-bar .stepping-bar--inner .stepping-bar-content-inner--text a:hover {
	text-decoration: underline !important;
	color: var(--semi-primary);
}

.stepping-bar .stepping-bar--inner .stepping-bar-content-inner--text {
	margin: 0px 4px;
	max-width: 150px;
}

.stepping-bar .stepping-bar--inner .stepping-bar-content-inner--text p {
	margin: 0px;
	font-size: 12px;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	font-family: "DM Sans";
	font-weight: 400;
	text-overflow: ellipsis;
	line-height: 18px;
	text-transform: capitalize;
}

.stepping-bar .stepping-bar--inner .stepping-bar-content-inner--text a {
	font-size: 12px;
	font-weight: 400;
	font-family: "DM Sans";
	color: #666666;
	text-decoration: underline !important;
}

.stepping-bar .is-active img {
	width: 40px !important;
	height: 40px !important;
}

.stepping-bar .stepping-bar--inner .stepping-bar-link--text img,
.stepping-bar .stepping-bar--inner .stepping-bar-link-span--text img {
	margin-left: 5px;
	max-width: 45px;
	width: 100%;
	height: 45px;
}

.stepping-bar .stepping-bar--inner .stepping-img--box {
	margin-left: 5px;
}

.stepping-bar .stepping-bar--inner .stepping-img--box img {
	width: 45px;
	height: 45px;
	border-radius: 100%;
}

/* =====  {{ END:: Multiple stepping CSS }}  ===== */

/* ===== {{START:: dynamic Component responsive CSS}} ===== */

@media only screen and (max-width: 576px) {
	.card-container .card .card-content .price--box {
		inset: auto auto 15px 175px !important;
	}

	.mobile-select {
		margin-bottom: 0px !important;
		width: 80%;
	}
}

@media only screen and (max-width: 475px) {
	.hero-section .hero-content .hero-body {
		padding-right: 0 !important;
	}

	.hero-section .hero-content .hero-body .btn-hero {
		display: block;
		margin: 0 auto;
		margin-bottom: 15px !important;
		max-width: 280px;
	}

	.wedding-rings .major-bar a {
		font-size: 12px;
		line-height: 20px;
	}

	.wedding-rings .major-bar {
		padding: 20px 10px;
	}

	.card-container .card .card-content .price--box {
		inset: auto auto 15px 318px !important;
	}

	.slider-container .slider-content .slick-prev,
	.slider-container .slider-content .slick-next,
	.style-slider-container .style-slider-content .slick-prev,
	.style-slider-container .style-slider-content .slick-next {
		top: auto;
		bottom: -50px;
	}

	.feature-container .feature-card .caption {
		position: relative;
		left: 0px;
		top: 0px;
		max-width: 100%;
		width: 100%;
		padding: 9px;
		text-align: center;
	}
}

@media only screen and (max-width: 768px) {
	.hero-section {
		display: flex;
		height: 320px !important;
	}

	.hero-section .hero-content .hero-body {
		margin-left: 0 !important;
		margin: 0;
		width: 100%;
		margin: auto !important;
		padding-right: 30px;
		text-align: center;
	}

	.hero-section .hero-content .hero-body > div {
		text-align: center;
	}

	.hero-section .hero-content .hero-body h1 {
		font-size: 22px !important;
		text-align: center;
	}

	.shape-container .shape-content .shop-content-link {
		flex-wrap: wrap;
		justify-content: center;
	}

	.shape-container .shape-content .shop-content-link li {
		padding: 8px;
		margin-bottom: 8px;
	}

	.shape-container .shape-content .shop-content-link li a i {
		min-width: 45px;
		width: 45px;
		height: 45px;
		background-size: 465px auto;
	}

	.card-container .card .card-content .price--box {
		inset: auto auto 15px 250px;
	}

	.slider-title-context {
		flex-wrap: wrap;
		flex-direction: column;
		align-items: start !important;
	}

	.slider-container .slider-content .slick-next,
	.style-slider-container .style-slider-content .slick-next {
		right: 9%;
	}

	.style-slider-container .style-slider-content .slider-title-context .btn--slider,
	.slider-container .slider-content .slider-title-context .btn--slider {
		margin: 0;
	}

	.ready-to-ship {
		padding: 20% 0px;
	}

	.ready-to-ship .minimalist-collection-full-tile {
		transform: translate(-50%, -50%);
		box-shadow: none;
		text-align: start;
		padding: 10px;
		top: 50%;
		left: 50%;
		width: 95%;
	}

	.mobile-best-select .mobile-select,
	.mobile-setect .css-b62m3t-container {
		width: 100% !important;
	}

	.stepping-bar .ir346-cyo__con .ir346-cyo__icon img {
		height: 30px;
		width: 30px;
	}
}

@media only screen and (max-width: 320px) {
	.card-container .card .card-content .price--box {
		inset: auto auto 15px 215px !important;
	}

	.slider-title-context {
		flex-wrap: wrap;
	}

	.slider-container .slider-content .slick-prev,
	.slider-container .slider-content .slick-next,
	.style-slider-container .style-slider-content .slick-next,
	.style-slider-container .style-slider-content .slick-prev {
		top: auto;
		bottom: -53px;
	}

	.slider-container .slider-content .slick-next,
	.style-slider-container .style-slider-content .slick-next {
		right: 22%;
	}

	.feature-container .feature-card .caption {
		position: initial;
		max-width: 100%;
		text-align: center;
		padding: 0px 10px;
		margin-bottom: 20px;
		border-radius: 0px;
	}

	.ready-to-ship {
		background-position: 58%;
	}

	.ready-to-ship .minimalist-collection-full-tile p {
		padding: 0 12px;
	}

	.ready-to-ship,
	.ready-to-ship .minimalist-collection-full-tile h3,
	.ready-to-ship .minimalist-collection-full-tile p,
	.style-slider-container .style-slider-content .slider-inner-content .slider-content-link .slider-caption p {
		text-align: center;
	}

	.ready-to-ship .minimalist-collection-full-tile .btn--custom {
		margin: auto;
	}

	.ready-to-ship .minimalist-collection-full-tile h3 {
		font-size: 26px;
	}

	.mobile-best-select {
		flex-wrap: wrap;
	}

	.mobile-select {
		width: 85% !important;
	}

	.feature-container .feature-card .caption .feature-btn .btn--feature {
		margin: auto;
	}
}

@media only screen and (max-width: 425px) {
	.slider-container .slider-content .slick-next,
	.style-slider-container .style-slider-content .slick-next {
		right: auto;
		left: 12px;
		z-index: 12;
	}

	.slider-container .slider-content .slick-prev {
		right: 12px;
	}

	.slider-caption {
		text-align: center;
	}
}

@media (min-width: 320px) and (max-width: 425px) {
	.stepping-bar {
		flex-wrap: wrap;
		height: auto;
		grid-gap: 0;
		flex-direction: column;
	}

	.stepping-bar .ir346-cyo__con {
		border-radius: 8px;
		font-size: 12px;
		flex-wrap: wrap;
		width: 100%;
		flex-direction: row;
		padding: 5px 0px;
	}

	.stepping-bar .ir346-cyo__info .ir346-cyo__product,
	.stepping-bar .stepping-bar--inner .stepping-bar-content-inner--text {
		max-width: 140px;
	}

	.stepping-bar .ir346-cyo__info a:nth-child(2) {
		justify-content: center;
	}

	.stepping-bar .ir346-cyo__con:first-child {
		border-start-start-radius: 8px;
		border-end-start-radius: 8px;
		margin-bottom: 8px;
	}

	.stepping-bar .ir346-cyo__con:last-child {
		border-start-end-radius: 8px;
		border-end-end-radius: 8px;
	}

	.ir346-cyo__con--diamond .ir346-cyo__info {
		padding: 0 4px;
	}

	.stepping-bar .ir346-cyo__con .ir346-cyo__icon img {
		display: block;
		margin: auto;
		width: 30px !important;
		height: 30px !important;
		background-size: 110px !important;
	}

	.stepping-bar .ir346-cyo__con .avenir-medium:nth-child(2),
	.stepping-bar .ir346-cyo__con .ir346-cyo__text {
		padding: 0px 10px;
		font-size: 10px;
	}

	.mobile-steper-link {
		flex-wrap: wrap;
		justify-content: center;
	}

	.stepping-bar .ir346-cyo__con span {
		font-size: 12px !important;
		text-transform: capitalize;
	}

	.stepping-bar .stepping-bar--inner.stepping-bar-left--content {
		margin-bottom: 10px;
	}

	.stepping-bar .stepping-bar--inner {
		flex: none;
		width: 100%;
		flex-direction: column;
		padding: 8px;
	}

	.stepping-bar .stepping-bar--inner .stepping-bar-span--text {
		display: block;
		text-align: center;
		margin-right: 0px;
		margin-bottom: 5px;
		width: 100%;
		font-size: 14px !important;
	}

	.stepping-bar .stepping-bar--inner .stepping-bar-content-inner--text p {
		text-overflow: initial;
		white-space: wrap;
		overflow: initial;
		margin-bottom: 5px !important;
	}

	.stepping-bar .stepping-bar--inner .stepping-bar-link--text,
	.stepping-bar .stepping-bar--inner .stepping-bar-content-inner--text a,
	.stepping-bar .stepping-bar--inner .stepping-bar-content-inner--text p {
		font-size: 14px !important;
		width: 100%;
	}

	.stepping-bar .stepping-bar--inner .stepping-bar-content-inner--text {
		max-width: 100% !important;
		width: 100%;
		text-align: center;
		padding: 0 10px;
	}

	.stepping-bar .stepping-bar--inner.stepping-bar-left--content,
	.stepping-bar .stepping-bar--inner.stepping-bar-right--content {
		border-radius: 8px;
	}

	.stepping-bar .stepping-bar--inner .stepping-bar-content-inner--text p {
		margin-bottom: 8px;
	}

	.stepping-bar .stepping-bar--inner .stepping-img--box {
		margin-left: 0px;
	}
}

@media (min-width: 481px) and (max-width: 768px) {
	.stepping-bar .ir346-cyo__con:first-child {
		grid-gap: 0px 4px;
	}

	.stepping-bar .ir346-cyo__con {
		font-size: 14px;
	}

	.stepping-bar .ir346-cyo__con .ir346-cyo__link {
		text-align: center;
	}

	.stepping-bar .ir346-cyo__info a:nth-child(2) {
		justify-content: center;
	}
}

@media (min-width: 500px) and (max-width: 768px) {
	.stepping-bar .ir346-cyo__info .ir346-cyo__product,
	.stepping-bar .stepping-bar--inner .stepping-bar-content-inner--text {
		max-width: 100px !important;
	}

	.style-slider-container .style-slider-content .slider-inner-content .slider-content-link img {
		height: 222px;
	}

	.slider-container .slider-content .slider-inner-content .slider-content-link .slider-caption p,
	.style-slider-container .style-slider-content .slider-inner-content .slider-content-link .slider-caption p {
		font-size: 12px;
	}
}

@media (max-width: 500px) and (min-width: 426px) {
	.stepping-bar .ir346-cyo__info .ir346-cyo__product,
	.stepping-bar .stepping-bar--inner .stepping-bar-content-inner--text {
		max-width: 80px;
	}

	.stepping-bar .ir346-cyo__con .avenir-medium {
		font-size: 10px;
	}

	.stepping-bar .ir346-cyo__con .ir346-cyo__icon img {
		height: 30px !important;
		width: 30px !important;
		background-size: 100px;
	}

	.stepping-bar .ir346-cyo__con .avenir-medium:nth-child(2),
	.stepping-bar .ir346-cyo__con .ir346-cyo__text {
		padding: 0px 4px !important;
	}
}

/* ===== {{END:: dynamic Component responsive CSS}} ===== */

/* ===== {{ START:: dynamic component animation css }} ===== */

@keyframes lines {
	0% {
		stroke-dasharray: 6;
		stroke-dashoffset: 16;
	}

	100% {
		stroke-dasharray: 13;
		stroke-dashoffset: 18;
	}
}

@keyframes heart {
	0% {
		transform: scale(1);
		transform-origin: center center;
		animation-timing-function: ease-out;
	}

	10% {
		transform: scale(1.2);
		animation-timing-function: ease-out;
	}

	35% {
		transform: scale(1);
		transform-origin: center center;
		animation-timing-function: ease-in;
	}

	75% {
		transform: scale(1.1);
		animation-timing-function: ease-in;
	}

	100% {
		transform: scale(1);
		animation-timing-function: ease-out;
	}
}

@keyframes pulse {
	0% {
		opacity: 1;
		transform-origin: center center;
		transform: scale(1);
	}

	50% {
		opacity: 0.6;
		transform: scale(1.15);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes shift-left {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-40px);
	}
}

@keyframes shift-left-circle {
	0% {
		transform: translateX(0);
	}

	50% {
		transform: translateX(-40px);
	}

	100% {
		transform: translateX(-40px);
	}
}

@keyframes shift-left-mask {
	0% {
		height: 7px;
		transform: translateX(0) rotate(0);
	}

	50% {
		transform: translateX(0) rotate(180deg);
	}

	100% {
		transform: translateX(-40px) rotate(180deg);
	}
}

@keyframes LineFadeIn {
	0% {
		opacity: 0;
		d: path("M 0 300 Q 0 300 0 300 Q 0 300 0 300 C 0 300 0 300 0 300 Q 0 300 0 300 ");
		stroke: var(--primary);
	}

	50% {
		opacity: 1;
		d: path("M 0 300 Q 50 300 100 300 Q 250 300 350 300 C 350 300 500 300 650 300 Q 750 300 800 300");
		stroke: var(--theme-yellow);
	}

	100% {
		opacity: 1;
		d: path("M -2 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 802 400");
		stroke: var(--bg-white);
	}
}

@keyframes ContentFadeIn {
	0% {
		transform: translateY(-1rem);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes ImageFadeIn {
	0% {
		transform: translate(-0.5rem, -0.5rem) scale(1.05);
		opacity: 0;
		filter: blur(2px);
	}

	50% {
		opacity: 1;
		filter: blur(2px);
	}

	100% {
		transform: translateY(0) scale(1);
		opacity: 1;
		filter: blur(0);
	}
}

/* ===== {{ END:: dynamic component animation css }} ===== */
