@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,200;9..40,300;9..40,400;9..40,500;9..40,600&family=Frank+Ruhl+Libre:wght@300;400;600&family=Roboto:wght@300;400;500;700&display=swap");
* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	font-family: "DM Sans";
}

.breadcrumb_main_div section {
	display: block;
	background-image: url("../../image/breadcrumbImage.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.breadcrumb_main_div .breadcrumb-z7n {
	padding: 30px 0;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
}

.breadcrumb_main_div .breadcrumb-z7n:after {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	content: "";
	background: rgba(0, 0, 0, 0.5);
	z-index: 1;
}

.breadcrumb_main_div .breadcrumb-77p li i {
	line-height: 1.1;
	font-size: 16px;
	font-weight: 600 !important;
	margin-right: 5px;
	outline: none !important;
}

.breadcrumb_main_div .breadcrumb-77p li a {
	display: block;
	font-family: "DM Sans";
	color: #fff;
	font-size: 16px;
}

.breadcrumb_main_div .breadcrumb-77p li a:hover {
	color: #c29958;
}

.breadcrumb_main_div .breadcrumb-77p ul {
	height: 24px;
	line-height: 24px;
}

.breadcrumb_main_div .breadcrumb-77p li {
	font-size: 18px;
	line-height: 1.5;
	color: #fff;
}

.breadcrumb_main_div .breadcrumb-77p li i {
	font-size: 12px;
	padding-left: 5px;
	line-height: 63px;
	color: #fff;
}

.breadcrumb_main_div .text-ynv {
	text-align: center !important;
}

.breadcrumb_main_div .ani-1sy {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.breadcrumb_main_div .fa-gpo {
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

.breadcrumb_main_div .breadcrumb-hiz {
	position: relative;
	z-index: 9;
}

.breadcrumb_main_div .breadcrumb-77p li a {
	color: #fff;
	font-family: "DM Sans";
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		-webkit-transform: translateY(20px);
		-ms-transform: translateY(20px);
		transform: translateY(20px);
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
		transform: translateY(0px);
	}
}
