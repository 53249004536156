@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,200;9..40,300;9..40,400;9..40,500;9..40,600&family=Frank+Ruhl+Libre:wght@300;400;600&family=Roboto:wght@300;400;500;700&display=swap");
:root {
	--primary: #000;
	--text-color: #9c9e9c;
	--color: #c29958;
	--bg-light: #fff;
	--dark-gray: #555555;
	--border-color: #efefef;

	--theme-title: #003f60;
	--theme-yellow-color: #fabc5f;
	--theme-gray-color: #777777;
}

@keyframes notification {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		transform: scale(2);
		opacity: 0;
	}
}

.header-area {
	position: sticky;
	top: 0px;
	z-index: 1111;
	background-color: var(--bg-light);
}
.main-menu ul {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin-bottom: 0px;
	padding: 0px;
}
.main-menu ul li {
	position: relative;
	list-style: none;
}
.main-menu ul li a {
	color: var(--primary);
	font-size: 13px;
	padding: 20px 8px;
	font-weight: 400;
	display: block;
	text-transform: capitalize;
	text-decoration: none;
	line-height: 40px;
	font-family: "DM Sans";
	cursor: pointer;
}
.main-menu ul li a i {
	font-size: 14px;
	padding: 0 3px;
}
.main-menu ul li:first-child a {
	padding-left: 0;
}

.main-menu ul li ul.dropdown {
	top: calc(100% - 1px);
	left: 0;
	width: 220px;
	position: absolute;
	-webkit-transition: 0.4s;
	-o-transition: 0.4s;
	transition: 0.4s;
	background-color: var(--bg-light);
	opacity: 0;
	visibility: hidden;
	z-index: 99999;
	pointer-events: none;
	-webkit-transform: translateY(20px);
	-ms-transform: translateY(20px);
	transform: translateY(20px);
}
.main-menu ul li ul.dropdown li {
	margin-right: 0;
	border-right: none;
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
}
.main-menu ul li ul.dropdown li a {
	color: var(--dark-gray);
	font-size: 14px;
	font-weight: 400;
	padding: 10px 20px;
	text-transform: capitalize;
	position: relative;
	border-bottom: 1px dashed var(--border-color);
	z-index: 1;
}
.main-menu ul li ul.dropdown li a:before {
	width: 10px;
	height: 1px;
	left: 20px;
	top: 50%;
	content: " ";
	position: absolute;
	background-color: var(--color);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	opacity: 0;
	visibility: hidden;
	-webkit-transition: 0.4s;
	-o-transition: 0.4s;
	transition: 0.4s;
	z-index: -1;
}
.main-menu ul li ul.dropdown li a i {
	float: right;
	padding-top: 5px;
}
.main-menu ul li ul.dropdown li:hover > a {
	color: var(--color);
	background-color: var(--bg-light);
	padding-left: 35px;
	transition: 0.4s;
}
.main-menu ul li ul.dropdown li:hover > a:before {
	opacity: 1;
	visibility: visible;
}
.main-menu ul li ul.dropdown li:hover > ul.dropdown {
	top: 0;
	opacity: 1;
	visibility: visible;
}
.main-menu ul li ul.dropdown li ul.dropdown {
	top: 100%;
	left: 100%;
	position: absolute;
	width: 250px;
	opacity: 0;
	visibility: hidden;
}
.main-menu ul li ul.dropdown li:last-child a {
	border-bottom: none;
}
.main-menu ul li ul.megamenu {
	width: 100%;
	max-width: 100%;
	padding: 30px;
	left: 50%;
	-webkit-transform: translate(-50%, 20px);
	-ms-transform: translate(-50%, 20px);
	transform: translate(-50%, -2px);
}

.main-menu ul li ul.megamenu li {
	-webkit-flex-basis: 25%;
	-ms-flex-preferred-size: 25%;
	flex-basis: 25%;
}
.main-menu ul li ul.megamenu li.mega-title {
	color: var(--text-color);
	font-size: 16px;
	font-weight: 600;
	text-transform: capitalize;
}
.main-menu ul li ul.megamenu li.mega-title span {
	position: relative;
	margin-bottom: 15px;
	display: inline-block;
}
.main-menu ul li ul.megamenu li.mega-title span:before,
.main-menu ul li ul.megamenu li.mega-title span:after {
	bottom: -6px;
	left: 0;
	width: 100%;
	height: 2px;
	content: " ";
	position: absolute;
	background-color: #ebebeb;
}
.main-menu ul li ul.megamenu li.mega-title span:after {
	width: 50%;
	background-color: var(--color);
}
.main-menu ul li ul.megamenu li a {
	padding: 6px 0;
	border-bottom: none;
}
.main-menu ul li ul.megamenu li a:before {
	left: 0;
}
.main-menu ul li ul.megamenu li ul li {
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
}
.main-menu ul li ul.megamenu li:hover > a {
	color: var(--color);
	padding-left: 15px;
}
.main-menu ul li ul.megamenu li.megamenu-banners {
	-webkit-flex-basis: 50%;
	-ms-flex-preferred-size: 50%;
	flex-basis: 50%;
	margin-top: 25px;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	padding-right: 10px;
}
.main-menu ul li ul.megamenu li.megamenu-banners img {
	width: 100%;
}
.main-menu ul li ul.megamenu li.megamenu-banners a:before {
	display: none;
}
.main-menu ul li ul.megamenu li.megamenu-banners:last-child {
	padding-right: 0;
	padding-left: 10px;
}
.main-menu ul li ul.megamenu li.megamenu-banners:hover {
	opacity: 0.6;
}
.main-menu ul li ul.megamenu li.megamenu-banners:hover a {
	padding-left: 0 !important;
}
.main-menu ul li:hover ul.dropdown {
	opacity: 1;
	visibility: visible;
	pointer-events: visible;
	-webkit-transform: translate(0);
	-ms-transform: translate(0);
	transform: translate(0);
}
.main-menu ul li:hover ul.megamenu {
	opacity: 1;
	visibility: visible;
	-webkit-transform: translate(-50%, 0);
	-ms-transform: translate(-50%, 0);
	transform: translate(-50%, 1px);
}
.main-menu ul.header-style-4 > li a {
	padding: 25px 15px;
}
.main-menu ul.header-style-4 > li:first-child > a {
	padding-left: 0;
}
.header-search-container {
	position: relative;
	margin-right: 10px;
}
.search-trigger {
	font-size: 26px;
	color: var(--text-color);
}

.header-search-box {
	position: relative;
}
.header-search-box:hover .header-search-btn {
	color: var(--color);
}
.header-search-btn {
	font-size: 25px;
	line-height: 1;
	left: 10px;
	top: 44%;
	width: 30px;
	position: absolute;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.header-search-btn i {
	font-size: 20px;
}
.header-search-field {
	border: none;
	background-color: #f7f7f7;
	border-radius: 30px;
	padding: 10px 20px 10px 50px;
	width: 100%;
}

.header-configure-area {
	padding-right: 10px;
}
.header-configure-area ul li {
	display: inline-block;
	margin-left: 15px;
	line-height: 1;
}
.header-transparent {
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	position: absolute;
	z-index: 1;
}
.sticky.is-sticky {
	top: 0;
	left: 0;
	position: fixed;
	width: 100%;
	z-index: 9;
	background-color: var(--bg-light);
	-webkit-box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
	box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
	-webkit-animation: 900ms cubic-bezier(0.2, 1, 0.22, 1) 0s normal none 1 running fadeInDown;
	animation: 900ms cubic-bezier(0.2, 1, 0.22, 1) 0s normal none 1 running fadeInDown;
}

.header-configure-area ul li a {
	font-size: 26px;
	line-height: 1;
	color: var(--text-color);
	position: relative;
	display: block;
}
.header-configure-area ul li a i,
.header-configure-area .unnotification--btn i {
	font-size: 18px;
}
.header-configure-area ul li a .notification,
.header-configure-area ul li button .notification {
	top: -7px;
	right: -7px;
	position: absolute;
	width: 18px;
	height: 18px;
	text-align: center;
	line-height: 15px;
	font-size: 10px;
	color: var(--bg-light);
	border-radius: 50%;
	border: 1px solid var(--theme-yellow);
	background-color: var(--theme-yellow);
}

.header-configure-area ul li button .notification i {
	color: var(--text-color);
}
.header-configure-area ul li .notification--btn::before {
	position: absolute;
	content: "";
	border: 1px solid var(--theme-yellow);
	height: 1pc;
	width: 1pc;
	top: calc(50% - 16.4px);
	left: calc(50% - 1px);
	border-radius: 50%;
	z-index: -1;
	animation-name: notification;
	animation-duration: 2s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}

.header-configure-area ul li .notification--btn::after {
	position: absolute;
	content: "";
	top: calc(50% - 20px);
	left: calc(50% - 5px);
	border: 1px solid var(--theme-yellow-rgb);
	border-radius: 50%;
	height: 1.5pc;
	width: 1.5pc;
	z-index: -1;
	animation-name: notification;
	animation-duration: 2s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}

.header-configure-area ul li a:hover {
	color: var(--color);
}
.header-configure-area ul li:first-child {
	margin-left: 0;
}
.header-configure-area ul li.user-hover {
	position: relative;
}
.header-configure-area ul li.user-hover .dropdown-list {
	position: absolute;
	top: 29px;
	right: -68px;
	width: 150px;
	padding: 9px;
	background-color: var(--bg-light);
	z-index: 11;
	-webkit-transform: translateY(30px);
	-ms-transform: translateY(30px);
	transform: translateY(30px);
	-webkit-transition: 0.4s;
	-o-transition: 0.4s;
	transition: 0.4s;
	opacity: 0;
	visibility: hidden;
	border: 1px solid var(--border-color);
}
.header-configure-area .nav {
	align-items: first baseline !important;
}
.header-configure-area ul li.user-hover .user-name-div {
	display: flex;
	align-items: center;
	justify-content: center;
}
.header-configure-area ul li.user-hover .user-name-div i {
	font-size: 18px;
}
.header-configure-area ul li.user-hover .user-name-div i::before {
	line-height: 21px;
}
.header-configure-area ul li.user-hover .user-name-div .user-name {
	font-size: 12px;
	font-weight: 500;
	margin: 0 !important;
	padding: 10px;
	padding-bottom: 6px;
	text-transform: capitalize;
}

.header-configure-area ul li.user-hover .dropdown-list li {
	display: block;
	margin-left: 0;
	cursor: pointer;
}
.header-configure-area ul li.user-hover .dropdown-list li a {
	color: var(--dark-gray);
	font-size: 13px;
	line-height: 1;
	display: block;
	padding: 8px 0;
	text-transform: capitalize;
	text-decoration: none;
}
.header-configure-area ul li.user-hover .dropdown-list li a:hover {
	color: var(--color);
}
.header-configure-area ul li.user-hover:hover .dropdown-list {
	opacity: 1;
	visibility: visible;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}
.sticky.is-sticky .main-menu li a {
	padding: 20px 15px;
}
.sticky.is-sticky .main-menu li:first-child a {
	padding-left: 0;
}
.sticky.is-sticky .main-menu li .dropdown li a {
	padding: 10px 20px;
}
.sticky.is-sticky .main-menu li .dropdown li:hover > a {
	padding-left: 35px;
}
.sticky.is-sticky .main-menu li .megamenu li a {
	padding: 6px 0;
}
.sticky.is-sticky .main-menu li .megamenu li:hover > a {
	padding-left: 15px;
}

.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-link {
	font-size: 16px;
	line-height: 24px;
	font-weight: normal;
	text-transform: capitalize;
	padding: 0px;
	margin-top: 20px;
	margin-bottom: 10px;
}

.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-inner-link {
	padding: 8px 0;
	line-height: 1em;
	text-align: left;
	text-decoration: none;
	display: flex;
	min-height: 30px;
	align-items: center;
	font-size: 13px;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}

.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-inner-link:hover {
	color: var(--color);
}

.main-menu ul li:hover ul.megamenu .submenu-title [class*="iconking-"] {
	margin-top: -10px;
	margin-bottom: -7px;
	margin-right: 5px;
	mix-blend-mode: multiply;
}

.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-inner-link .iconking-setting {
	background-position: -2px -204px;
}
.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-inner-link .iconking-diamond {
	background-position: -2px -31px;
}
.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-inner-link .iconking-lab-diamond {
	background-position: -2px -175px;
}
.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-inner-link .iconking-gemstone {
	background-position: -1px -148px;
}
.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-inner-link .iconking-bridal-set {
	background-position: 0 -237px;
}

.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-inner-link .iconking-round {
	background-position: -30px 0;
}
.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-inner-link .iconking-cushion {
	background-position: -30px -60px;
}
.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-inner-link .iconking-pear {
	background-position: -60px -30px;
}
.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-inner-link .iconking-princess {
	background-position: -30px -30px;
}
.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-inner-link .iconking-asscher {
	background-position: -30px -120px;
}
.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-inner-link .iconking-oval {
	background-position: -30px -90px;
}
.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-inner-link .iconking-emerald {
	background-position: -60px -60px;
}
.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-inner-link .iconking-radiant {
	background-position: -60px 0;
}
.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-inner-link .iconking-marquise {
	background-position: -60px -120px;
}
.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-inner-link .iconking-heart {
	background-position: -60px -90px;
}
.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-inner-link .iconking-moissanite {
	background-position: -91px -60px;
}
.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-inner-link .iconking-sapphire {
	background-position: -91px 0;
}
.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-inner-link .iconking-emerald-2 {
	background-position: -91px -30px;
}
.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-inner-link .iconking-alexandrite {
	background-position: -91px -210px;
}
.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-inner-link .iconking-aquamarine {
	background-position: -91px -90px;
}
.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-inner-link .iconking-morganite {
	background-position: -91px -180px;
}
.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-inner-link .iconking-ruby {
	background-position: -2px -122px;
}
.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-inner-link .iconking-colored-diamond {
	background-position: -91px -240px;
}

.header-img {
	min-height: 100%;
}

/* ----- {{ START:: mobile Header }} */
.mobile-header {
	padding: 10px 0;
}
.mobile-header-top {
	border-bottom: 1px solid var(--border-color);
}
.mobile-header-top .header-top-settings {
	float: none;
}
.mobile-logo {
	max-width: 100px;
	width: 100%;
}
.mobile-main-header {
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.mobile-main-header .mobile-menu-toggler {
	display: flex;
	align-items: center;
}
.header-configure-area .notification--btn i {
	font-size: 18px;
}

.mobile-main-header .mobile-menu-toggler .notification--btn::before {
	position: absolute;
	content: "";
	border: 1px solid var(--theme-yellow);
	height: 17px;
	width: 17px;
	top: calc(50% - 15px);
	left: calc(50% - 4px);
	border-radius: 50%;
	z-index: -1;
	animation-name: notification;
	animation-duration: 2s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}

.mobile-main-header .mobile-menu-toggler .notification--btn::after {
	position: absolute;
	content: "";
	top: calc(50% - 19px);
	left: calc(50% - 7px);
	border: 1px solid var(--theme-yellow-rgb);
	border-radius: 50%;
	height: 1.5pc;
	width: 1.5pc;
	z-index: -1;
	animation-name: notification;
	animation-duration: 2s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap {
	line-height: 1;
}
.mobile-main-header .mobile-menu-toggler .mini-cart-wrap a,
.mobile-menu-toggler .notification--btn i {
	font-size: 22px;
	line-height: 1;
	color: var(--text-color);
	position: relative;
	display: block;
}
.mobile-main-header .mobile-menu-toggler .mini-cart-wrap a:hover {
	color: var(--color);
}
.mobile-main-header .mobile-menu-toggler .mini-cart-wrap .notification,
.mobile-menu-toggler .notification--btn .notification,
.mobile-menu-toggler .unnotification--btn .notification {
	font-size: 10px;
	color: var(--bg-light);
	width: 15px;
	height: 15px;
	line-height: 15px;
	border-radius: 50%;
	text-align: center;
	font-weight: 600;
	right: -4px;
	top: -3px;
	position: absolute;
	background-color: var(--color);
}

.mobile-menu-toggler .notification--btn {
	margin-right: 10px;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-heart {
	line-height: 1;
	margin-left: 10px;
}
.mobile-main-header .mobile-menu-toggler .mini-cart-heart a {
	font-size: 22px;
	line-height: 1;
	color: var(--text-color);
	position: relative;
	display: block;
}
.mobile-main-header .mobile-menu-toggler .mini-cart-heart a:hover {
	color: var(--color);
}
.mobile-main-header .mobile-menu-toggler .mini-cart-heart .notification {
	font-size: 10px;

	color: var(--bg-light);
	width: 15px;
	height: 15px;
	line-height: 15px;
	border-radius: 50%;
	text-align: center;
	font-weight: 600;
	right: -4px;
	top: 0px;
	position: absolute;
	background-color: var(--color);
}
.mobile-main-header .mobile-menu-toggler .mobile-menu-btn,
.mobile-main-header .mobile-menu-toggler .mobile-menu-btn-1 {
	margin-left: 10px;
}
.mobile-main-header .mobile-menu-toggler .mobile-menu-btn span,
.mobile-main-header .mobile-menu-toggler .mobile-menu-btn-1 span {
	width: 25px;
	height: 2px;
	display: block;
	-webkit-transition: 0.4s;
	-o-transition: 0.4s;
	transition: 0.4s;
	background-color: var(--text-color);
	-webkit-transform-origin: left;
	-ms-transform-origin: left;
	transform-origin: left;
}
.mobile-main-header .mobile-menu-toggler .mobile-menu-btn span:nth-child(2),
.mobile-main-header .mobile-menu-toggler .mobile-menu-btn span:nth-child(3),
.mobile-main-header .mobile-menu-toggler .mobile-menu-btn-1 span:nth-child(2),
.mobile-main-header .mobile-menu-toggler .mobile-menu-btn-1 span:nth-child(3) {
	margin-top: 5px;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn-1 span {
	background-color: var(--color);
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn-1 span:nth-child(1),
.mobile-main-header .mobile-menu-toggler .mobile-menu-btn-1 span:nth-child(3) {
	width: 20px;
}
.mobile-navigation {
	overflow: hidden;
	max-height: 250px;
}
.mobile-navigation nav {
	height: 100%;
}
.mobile-menu {
	margin-top: 30px;
	height: 100%;
	overflow: auto;
	padding-right: 30px;
	margin-right: -30px;
}

.mobile-menu li > a {
	font-size: 15px;
	color: var(--text-color);
	text-transform: capitalize;
	line-height: 18px;
	position: relative;
	display: inline-block;
	padding: 10px 0;
}
.mobile-menu li > a:hover {
	color: var(--color);
}
.mobile-menu li ul li {
	border: none;
}
.mobile-menu li ul li a {
	font-size: 14px;
	text-transform: capitalize;
	padding: 10px 0 8px;
}
.mobile-menu li.menu-item-has-children {
	display: block;
	position: relative;
}
.mobile-menu li.menu-item-has-children .dropdown {
	padding-left: 15px;
}
.mobile-menu li.menu-item-has-children .menu-expand {
	line-height: 50;
	top: -5px;
	left: 95%;
	width: 30px;
	position: absolute;
	height: 50px;
	text-align: center;
	cursor: pointer;
}
.mobile-menu li.menu-item-has-children .menu-expand i {
	display: block;
	position: relative;
	width: 10px;
	margin-top: 25px;
	border-bottom: 1px solid var(--text-color);
	-webkit-transition: all 250ms ease-out;
	-o-transition: all 250ms ease-out;
	transition: all 250ms ease-out;
}
.mobile-menu li.menu-item-has-children .menu-expand i:before {
	top: 0;
	width: 100%;
	content: "";
	display: block;
	position: absolute;
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
	border-bottom: 1px solid var(--text-color);
	-webkit-transition: 0.4s;
	-o-transition: 0.4s;
	transition: 0.4s;
}
.mobile-menu li.menu-item-has-children.active > .menu-expand i:before {
	-webkit-transform: rotate(0);
	-ms-transform: rotate(0);
	transform: rotate(0);
}
.mobile-sidebar .accordion .offcanvas-body .accordion-item {
	border: 0px;
}
.mobile-sidebar .accordion-button:not(.collapsed) {
	background-color: transparent;
}
.mobile-sidebar .accordion-button:focus {
	box-shadow: none;
}
.mobile-sidebar .accordion .offcanvas-body .accordion-item {
	border: 0px;
}
.mobile-sidebar .accordion-button:not(.collapsed) {
	background-color: transparent;
}
.mobile-sidebar .accordion-button:focus {
	box-shadow: none;
}
.mobile-sidebar.offcanvas {
	max-width: 320px;
	top: 82px;
}
.mobile-sidebar .accordion-item {
	background-color: var(--bg-light);
	border: 0px;
}
.mobile-sidebar .menu-item-has-children li {
	font-size: 15px;
	color: var(--text-color);
	text-transform: capitalize;
	line-height: 18px;
	padding: 10px 0;
	text-decoration: none;
}
.mobile-sidebar .menu-item-has-children a {
	text-decoration: none;
	color: var(--text-color);
}
.mobile-sidebar .accordion {
	--bs-accordion-btn-icon-width: 14px !important;
}
.search-box-offcanvas form {
	position: relative;
}
.search-box-offcanvas form input {
	color: #666;
	font-size: 13px;
	width: 100%;
	height: 40px;
	border: none;
	padding: 0 40px 0 10px;
	background-color: #f2f2f2;
}
.search-box-offcanvas form .search-btn {
	top: 0;
	right: 0;
	width: 40px;
	height: 40px;
	line-height: 42px;
	font-size: 20px;
	color: var(--text-color);
	position: absolute;
}
.search-box-offcanvas form .search-btn:hover {
	color: var(--color);
}
.offcanvas-widget-area {
	margin-top: auto;
	padding-bottom: 30px;
}
.off-canvas-contact-widget ul {
	padding: 0;
	margin-top: 20px;
}
.off-canvas-contact-widget li {
	color: var(--dark-gray);
	font-size: 15px;
	margin-bottom: 5px;
}
.off-canvas-contact-widget li i {
	width: 20px;
}
.off-canvas-contact-widget li a {
	color: var(--dark-gray);
	text-decoration: none;
	margin-left: 10px;
}
.off-canvas-contact-widget li a:hover {
	color: var(--color);
}
.off-canvas-social-widget {
	margin-top: 20px;
}
.off-canvas-social-widget a {
	color: var(--dark-gray);
	font-size: 18px;
	display: inline-block;
	margin-right: 15px;
}
.off-canvas-social-widget a:hover {
	color: var(--color);
}
.minicart-content-box {
	overflow: auto;
	height: 100%;
	padding-right: 30px;
	margin-right: -15px;
}
.minicart-item-wrapper {
	border-bottom: 1px solid var(--border-color);
}
.minicart-item-wrapper ul li {
	padding-bottom: 20px;
	margin-bottom: 20px;
	border-bottom: 1px solid var(--border-color);
}
.minicart-item-wrapper ul li:last-child {
	border-bottom: none;
	padding-bottom: 0;
}
.minicart-item {
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.minicart-thumb {
	max-width: 85px;
	-webkit-flex-basis: 85px;
	-ms-flex-preferred-size: 85px;
	flex-basis: 85px;
}
.minicart-content {
	padding: 0 10px;
	max-width: calc(100% - 115px);
	-webkit-flex-basis: calc(100% - 115px);
	-ms-flex-preferred-size: calc(100% - 115px);
	flex-basis: calc(100% - 115px);
}
.minicart-content .product-name {
	font-size: 14px;
	font-weight: 700;
	line-height: 1.5;
	padding-bottom: 10px;
}
.minicart-content .product-name a {
	color: var(--text-color);
}
.minicart-content .product-name a:hover {
	color: var(--color);
}
.minicart-content .cart-quantity {
	font-size: 12px;
	color: var(--dark-gray);
	line-height: 1;
}
.minicart-content .cart-quantity strong {
	font-size: 16px;
	font-weight: 400;
	vertical-align: text-bottom;
}
.minicart-content .cart-price {
	color: var(--color);
	font-size: 14px;
	line-height: 1;
}
.minicart-remove {
	max-width: 30px;
	-webkit-flex-basis: 30px;
	-ms-flex-preferred-size: 30px;
	flex-basis: 30px;
	text-align: center;
	font-size: 18px;
	line-height: 1;
	color: var(--text-color);
}
.minicart-remove:hover {
	color: var(--color);
}
.minicart-pricing-box {
	border-bottom: 1px solid var(--border-color);
	padding-bottom: 20px;
	margin-bottom: 20px;
	padding-top: 15px;
}
.minicart-pricing-box li {
	margin-bottom: 5px;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.minicart-pricing-box li span {
	font-size: 14px;
	color: var(--text-color);
	text-transform: capitalize;
}
.minicart-pricing-box li span strong {
	color: var(--color);
	font-weight: 400;
}
.minicart-pricing-box li.total span {
	font-size: 16px;
}
.minicart-pricing-box li:last-child {
	margin-bottom: 0;
}
.minicart-button a {
	color: var(--text-color);
	font-size: 14px;
	display: block;
	font-weight: 700;
	line-height: 1;
	padding: 17px 0;
	background-color: #f3f3f3;
	border-radius: 40px;
	text-align: center;
	margin-bottom: 10px;
}
.minicart-button a i {
	padding-right: 5px;
}
.minicart-button a:hover {
	color: var(--bg-light);
	letter-spacing: 1.1px;
	background-color: var(--color);
}
.minicart-button a:last-child {
	margin-bottom: 0;
}
.minicart-close {
	width: 50px;
	height: 50px;
	text-align: center;
	background-color: var(--color);
	color: var(--bg-light);
	font-size: 50px;
	cursor: pointer;
	top: 0;
	right: auto;
	left: -50px;
	position: absolute;
}
.minicart-close i {
	display: block;
	line-height: 15px;
	-webkit-transition: 0.6s;
	-o-transition: 0.6s;
	transition: 0.6s;
}
.minicart-close:hover i {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}
.minicart-inner-content {
	top: 0;
	right: 0;
	height: 100vh;
	width: 100%;
	max-width: 375px;
	position: absolute;
	background-color: var(--bg-light);
	-webkit-transform: translateX(calc(100% + 50px));
	-ms-transform: translateX(calc(100% + 50px));
	transform: translateX(calc(100% + 50px));
	-webkit-transition: 0.4s;
	-o-transition: 0.4s;
	transition: 0.4s;
	padding: 50px 20px;
}

.footer-top {
	background-color: #f7f7f7;
	margin-top: 80px;
}
.footer-top [class*="col-"]:last-child .widget-item {
	margin-bottom: 0;
}

.footer-bottom {
	background-color: var(--theme-yellow);
	font-family: "DM Sans";
}

.widget-title {
	margin-top: 0px !important;
	margin-bottom: 23px !important;
	color: var(--theme-yellow);
}
.contact-block li {
	font-size: 14px;
	margin-bottom: 8px;
	display: flex;
	align-items: center;
}
.contact-block li:last-child {
	margin-bottom: 0;
}
.contact-block li i {
	font-size: 18px;
	padding-right: 5px;
	vertical-align: middle;
	display: block;
}
.contact-block li i::before {
	line-height: 24px;
}
.contact-block li a {
	color: var(--theme-gray-color);
}
.contact-block li a:hover {
	color: var(--theme-yellow);
	letter-spacing: 1.1px;
}
.social-link {
	margin-top: 30px;
	display: flex;
	align-items: center;
}
.social-link a {
	width: 40px;
	height: 40px;
	font-size: 18px;
	line-height: 40px;
	margin-right: 10px;
	color: var(--theme-gray-color);
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-color: var(--bg-light);
	border-radius: 50%;
	border: 1px solid #dedede;
}
.social-link a i::before {
	line-height: 24px;
}
.social-link a:last-child {
	margin-right: 0;
}
.social-link a:hover {
	color: var(--bg-light);
	background-color: var(--theme-yellow);
	border-color: var(--theme-yellow);
}
.info-list {
	display: grid;
	grid-template-columns: auto auto;
}
.info-list li {
	margin-bottom: 8px;
}
.info-list li a {
	font-size: 14px;
	color: var(--theme-gray-color);
	text-transform: capitalize;
}
.info-list li a:hover {
	color: var(--theme-yellow);
}
.newsletter-inner {
	position: relative;
	margin-top: 16px;
}
.newsletter-inner .news-field {
	width: 100%;
	height: 40px;
	border: none;
	padding: 10px 0;
	color: #777777;
	padding-right: 80px;
	background-color: transparent;
	border-bottom: 1px solid #bcbcbc;
}
.newsletter-inner .news-btn {
	top: 0;
	right: 10px;
	line-height: 40px;
	position: absolute;
	font-size: 14px;
	font-weight: 700;
	color: var(--color);
}
.newsletter-inner .news-btn:hover {
	color: var(--text-color);
}
.newsletter-inner .news-field {
	width: 100%;
	height: 40px;
	border: none;
	padding: 10px 0;
	color: #777777;
	padding-right: 80px;
	background-color: transparent;
	border-bottom: 1px solid #bcbcbc;
}
.newsletter-inner .news-btn {
	top: 0;
	right: 10px;
	line-height: 40px;
	position: absolute;
	font-size: 14px;
	font-weight: 700;
	color: var(--color);
}
.newsletter-inner .news-btn:hover {
	color: var(--text-color);
}
.footer-payment {
	text-align: right;
}

.mobile-sidebar .header-link li .header-title-link {
	font-size: 12px;
	font-weight: 400;
	text-transform: uppercase;
	color: var(--primary);
	position: relative;
	padding-bottom: 8px;
	line-height: 23px;
	width: fit-content;
}
.mobile-sidebar .header-link li .sec-header-link {
	font-size: 12px;
	text-transform: capitalize;
	color: var(--primary);
}
.mobile-sidebar .header-link li .header-title-link::after {
	position: absolute;
	content: "";
	bottom: 0px;
	left: 0px;
	width: 50%;
	height: 1px;
	background-color: var(--color);
}

.mobile-sidebar .header-link li .header-title-link-header {
	font-size: 15px;
	font-weight: 400;
	text-transform: uppercase;
	color: var(--primary);
	position: relative;
	padding-bottom: 8px;
	line-height: 23px;
	width: 100%;
	display: flex !important;
	justify-content: center !important;
}
.mobile-sidebar .header-link li .sec-header-link {
	font-size: 12px;
	text-transform: capitalize;
	color: var(--primary);
}

.mobile-sidebar .header-link li .shope-all-link {
	font-size: 14px;
	font-weight: 400;
	color: var(--primary);
	text-transform: capitalize;
}
.mobile-sidebar .header-link li .shope-all-link i {
	margin-left: 5px;
	color: var(--primary);
}
.mobile-sidebar .header-link li .shope-all-link i::before {
	line-height: 20px;
}
.mobile-sidebar .header-link .sub-header-link {
	padding: 0px 10px;
}
.mobile-sidebar .header-link .sub-header-link li {
	padding-left: 5px;
	position: relative;
	margin-bottom: 5px;
}
.mobile-sidebar .header-link .sub-header-link li:last-child {
	margin-bottom: 0px;
}

.mobile-sidebar .header-link .sub-header-link li a {
	font-size: 14px;
	font-weight: 400;
	color: var(--primary);
	line-height: 20px;
	text-transform: capitalize;
}

.mobile-sidebar .header-link .sub-header-link li::after {
	position: absolute;
	content: "";
	width: 3px;
	height: 3px;
	border-radius: 50%;
	left: -2px;
	top: 50%;
	transform: translate(0, -50%);
	background-color: var(--color);
}
.mobile-sidebar .offcanvas-widget-area .off-canvas-contact-widget ul li a {
	text-transform: capitalize;
}
/* ----- {{ END:: mobile Header }} */

#engraving[style*="Lucida"] {
	transform: scale(0.9, 0.9);
}
