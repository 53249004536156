.overlay_login-content .formlogin-destop {
  width: 85%;
}

.overlay_login-content .formlogin-destop .login-icon-popup-login {
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
}

.overlay_login-content .formlogin-destop .login-icon-popup-login a {
  color: #111111;
}

.overlay_login-content .formlogin-destop .title-tab-login {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  color: #111111;
  text-transform: unset;
  padding-top: 20px;
}

.form-control {
  height: 55px;
  border-radius: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 25px 25px !important;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.overlay_login-content .formlogin-destop .form-check {
  padding-left: 0;
  text-align: left;
  font-size: 14px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.overlay_login-content .formlogin-destop .form-check a {
  color: #c5c4c4;
}

.overlay_login-content .btn {
  height: 55px;
  border-radius: 0;
  background-color: #111111;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: 600;
  margin-top: 20px;
  width: 100%;
  transition: all 0.3s ease;
  border: none;
}

.overlay_login-content .form-control:focus {
  color: #212529;
  background-color: none !important;
  border-color: black !important;
  outline: 0;
  box-shadow: none !important;
}

.overlay_login-content .formlogin-destop .or_creat .box-register {
  display: block;
  text-align: center;
  margin-top: 30px;
  padding: 10px;
  background: #f2f2f2;
  border: 1px solid #e8eced;
  font-size: 14px;
}

.overlay_login-content .formlogin-destop .or_creat .box-register button{
  color: #000;
}

.overlay_login-content .formlogin-destop .or_creat .box-register a {
  color: #c5c4c4;
}
