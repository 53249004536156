/* login css */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,200;9..40,300;9..40,400;9..40,500;9..40,600&family=Frank+Ruhl+Libre:wght@300;400;600&family=Roboto:wght@300;400;500;700&display=swap");

@font-face {
	font-family: "Lucida";
	src: url("../public/fonts/Lucida\ Calligraphy\ W01.ttf") format("opentype");
	font-style: normal;
	font-weight: 800;
}

@font-face {
	font-family: "Avenir-Medium";
	src: url("../public/fonts/Avenir-Medium.ttf") format("opentype");
	font-style: normal;
	font-weight: 800;
}

::-webkit-scrollbar {
	width: 2px;
	border-radius: 8px;
	height: 2px;
}

::-webkit-scrollbar-track {
	background: var(--white);
}

::-webkit-scrollbar-thumb {
	background: var(--theme-yellow);
}

:root {
	--primary: #000000;
	--secondary: #333;
	--gray: #555555;
	--black: #222222;
	--theme-yellow: #c29958;
	--webkit-placeholder: #999;
	--select-color: #fff;
	--white: #fff;
	--off-white: #f5f5f5;
	--light-bg: #f6f6f6;
	--sec-white: #fcfbfe;
	--light-gray: #777;
	--rgba-gray: #0000001f;
	--sec-gray: #b3b2af;
	--tab-font-color: #2c2c2c;
	--lightblue: #b0c4de;
	--darktan: #aa9e78;
	--brown: #964b00;
	--delete-color: #e74c3c;
	--red-rgba: rgb(255, 26, 26, 0.4);
	--mettal-grey: #808080;
	--price-grey: #999;
	--deactive-border: #dedede;
	--border-color-disable: #dedede;
	--border-color-active: #151542;
	--platinum: linear-gradient(30deg, var(--sec-gray) 0%, var(--sec-white) 55%, var(--sec-white) 60%, var(--sec-gray) 100%);
	--white-gold: linear-gradient(30deg, var(--sec-gray) 0%, var(--sec-white) 55%, var(--sec-white) 60%, var(--sec-gray) 100%);
	--yellow-gold: linear-gradient(30deg, #e1b94f 0%, #fcf1d5 55%, #fcf1d5 60%, #e1b94f 100%);
	--rose-gold: linear-gradient(30deg, #ec8a55 0%, #fdede0 55%, #fdede0 60%, #ec8a55 100%);
	--hase-platinum: #c3c3c3;
	--hase-yellow: #efd9a7;
	--hase-rosegold: #d0a68e;
	--border-color: #ccc;
	--off-white: #f1f1f1;
	--sec-white: #f7f7f7;
	--border-bottom: #efefef;
	--disabled: #dddddd;
	--theme-light-yellow-rgb: rgba(194, 153, 88, 0.1);
	--theme-yellow-rgb: rgba(194, 153, 88, 0.9);
	--theme-btn-bg: #003f60;
	--theme-bg-light: #edf9ff;
	--theme-title: #003f60;
	--theme-yellow-color: #fabc5f;
	--theme-title-text: rgb(0, 63, 96, 0.7);
	--theme-gray-color: #777777;
	--theme-yellow-color-rgba: rgb(250, 188, 95, 0.2);
	--font-family: "DM Sans";
}

body {
	color: var(--gray);
	line-height: 1.7;
	font-size: 14px;
	font-weight: 400;
	font-family: var(--font-family);
}

a {
	-webkit-transition: 0.4s;
	-o-transition: 0.4s;
	transition: 0.4s;
	text-decoration: none !important;
}

a:hover,
a:focus {
	outline: none;
	text-decoration: none;
}

p {
	margin-bottom: 8px;
}

p:last-child {
	margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--black);
	font-weight: 700;
	margin: 0;
	line-height: 1.2;
}

h1 {
	font-size: 36px;
}

h2 {
	font-size: 30px;
}

h3 {
	font-size: 24px;
}

h4 {
	font-size: 20px;
}

h5 {
	font-size: 18px;
}

h6 {
	font-size: 16px;
}

ul {
	margin: 0;
	padding: 0;
}

ul li {
	list-style: none;
}

strong,
b {
	font-weight: 700;
}

figure {
	margin: 0;
}

img {
	max-width: 100%;
	-webkit-transition: 0.4s;
	-o-transition: 0.4s;
	transition: 0.4s;
}

.btn,
button {
	border: none;
	cursor: pointer;
	outline: none;
	padding: 0 !important;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: 0.4s;
	-o-transition: 0.4s;
	transition: 0.4s;
	background-color: transparent;
	color: #000;
}

.login--close {
	padding: 10px !important;
	margin-right: 20px;
}

.btn:active,
.btn:focus,
button:active,
button:focus {
	outline: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}

input,
textarea {
	resize: none;
}

input:focus,
textarea:focus {
	outline: none;
}

.form-control:focus {
	border-color: var(--theme-yellow);
	-webkit-box-shadow: none;
	box-shadow: none;
}

.form-group {
	margin-bottom: 25px;
	position: relative;
}

::-moz-selection {
	color: var(--select-color);
	background: var(--theme-yellow);
}

::selection {
	color: var(--select-color);
	background: var(--theme-yellow);
}

::-webkit-input-placeholder {
	color: var(--webkit-placeholder);
	font-size: 13px;
	opacity: 1;
}

::-moz-placeholder {
	color: var(--webkit-placeholder);
	font-size: 13px;
	opacity: 1;
}

:-ms-input-placeholder {
	color: var(--webkit-placeholder);
	font-size: 13px;
	opacity: 1;
}

::-ms-input-placeholder {
	color: var(--webkit-placeholder);
	font-size: 13px;
	opacity: 1;
}

::placeholder {
	color: var(--webkit-placeholder);
	font-size: 13px;
	opacity: 1;
}

.section-padding {
	padding-top: 80px;
	padding-bottom: 80px;
}

.price-box {
	font-size: 15px;
	line-height: 1;
}

.price-regular {
	color: var(--theme-yellow);
	font-weight: 500;
}

[class^="iconking-"],
[class*="iconking-"] {
	background: url("./image/iconking-new3.png");
	background-repeat: no-repeat;
	background-size: 120px auto;
	display: inline-block;
	height: 28px;
	width: 28px;
	min-width: 28px;
}

.herosection--btn {
	position: relative;
	width: fit-content;
	padding: 8px 25px !important;
	text-transform: capitalize;
	background: var(--theme-yellow);
	color: var(--white);
	overflow: hidden;
}

.heroButton--slider {
	position: relative;
	width: fit-content;
	padding: 8px 25px !important;
	text-transform: capitalize;
	border: 1px solid var(--theme-yellow);
	color: var(--off-white);
	overflow: hidden;
	background-color: var(--theme-yellow);
}

/* {{ START:: slider button css }} */
.product-slider--container .slick-prev,
.product-slider--container .slick-next {
	top: -22px !important;
}

.product-slider--container .slick-prev {
	left: auto !important;
	right: 10%;
}

.product-slider--container .slick-next {
	right: 0px !important;
}

.slider--container .slick-prev {
	left: -6%;
}

.slider--container .slick-next {
	right: -6%;
}

.slider--container .slick-prev,
.slider--container .slick-next {
	width: 34px;
	height: 34px;
	background: var(--theme-light-yellow-rgb);
	z-index: 1;
	border-radius: 50%;
}

.slider--container .slick-prev::before,
.slider--container .slick-next::before {
	position: absolute;
	content: "";
	width: 16px;
	height: 16px;
	background-image: url(./image/angle-right.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.slider--container .slick-prev::before {
	transform: rotate(-180deg) translate(50%, 45%);
}

.slider--container .slick-prev:hover,
.slider--container .slick-next:hover,
.slider--container .slick-prev:active,
.slider--container .slick-next:active,
.slider--container .slick-prev:focus,
.slider--container .slick-next:focus {
	background: var(--theme-light-yellow-rgb);
}

/* {{ END:: slider button css }} */

.diamond .bABHpC,
.diamond .ceAOzV,
.diamond .iKdJGd {
	background-color: transparent;
	font-family: var(--font-family);
}

.diamond .fEnJOm {
	background-color: var(--theme-yellow);
}

.diamond .iKdJGd {
	padding-right: 8px;
	margin-right: 8px;
}

.diamond .dmvfUG:hover {
	background-color: var(--theme-light-yellow-rgb);
	border-bottom-color: var(--rgba-gray);
	outline-style: none;
	outline-width: 0px;
	outline-color: transparent;
}

.diamond .eirtpj:hover:not(:disabled),
.diamond .eirtpj:focus {
	background-color: transparent;
}

.diamond .info-4yj .text-oed {
	display: flex;
	align-items: center;
}

.diamond .jNvkxB {
	color: var(--white);
}

.diamond .gZqYPb:hover {
	background-color: var(--theme-light-yellow-rgb);
	border-bottom-color: var(--rgba-gray);
	outline-style: none;
	outline-width: 0px;
	outline-color: transparent;
}

.diamond .jNvkxB {
	font-size: 14px;
}

.diamond .fssVbF,
.diamond .elnqKc,
.diamond .iCCOt:hover .buVaUk {
	color: var(--white);
}

.diamond .bABHpC:nth-child(4),
.diamond .bABHpC:nth-child(5),
.diamond .eZUYAn:nth-child(4),
.diamond .eZUYAn:nth-child(5) {
	justify-content: end;
}

.diamond .bABHpC:nth-child(6),
.diamond .eZUYAn:nth-child(6),
.diamond .bABHpC:nth-child(7),
.diamond .eZUYAn:nth-child(7),
.diamond .bABHpC:nth-child(8),
.diamond .eZUYAn:nth-child(8) {
	justify-content: center;
}

/* {{ END:: Table CSS }} */

/* {{START:: Animation css}} */
@-webkit-keyframes shine {
	0% {
		background-position: -500%;
	}

	100% {
		background-position: 100%;
	}
}

@keyframes shine {
	0% {
		background-position: -500%;
	}

	100% {
		background-position: 100%;
	}
}

/* {{END:: Animation css}} */

/* ---- {{ START:: Home page css }} ---- */
.mainSlider {
	position: relative;
}

.mainSlider .heroSliderImage_1 {
	position: relative;
	height: 600px;
	display: flex !important;
	align-items: center;
	z-index: 10;
}

.mainSlider .heroSliderImage_1 img {
	z-index: -1;
	position: absolute;
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.mainSlider .heroSliderImage_2 {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 670px !important;
	display: flex !important;
	align-items: center;
}

.mainSlider .heroSliderImage {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 670px !important;
	display: flex !important;
	align-items: center;
}

.ready-to-ship--slider {
	height: 500px;
}

.ready-to-ship--slider .slick-list,
.ready-to-ship--slider .slick-list .slick-track,
.ready-to-ship--slider .slick-list .slick-track .slick-slide,
.ready-to-ship--slider .slick-list .slick-track .slick-slide > div {
	height: 100%;
}

.mainSlider .slick-dots,
.banner-engagement--slider .slick-dots,
.feature-card--slider .slick-dots,
.ready-to-ship--slider .slick-dots,
.diamond-page--slider .slick-dots {
	bottom: 20px;
	height: 35px;
	line-height: 35px;
}

.mainSlider .slick-dots li,
.banner-engagement--slider .slick-dots li,
.feature-card--slider .slick-dots li,
.ready-to-ship--slider .slick-dots li,
.diamond-page--slider .slick-dots li {
	width: 25px;
	height: 25px;
	line-height: 25px;
	display: none;
	color: var(--secondary);
	opacity: 0.2;
}

.mainSlider .slick-dots li .dot--box,
.banner-engagement--slider .slick-dots li .dot--box,
.feature-card--slider .slick-dots li .dot--box,
.ready-to-ship--slider .slick-dots li .dot--box,
.diamond-page--slider .slick-dots li .dot--box {
	width: 100%;
	height: 100%;
}

.mainSlider .slick-dots .hero-slick--slider-dots,
.banner-engagement--slider .slick-dots .hero-slick--slider-dots,
.feature-card--slider .slick-dots .hero-slick--slider-dots,
.ready-to-ship--slider .slick-dots .hero-slick--slider-dots,
.diamond-page--slider .slick-dots .hero-slick--slider-dots {
	width: fit-content;
	display: flex;
	align-items: center;
	margin-left: auto;
	margin-right: 55px;
	height: 100%;
}

.mainSlider .slick-dots .hero-slick--slider-dots .slick-active,
.banner-engagement--slider .slick-dots .hero-slick--slider-dots .slick-active,
.feature-card--slider .slick-dots .hero-slick--slider-dots .slick-active,
.ready-to-ship--slider .slick-dots .hero-slick--slider-dots .slick-active,
.diamond-page--slider .slick-dots .hero-slick--slider-dots .slick-active {
	width: 35px;
	height: 35px;
	line-height: 35px;
	color: var(--theme-yellow);
	opacity: 1;
	display: block;
	border-radius: 100%;
	border: 1px solid var(--theme-yellow);
}

.mainSlider .slick-dots .hero-slick--slider-dots .slick-active .dot--box,
.banner-engagement--slider .slick-dots .hero-slick--slider-dots .slick-active .dot--box,
.feature-card--slider .slick-dots .hero-slick--slider-dots .slick-active .dot--box,
.ready-to-ship--slider .slick-dots .hero-slick--slider-dots .slick-active .dot--box,
.diamond-page--slider .slick-dots .hero-slick--slider-dots .slick-active .dot--box {
	font-size: 19px;
}

.mainSlider .slick-arrow,
.banner-engagement--slider .slick-arrow,
.feature-card--slider .slick-arrow,
.ready-to-ship--slider .slick-arrow,
.diamond-page--slider .slick-arrow {
	top: auto;
	bottom: 0;
	width: auto;
}

.mainSlider .slick-prev,
.banner-engagement--slider .slick-prev,
.feature-card--slider .slick-prev,
.ready-to-ship--slider .slick-prev,
.diamond-page--slider .slick-prev {
	left: auto;
	right: 101px;
	z-index: 1;
	bottom: 25px;
}

.mainSlider .slick-next,
.banner-engagement--slider .slick-next,
.feature-card--slider .slick-next,
.ready-to-ship--slider .slick-next,
.diamond-page--slider .slick-next {
	right: 34px;
	z-index: 1;
	bottom: 10px;
}

.mainSlider .slick-prev::before,
.banner-engagement--slider .slick-prev::before,
.feature-card--slider .slick-prev::before,
.ready-to-ship--slider .slick-prev::before,
.diamond-page--slider .slick-prev::before {
	transform: rotate(-180deg);
}

.mainSlider .slick-prev::before,
.mainSlider .slick-next::before,
.banner-engagement--slider .slick-prev::before,
.banner-engagement--slider .slick-next::before,
.feature-card--slider .slick-prev::before,
.feature-card--slider .slick-next::before,
.ready-to-ship--slider .slick-prev::before,
.ready-to-ship--slider .slick-next::before,
.diamond-page--slider .slick-prev::before,
.diamond-page--slider .slick-next::before {
	content: "";
	display: block;
	width: 20px;
	height: 20px;
	background: url(./image/arrow-right.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.mainSlider .heroSliderImage .slide-title,
.hero-section--banner .slide-title,
.mainSlider .heroSliderImage_2 .slide-title,
.mainSlider .heroSliderImage_1 .slide-title {
	font-size: 60px;
	font-weight: normal;
	line-height: 70px;
	font-family: var(--font-family);
	color: var(--theme-bg-light);
}

.mainSlider .heroSliderImage .slide-title span,
.mainSlider .heroSliderImage_2 .slide-title span,
.mainSlider .heroSliderImage_1 .slide-title span {
	display: block;
}

.mainSlider .heroSliderImage .slide-desc,
.mainSlider .heroSliderImage_2 .slide-desc,
.hero-section--banner .slide-desc,
.mainSlider .heroSliderImage_1 .slide-desc {
	font-size: 20px;
	font-weight: 300;
	line-height: 1.3;
	color: var(--theme-bg-color);
	padding-top: 20px;
	font-family: var(--font-family);
}

.mainSlider .heroSliderImage .btn-hero,
.mainSlider .heroSliderImage_2 .btn-hero,
.mainSlider .heroSliderImage_1 .btn-hero {
	color: var(--white);
	font-size: 15px;
	line-height: 1;
	padding: 14px 30px !important;
	display: inline-block;
	border-radius: 50px;
	background-color: var(--theme-yellow);
	margin-top: 38px;
}

.ur3-category-box {
	text-align: center;
}

.ur3-category-box .thumb-wrapper {
	width: 200px;
	height: 200px;
	padding: 40px;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	z-index: 1;
}

.ring_main_div {
	padding-top: 80px;
}

.ring_main_div .ring_slider {
	margin-bottom: 40px !important;
}

.ring_main_div .ring_slider h2 {
	-webkit-text-fill-color: hsla(0, 0%, 100%, 0);
	-webkit-animation: shine 3s linear infinite;
	animation: shine 3s linear infinite;
	background: linear-gradient(90deg, #c29958, #000, #c29958);
	background: linear-gradient(90deg, var(--theme-yellow), var(--primary), var(--theme-yellow));
	-webkit-background-clip: text;
	background-repeat: no-repeat;
	background-size: 90%;
	text-transform: capitalize;
}

.ring_main_div .ur3-category-box .thumb-wrapper::before {
	content: "";
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	border: 1px solid var(--theme-yellow);
	width: 200px;
	height: 200px;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	z-index: 999999;
	opacity: 0;
}

.light-bg {
	background-color: var(--light-bg);
}

.ring_main_div .ur3-category-box .thumb-wrapper img {
	transition: all 0.3s;
	height: 120px;
	width: 120px;
	border-radius: 100%;
}

.ring_main_div .ur3-category-box .ct-title {
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

.ring_main_div .ur3-category-box .ct-title h6 {
	margin-top: 30px;
	transition: all 0.3s;
	color: var(--theme-gray-color);
	margin-top: 10px !important;
	line-height: 20px;
}

.ring_main_div .ur3-category-box .ct-title h6:hover {
	color: var(--theme-yellow) !important;
}

.ring_main_div .ur3-category-box:hover .thumb-wrapper::before {
	opacity: 1;
	-webkit-transform: translate(-50%, -50%) scale(0.8);
	-ms-transform: translate(-50%, -50%) scale(0.8);
	transform: translate(-50%, -50%) scale(0.8);
}

.ring_main_div .ur3-category-box:hover .thumb-wrapper img {
	-webkit-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
}

.policy-item {
	display: flex;
	justify-content: center;
	margin-bottom: 0px;
}

.policy-item .policy-content h6 {
	color: var(--theme-yellow);
}

.policy-icon {
	font-size: 30px;
	line-height: 1;
	color: var(--theme-yellow);
	padding-right: 16px;
}

.policy-content p {
	color: var(--theme-gray-color);
	padding-top: 9px;
}

.ring-main-div--slider .slick-arrow,
.tab-content-slider .slick-arrow,
.jewelry-category .slick-arrow,
.education-container .education-silder--box .slick-arrow {
	width: 40px;
	height: 40px;
	z-index: 1;
	background: var(--theme-yellow-rgb);
	border-radius: 50%;
}

.ring-main-div--slider .slick-prev,
.jewelry-category .slick-prev,
.tab-content-slider .slick-prev,
.education-container .education-silder--box .slick-prev {
	left: -44px;
}

.ring-main-div--slider .slick-next,
.jewelry-category .slick-next,
.tab-content-slider .slick-next,
.education-container .education-silder--box .slick-next {
	right: -44px;
}

.ring-main-div--slider .slick-prev::before,
.ring-main-div--slider .slick-next::before,
.tab-content-slider .slick-next::before,
.tab-content-slider .slick-prev::before,
.jewelry-category .slick-next::before,
.jewelry-category .slick-prev::before,
.education-container .education-silder--box .slick-prev::before,
.education-container .education-silder--box .slick-next::before {
	content: "";
	display: block;
	width: 20px;
	height: 20px;
	margin: auto;
	background: url(./image/arrow-right.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.ring-main-div--slider .slick-next:hover,
.ring-main-div--slider .slick-next:focus,
.ring-main-div--slider .slick-prev:focus,
.ring-main-div--slider .slick-prev:hover,
.tab-content-slider .slick-next:hover,
.tab-content-slider .slick-prev:hover,
.tab-content-slider .slick-next:focus,
.tab-content-slider .slick-prev:focus,
.jewelry-category .slick-next:hover,
.jewelry-category .slick-prev:hover,
.jewelry-category .slick-next:focus,
.jewelry-category .slick-prev:focus,
.education-container .education-silder--box .slick-next:hover,
.education-container .education-silder--box .slick-next:focus,
.education-container .education-silder--box .slick-prev:hover,
.education-container .education-silder--box .slick-next:focus {
	background: var(--theme-yellow);
}

.ring-main-div--slider .slick-prev.slick-disabled,
.ring-main-div--slider .slick-next.slick-disabled,
.tab-content-slider .slick-next.slick-disabled,
.tab-content-slider .slick-prev.slick-disabled,
.jewelry-category .slick-next.slick-disabled,
.jewelry-category .slick-prev.slick-disabled,
.education-container .education-silder--box .slick-next.slick-disabled,
.education-container .education-silder--box .slick-prev.slick-disabled {
	opacity: 0.25;
}

.ring-main-div--slider .slick-next.slick-disabled::before,
.ring-main-div--slider .slick-prev.slick-disabled::before,
.tab-content-slider .slick-next.slick-disabled::before,
.tab-content-slider .slick-prev.slick-disabled::before,
.jewelry-category .slick-next.slick-disabled::before,
.jewelry-category .slick-prev.slick-disabled::before,
.education-container .education-silder--box .slick-next.slick-disabled::before,
.education-container .education-silder--box .slick-prev.slick-disabled::before {
	opacity: 1;
}

.ring-main-div--slider .slick-prev::before,
.tab-content-slider .slick-prev::before,
.jewelry-category .slick-prev::before,
.education-container .education-silder--box .slick-prev::before {
	transform: rotate(-180deg);
}

.banner-statistics {
	position: relative;
	margin-bottom: 16px !important;
}

.banner-statistics .small-product-banner {
	display: none;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	transform: translateX(-100%);
}

.banner-statistics:hover .small-product-banner {
	display: block !important;
	animation: banner-hover 0.5s ease-in-out forwards;
	transform: translateX(0%);
}

@keyframes banner-hover {
	0% {
		transform: translateX(-100%);
		opacity: 0;
	}

	100% {
		transform: translateX(0%);
		opacity: 1;
	}
}

.banner-statistics img {
	width: 100%;
	height: 300px;
	object-fit: cover;
}

.banner-statistics:hover img {
	opacity: 0.7;
}

.banner-content {
	right: 55px;
	top: 50%;
	position: absolute;
	transform: translateY(-50%);
	width: fit-content;
	padding: 10px;
}

.banner-content .banner-text1 {
	font-size: 30px;
	font-weight: 400;
	font-family: var(--font-family);
	color: var(--theme-yellow);
}

.banner-text2 {
	margin-bottom: 10px;
	margin-left: auto;
	transition: 0.4s;
	margin-top: 10px;
	font-size: 16px;
	color: var(--theme-bg-color);
	max-width: 330px;
	width: 100%;
	height: 75px;
	font-weight: 300;
	line-height: 25px;
	font-family: var(--font-family);
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}

.banner-text2 span {
	display: block;
}

.section-title {
	margin-top: 0px;
	margin-bottom: 37px;
}

.section-title .title {
	text-transform: capitalize;
	background: -webkit-linear-gradient(left, var(--theme-yellow), var(--primary), var(--theme-yellow));
	background: -o-linear-gradient(left, var(--theme-yellow), var(--primary), var(--theme-yellow));
	background: linear-gradient(90deg, var(--theme-yellow), var(--primary), var(--theme-yellow));
	background-repeat: no-repeat;
	background-size: 90%;
	-webkit-animation: shine 3s linear infinite;
	animation: shine 3s linear infinite;
	-webkit-background-clip: text;
	-webkit-text-fill-color: rgba(255, 255, 255, 0);
}

.product-area .main_tabs {
	grid-gap: 0px 5px;
}

.product-area .main_tabs .nav-link {
	border: 0px;
	padding: 5px 20px !important;
	width: fit-content;
	border-radius: 25px;
	color: var(--tab-font-color);
	font-weight: 500;
}

.product-area .main_tabs .nav-link.active {
	background-color: var(--theme-yellow);
	color: var(--white);
}

.product-item {
	overflow: hidden;
}

.product-thumb {
	position: relative;
	width: 300px;
	height: 300px;
	margin: auto;
	display: block;
}

.product-thumb img {
	width: 100%;
}

.product-thumb .sec-img {
	top: 0;
	left: 0;
	position: absolute;
	opacity: 0;
	visibility: hidden;
}

.product-item .btn-cart {
	padding: 12px 25px !important;
	background-color: var(--white);
	border-radius: 30px;
	text-transform: capitalize;
	-webkit-box-shadow: 0 2px 1px 0.5px rgba(0, 0, 0, 0.05);
	box-shadow: 0 2px 1px 0.5px rgba(0, 0, 0, 0.05);
}

.product-item .btn-cart:hover {
	color: var(--white) !important;
	background-color: var(--theme-yellow) !important;
}

.product-item:hover .cart-hover {
	bottom: 15px;
	opacity: 1;
	visibility: visible;
}

.product-item .cart-hover {
	bottom: 0;
	left: 0;
	right: 0;
	position: absolute;
	text-align: center;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: 0.4s;
	-o-transition: 0.4s;
	transition: 0.4s;
}

.tab-content-slider .slick-slide {
	padding: 0px 10px;
}

.product-caption {
	padding-top: 10px;
}

.manufacturer-name {
	padding-bottom: 5px;
	line-height: 1;
}

.manufacturer-name a {
	font-size: 14px;
	line-height: 1;
	color: var(--light-gray);
	text-transform: capitalize;
	text-decoration: none;
}

.manufacturer-name a:hover {
	color: var(--theme-yellow);
}

.product-caption .product-name {
	font-weight: 400;
	line-height: 1.2;
	font-size: 14px;
}

.product-caption .product-name a {
	color: var(--black);
	text-transform: capitalize;
	display: block;
	font-family: var(--font-family);
	margin-bottom: 15px;
	margin-top: 15px;
}

.product-caption .product-name a:hover {
	color: var(--theme-yellow);
}

.product-item:hover .product-thumb .sec-img {
	opacity: 1;
	visibility: visible;
}

.banner-statistics:hover img {
	opacity: 0.7;
}

.section {
	padding: 0px 1.40625pc;
}

.section .section-container {
	max-width: 1425px;
	width: 100%;
	margin: 0 auto;
}

.lookbook-items {
	padding: 70px 0px;
}

.lookbook-items .lookbook-content img {
	max-width: 500px;
	width: 100%;
	height: auto;
	border-radius: 18px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.lookbook-items .lookbook-intro-wrap {
	position: absolute;
	top: auto;
	bottom: 18%;
	width: 45%;
	left: 46%;
	padding: 30px;
	border-radius: 24px;
	background-color: var(--white);
	box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}

.lookbook-content .item {
	position: relative;
}

.lookbook-content .item .item-lookbook {
	cursor: pointer;
	position: absolute;
	left: 43%;
	top: 77%;
	display: inline-block;
	text-align: center;
	border-radius: 50%;
	height: 25.5pt !important;
	width: 34px !important;
	line-height: 34px;
	text-indent: -0.0625pc;
	z-index: 5;
	transition: width 0.2s ease, height 0.2s ease;
}

.lookbook-content .item .item-lookbook::before {
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
	animation-duration: 2s;
	animation-name: n;
	z-index: -1;
	border-radius: 50%;
	border: 1px solid var(--sec-white);
	top: calc(50% - 20px);
	left: calc(50% - 20px);
	content: "";
	position: absolute;
	height: 2.5pc;
	width: 2.5pc;
}

.lookbook-content .item .item-lookbook::after {
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
	animation-duration: 2s;
	animation-name: n;
	z-index: -1;
	border-radius: 50%;
	border: 1px solid var(--sec-white);
	top: calc(50% - 20px);
	left: calc(50% - 20px);
	content: "";
	position: absolute;
	height: 2.5pc;
	width: 2.5pc;
}

.lookbook-content .item .item-lookbook .number-lookbook {
	transition: all 0.3s ease;
	left: 0;
	top: 0;
	position: absolute;
	display: inline-block;
	background: var(--white);
	border-radius: 50%;
	line-height: 34px;
	height: 2.125pc;
	width: 2.125pc;
}

.lookbook-content .item .item-lookbook .number-lookbook:hover {
	background: var(--theme-yellow);
	color: var(--white);
}

.lookbook-content .item .item-lookbook .number-lookbook:hover .gg-math-plus,
.lookbook-content .item .item-lookbook .number-lookbook:hover .gg-math-plus::after {
	background: var(--white);
}

.lookbook-content .item .item-lookbook .number-lookbook .gg-math-plus,
.lookbook-content .item .item-lookbook .number-lookbook .gg-math-plus::after {
	display: block;
	border-radius: 10px;
	background: currentColor;
}

.lookbook-content .item .item-lookbook .number-lookbook .gg-math-plus {
	margin-top: -2px;
	position: relative;
	transform: scale(var(--ggs, 1)) translate(-50%, 35%);
	width: 16px;
	height: 2px;
	top: 50%;
	left: 50%;
	background: var(--theme-yellow);
}

.lookbook-content .item .item-lookbook .number-lookbook .gg-math-plus::after {
	content: "";
	position: absolute;
	width: 2px;
	height: 16px;
	top: -7px;
	left: 7px;
	background: var(--theme-yellow);
}

.lookbook-content .item .item-lookbook .content-lookbook {
	position: absolute;
	left: 35px;
	bottom: 10px;
	display: none;
	background: var(--white);
	transition: all 0.2s ease;
	box-shadow: -0.010416667in 0.3125pc 5px 0 rgba(182, 41, 41, 0.1);
	width: 2.34375in;
	padding: 0.625pc;
	text-align: center;
	z-index: 9;
}

.lookbook-content .item .item-lookbook .content-lookbook .content-lookbook-bottom {
	margin-top: 10px;
}

.lookbook-content .item .item-lookbook .content-lookbook-bottom .item-title {
	line-height: 0.1875in;
	text-transform: capitalize;
}

.lookbook-content .item .item-lookbook .content-lookbook-bottom .item-title a {
	font-size: 12pt;
	line-height: 1.5625pc;
	color: var(--primary);
}

.lookbook-content .item .item-lookbook:hover .content-lookbook {
	display: block;
}

.lookbook-content .item .item-lookbook .content-lookbook-bottom del {
	color: #ababab;
	font-size: 10.5pt;
}

.lookbook-content .item .item-lookbook .content-lookbook-bottom ins {
	color: var(--theme-yellow);
	text-decoration: none;
}

.lookbook-intro-wrap .lookbook-intro .title {
	margin-bottom: 8px;
	font-size: 30pt;
	line-height: 42px;
	color: var(--theme-yellow);
	font-family: var(--font-family);
	font-weight: 400;
	text-overflow: ellipsis;
	overflow: hidden;
}

.lookbook-intro-wrap .lookbook-intro .description {
	margin-bottom: 1.25pc;
	color: var(--theme-gray-color);
}

.lookbook-intro-wrap .lookbook-intro .animation-horizontal:hover {
	animation-iteration-count: 1;
	animation-name: animation-horizontal;
	animation-timing-function: ease-in-out;
	animation-duration: 1s;
}

@keyframes n {
	0% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		transform: scale(2);
		opacity: 0;
	}
}

@keyframes animation-horizontal {
	16.65% {
		transform: translateX(8px);
	}

	33.3% {
		transform: translateX(-6px);
	}

	49.95% {
		transform: translateX(4px);
	}

	66.6% {
		transform: translateX(-2px);
	}

	83.25% {
		transform: translateX(1px);
	}

	100% {
		transform: translateX(0);
	}
}

.hero-section--banner {
	z-index: 1;
	height: 589px;
	display: flex;
	align-items: center;
}

.hero-section--banner img {
	position: absolute;
	z-index: -1;
	height: 100%;
	object-fit: cover;
}

.banner-statistics-area .banner-statistics--slider .slick-dots {
	bottom: 30px;
	max-width: fit-content;
	width: 100%;
	left: 8px;
	height: 20px;
	display: flex !important;
	align-items: center;
}

.banner-statistics-area .banner-statistics--slider .slick-dots li {
	border-radius: 50px;
	overflow: hidden;
}

.banner-statistics-area .banner-statistics--slider .slick-dots li,
.banner-statistics-area .banner-statistics--slider .slick-dots li button,
.banner-statistics-area .banner-statistics--slider .slick-dots li button::before {
	width: 20px;
	height: 4px;
}

.banner-statistics-area .banner-statistics--slider .slick-dots li button::before {
	background-color: var(--theme-yellow);
	display: block;
	line-height: 3px;
	content: "";
}

.banner-statistics-area .banner-content .heroButton--slider {
	margin-left: auto;
}

.banner-statistics-area .banner-content .heroButton--slider:hover {
	background-color: var(--theme-yellow-color-rgba);
}

/* ---- {{ END:: Home page css }} ---- */

/* ---- {{ START:: Product Page Css }} ---- */

.total-recod {
	padding: 7px 10px;
	background: var(--theme-light-yellow-rgb);
	border-radius: 8px;
}

.total-recod span {
	font-family: var(--font-family);
	font-weight: 400;
	text-transform: capitalize;
	color: var(--black);
}

.btn--reset-1 {
	background: var(--theme-yellow);
	color: var(--white);
	width: 32px;
	height: 32px;
	border-radius: 8px;
	line-height: 0px;
}

.filter--box .btn--reset {
	background: var(--white);
	width: 32px;
	height: 32px;
	border-radius: 8px;
}

.filter--box .btn--reset i {
	color: var(--theme-yellow) !important;
	display: block;
	font-size: 14px;
	line-height: 18px;
}

.filter--box .btn--reset i::before {
	line-height: 32px !important;
}

.product-details-inner .slick-list {
	margin-left: 85px !important;
}

.product-details-inner .slick-dots {
	bottom: auto;
	top: 0;
	display: flex !important;
	flex-direction: column;
	left: 0;
	width: -moz-fit-content;
	width: fit-content;
	height: 100%;
}

.product-details-inner .slick-dots li {
	width: 68px !important;
	height: 68px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #ececec;
	border-radius: 5px;
	overflow: hidden;
	margin-bottom: 15px;
	padding: 0 !important;
}

.product-details-inner .slick-dots li a {
	display: block;
	width: 100%;
	height: 100%;
}

.product-details-inner .slick-dots li a img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.product-details-inner .slick-dots .slick-active {
	border: 1px solid var(--theme-yellow);
	border-radius: 5px;
}

.product-details-des {
	position: sticky;
	top: 80px;
}

.product-details-des .product-name {
	font-weight: 400;
	padding-top: 5px;
	padding-bottom: 5px;
	text-transform: capitalize;
}

.product-details-des .diamond_heading {
	font-weight: 400;
	font-size: 20px;
	padding-top: 5px;
	padding-bottom: 5px;
	text-transform: capitalize;
}

.product-details-des .manufacturer-name {
	padding-bottom: 5px;
	line-height: 1;
}

.product-details-des .manufacturer-name a {
	font-size: 14px;
	line-height: 1;
	color: var(--light-gray);
	text-transform: capitalize;
	text-decoration: none;
}

.product-details-des .price-box {
	padding-top: 10px;
	padding-bottom: 10px;
}

.product-details-des .offer-text {
	font-weight: 400;
	margin-bottom: 10px;
}

.product-details-des .availability {
	margin-bottom: 15px;
}

.product-details-des .availability i {
	color: #81ca33;
}

.product-details-des .availability span {
	color: var(--gray);
	font-size: 13px;
	font-weight: 500;
	line-height: 1;
	padding-left: 3px;
	text-transform: uppercase;
}

.product-details-des .pro-desc {
	margin-bottom: 20px;
}

.product-details-inner .select-js9,
.product-details-inner .list-9zn,
.product-details-inner .select-js9 span,
.product-details-inner .list-9zn li,
.product-details-inner .ir237-cart-j1m li a,
.product-details-inner .list-b27 li,
.product-details-inner .select-kxw span,
.product-details-inner .list-b27 li a {
	max-height: 999999px;
}

.product-details-inner .list-9zn {
	margin-top: 0;
	margin-bottom: 10px;
	padding-left: 0;
	list-style: none;
}

.product-details-inner .select-9om,
.product-details-inner .ir237-cart-j1m {
	font-size: 0;
}

.product-details-inner .list-9zn li,
.product-details-inner .list-b27 li {
	display: inline-block;
	padding-right: 5px;
	padding-left: 5px;
}

.product-details-inner .select-9om li {
	font-size: 14px;
	margin-bottom: 15px;
}

.product-details-inner .ir237-cart-j1m li {
	padding: 0;
	margin-right: 10px;
	margin-bottom: 10px;
}

.product-details-inner .ir237-cart-j1m li a {
	color: #428bca;
}

.product-details-inner .select-9om .val-toi {
	text-transform: uppercase;
	display: block;
	text-align: center;
	min-width: auto;
	width: 36px;
	height: 36px;
	border-radius: 50%;
	line-height: 32px;
	letter-spacing: 0.05em;
	border: 2px solid transparent;
	font-size: 12px;
	color: inherit;
	padding: 0 5px;
}

.product-details-inner .select-9om .val-toi.td-jez {
	text-transform: uppercase;
	display: block;
	text-align: center;
	min-width: auto;
	width: 36px;
	height: 36px;
	border-radius: 50%;
	line-height: 32px;
	letter-spacing: 0.05em;
	border: 2px solid var(--deactive-border);
	font-size: 12px;
	color: inherit;
	padding: 0 5px;
}

.product-details-inner .select-9om .val-toi:active,
.product-details-inner .select-9om .val-toi:hover {
	outline: 0;
}

.product-details-inner .select-9om .val-toi:hover {
	color: #2a6496;
	text-decoration: underline;
}

.product-details-inner .select-9om .val-toi.act-5e5 {
	border-color: var(--theme-yellow);
}

.product-details-inner .select-js9 span,
.product-details-inner .select-kxw .avenir-kqm,
.product-details-inner .ring-size {
	font-size: 16px;
	font-weight: 600;
	text-transform: capitalize;
}

.product-details-inner .select-js9,
.product-details-inner .select-kxw {
	font-size: 14px;
}

.product-details-inner .list-b27 {
	padding-left: 0;
	list-style: none;
}

.product-details-inner .select-nqw .val-oep {
	height: 34px;
	width: 34px;
	border-radius: 100%;
	overflow: hidden;
	display: block;
	border: 2px solid transparent;
	padding: 2px;
	cursor: pointer;
}

.product-details-inner .select-nqw .val-oep::after {
	content: "";
	display: block;
	width: 26px;
	height: 26px;
	border-radius: 100%;
	cursor: pointer;
}

.product-details-inner .select-nqw .value-owv::after {
	background: var(--sec-gray) !important;
	background: -moz-linear-gradient(30deg, var(--sec-gray) 0, var(--sec-white) 55%, var(--sec-white) 60%, var(--sec-gray) 100%) !important;
	background: -webkit-linear-gradient(30deg, var(--sec-gray) 0, var(--sec-white) 55%, var(--sec-white) 60%, var(--sec-gray) 100%) !important;
	background: linear-gradient(30deg, var(--sec-gray) 0, var(--sec-white) 55%, var(--sec-white) 60%, var(--sec-gray) 100%) !important;
}

.product-details-inner .select-nqw .val-oep:active,
.product-details-inner .select-nqw .val-oep:hover {
	outline: 0;
}

.product-details-inner .select-nqw .val-oep:hover {
	color: #2a6496;
	text-decoration: underline;
}

.product-details-inner .select-nqw .value-owv:hover,
.product-details-inner .select-nqw .val-oep:hover {
	border: 2px solid #3e9f8e;
}

.product-details-inner .select-nqw .value-ddf::after {
	background: #e1b94f !important;
	background: -moz-linear-gradient(30deg, #e1b94f 0, #faf1d8 55%, #faf1d8 60%, #e1b94f 100%) !important;
	background: -webkit-linear-gradient(30deg, #e1b94f 0, #faf1d8 55%, #faf1d8 60%, #e1b94f 100%) !important;
	background: linear-gradient(30deg, #e1b94f 0, #faf1d8 55%, #faf1d8 60%, #e1b94f 100%) !important;
}

.product-details-inner .select-nqw .value-lxa::after {
	background: #ec8a55 !important;
	background: -moz-linear-gradient(30deg, #ec8a55 0, #fcede2 55%, #fcede2 60%, #ec8a55 100%) !important;
	background: -webkit-linear-gradient(30deg, #ec8a55 0, #fcede2 55%, #fcede2 60%, #ec8a55 100%) !important;
	background: linear-gradient(30deg, #ec8a55 0, #fcede2 55%, #fcede2 60%, #ec8a55 100%) !important;
}

.product-details-inner .select-nqw .value-rsp::after {
	background: var(--sec-gray) !important;
	background: -moz-linear-gradient(30deg, var(--sec-gray) 0, var(--sec-white) 55%, var(--sec-white) 60%, var(--sec-gray) 100%) !important;
	background: -webkit-linear-gradient(30deg, var(--sec-gray) 0, var(--sec-white) 55%, var(--sec-white) 60%, var(--sec-gray) 100%) !important;
	background: linear-gradient(30deg, var(--sec-gray) 0, var(--sec-white) 55%, var(--sec-white) 60%, var(--sec-gray) 100%) !important;
}

.product-details-inner .select-nqw .value-owv:hover,
.product-details-inner .select-nqw .value-owv.act-3vm,
.product-details-inner .select-nqw .value-rsp:hover,
.product-details-inner .select-nqw .value-rsp.act-3vm,
.product-details-inner .select-nqw .value-ddf:hover,
.product-details-inner .select-nqw .value-ddf.act-3vm,
.product-details-inner .select-nqw .value-lxa:hover,
.product-details-inner .select-nqw .value-lxa.act-3vm,
.product-details-inner .select-nqw .val-oep:hover,
.product-details-inner .select-nqw .val-oep.act-3vm {
	border: 2px solid var(--theme-yellow);
}

.product-details-des .useful-links {
	display: flex;
}

.product-details-des .useful-links a {
	font-size: 14px;
	font-weight: 500;
	color: var(--black);
	margin-right: 20px;
	text-transform: capitalize;
	text-decoration: none;
}

.product-details-des .useful-links a:hover {
	color: var(--theme-yellow);
}

.product-details-des .useful-links a i {
	font-size: 20px;
	padding-right: 5px;
	vertical-align: middle;
}

.product-details-des .btn-cart2 {
	height: 40px;
	color: var(--white);
	line-height: 40px;
	border-radius: 50px;
	padding: 0 25px !important;
	background-color: var(--theme-yellow);
}

.product-details-des .btn-cart2:hover {
	color: var(--white);
	background-color: var(--black);
}

.product-details-des .diamond-origin {
	display: flex;
	align-items: center;
	width: 100%;
	grid-gap: 0px 5px;
}

.product-details-des .diamond-origin label {
	display: block;
	width: 50%;
	border: 2px solid var(--deactive-border);
	height: 40px;
	border-radius: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: capitalize;
	font-size: 16px;
	cursor: pointer;
}

.product-details-des .diamond-origin label input {
	position: absolute;
	opacity: 0;
}

.product-details-des .diamond-origin label input:checked ~ label {
	border: 2px solid var(--theme-yellow);
}

.product_tabs {
	border: 0px;
	grid-gap: 5px;
}

.product_tabs li {
	box-shadow: inset 3px 0;
	transition: box-shadow 300ms ease-out;
	border-radius: 5px;
	background-color: transparent;
	color: var(--theme-yellow);
}

.product_tabs li:hover {
	box-shadow: inset 140px 0;
}

.product_tabs li .nav-link.active {
	background-color: var(--theme-yellow);
	color: var(--white);
}

.product_tabs li .nav-link {
	padding: 5px 10px !important;
	border: 0px;
	font-size: 14px;
	border-radius: 5px;
	color: var(--black);
	text-transform: capitalize;
}

.product_tabs li:hover .nav-link.active,
.product_tabs li:hover .nav-link {
	color: var(--white);
}

.label_container {
	display: block !important;
	position: relative;
	cursor: pointer;
	font-size: 12px;
	text-align: center;
	max-width: 50px;
	height: auto;
	margin: auto;
	color: var(--theme-gray-color);
}

.label_container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 50px;
	width: 50px;
	top: 0px;
	left: 0px;
}

.label_container .disable {
	overflow: hidden;
	border-radius: 100%;
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: initial;
}

.label_container .checkmark {
	display: block;
	height: 50px;
	width: 50px;
}

.label_container span.disable {
	border: 1px solid var(--sec-gray);
	display: flex;
	align-items: center;
	justify-content: center;
}

.label_container span.disable img,
.label_container span.active img {
	width: 40px !important;
	height: 40px !important;
	margin: 0 auto;
	border-radius: 50%;
}

.gamestone-label--container .checkmarks {
	display: flex;
	align-items: center;
	justify-content: center;
}

.gamestone-label--container img {
	width: 40px !important;
	height: 40px !important;
	border-radius: 0px !important;
}

.label_container .checkmark img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 100%;
}

.label_container input:checked ~ .checkmark.active {
	border: 1px solid var(--theme-yellow);
	border-radius: 100%;
}

.label_container .checkmark::after {
	content: "";
	position: absolute;
	display: none;
}

.label_container input:checked ~ .checkmark:after {
	display: block;
}

.label_container input:checked ~ .label_container {
	color: var(--theme-yellow);
}

.color_radio_mainContainer {
	display: flex !important;
	align-items: center;
	justify-content: center;
	grid-gap: 8px;
}

.color_container {
	position: relative;
	cursor: pointer;
	text-align: center;
}

.color_container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

.color_container span {
	margin: auto;
	height: 25px;
	width: 25px;
	border-radius: 50%;
	border: 2px solid var(--white);
	overflow: hidden;
}

.color_container .platinum {
	background: var(--platinum);
}

.color_container .white-gold {
	background: var(--white-gold);
}

.color_container .yellow-gold {
	background: var(--yellow-gold);
}

.color_container .rose-gold {
	background: var(--rose-gold);
}

.color_container input:checked ~ .checkmark {
	border: 2px solid var(--theme-yellow);
}

.color_container input:checked ~ p {
	color: var(--theme-yellow);
}

.color_container p {
	font-size: 14px;
	line-height: 20px;
	text-transform: capitalize;
}

/* ---- {{ END:: Product Page Css }} ---- */

/* ---- {{ START:: cart page css }} ---- */

.shopping-cart-title h3 {
	font-size: 24px;
	font-weight: 400;
	font-family: var(--font-family);
	line-height: 30px;
	width: fit-content;
	text-transform: capitalize;
}

.shopping-cart-title p {
	font-size: 14px;
	font-weight: 400;
	font-family: var(--font-family);
	text-transform: capitalize;
}

.shopping-cart-title p span {
	font-size: 16px;
}

.shopping-cart-title a {
	color: var(--primary);
}

.shopping-cart-title a i::before {
	line-height: 19px;
}

.shopping-account-content {
	font-size: 14px;
	text-transform: capitalize;
	line-height: 30px;
}

.shopping-account-content button {
	color: var(--theme-yellow);
	text-decoration: underline !important;
	font-family: var(--font-family);
	cursor: pointer;
}

.shopping-cart-table {
	position: relative;
}

.shopping-cart-table::after {
	position: absolute;
	content: "";
	width: 70%;
	height: 1px;
	background: var(--theme-yellow-rgb);
	top: 0px;
	left: 0px;
}

.shopping-cart-table .dot {
	position: absolute;
	display: block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	left: 70%;
	top: -5px;
	background-color: var(--theme-yellow);
}

.shopping-cart-table .dot::after {
	position: absolute;
	content: "";
	width: 7px;
	height: 7px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	background-color: var(--white);
}

.shopping-cart-table .shopping-link {
	text-transform: capitalize;
	font-family: var(--font-family);
	font-weight: 400;
	font-size: 13px;
	max-width: fit-content;
	width: 100%;
	border-radius: 4px;
	margin: auto;
}

.shopping-cart-table .shopping-image a:nth-child(1) {
	max-width: 172px;
	width: 100%;
	height: auto;
	margin: auto;
}

.shopping-cart-table .shopping-content h3 {
	font-size: 16px;
	font-weight: 400;
	font-family: var(--font-family);
	max-width: 300px;
	width: 100%;
}

.shopping-cart-table .shopping-content p {
	font-size: 22px;
}

.shopping-cart-table .shopping-content .shopping-inner-content {
	padding-bottom: 5px;
	border-bottom: 1px solid var(--border-color-disable);
}

.shopping-cart-table .shopping-content .shopping-diamond-inner {
	padding-top: 15px;
}

.shopping-cart-table .shopping-content .shopping-diamond-inner label i {
	color: var(--theme-yellow);
	outline: none !important;
}

.shopping-cart-table .shopping-content .shopping-diamond-inner label i::before {
	line-height: 18px;
}

.shopping-cart-table .shopping-content .shopping-inner-content span,
.shopping-cart-table .shopping-content .shopping-diamond-inner span {
	font-size: 13px;
	margin: 5px 0px;
	text-transform: uppercase;
}

.shopping-cart-table .shopping-image a:nth-child(2) {
	color: var(--primary);
}

.shopping-cart-table .shopping-image a:nth-child(3) {
	color: var(--primary);
}

.shopping-cart-table .shopping-image a:nth-child(2):hover {
	color: var(--theme-yellow);
}

.shopping-cart-table .shopping-image a:nth-child(3):hover {
	color: var(--delete-color);
}

.shopping-cart-table .shopping-image .shopping-link i::before {
	line-height: 17px;
}

.summary-box .order-summary {
	margin-top: 15px;
	width: 100%;
	border: 0.5px solid var(--border-color-disable);
	border-radius: 8px;
	padding: 20px;
}

.need-contact-content {
	font-size: 14px;
	color: var(--primary);
	text-transform: capitalize;
}

.need-contact-content a {
	color: var(--primary);
	text-decoration: underline !important;
}

.summary-box .order-summary .cart-btn-box button {
	height: 36px;
	text-transform: capitalize;
	border-radius: 4px;
}

.summary-box .order-summary .cart-btn-box span {
	text-transform: uppercase;
}

.summary-box .order-summary .cart-btn-box button:nth-child(1) {
	background-color: var(--theme-yellow);
	color: var(--white);
}

.summary-box .order-summary .cart-btn-box button:nth-child(3) {
	background-color: var(--border-color-disable);
}

.summary-box .order-summary h3,
.shipping-info-box h3 {
	font-size: 20px;
	font-weight: 400;
	font-family: var(--font-family);
	text-transform: capitalize;
	padding-bottom: 20px;
	border-bottom: 1px solid var(--border-color-disable);
}

.shipping-info-box {
	padding: 20px;
	margin-top: 20px;
	border: 1px solid var(--border-color-disable);
	border-radius: 8px;
}

.shipping-info-box img {
	width: 30px;
	height: 26px;
}

.shipping-info-box p {
	line-height: 20px;
}

.shipping-info-box p a,
.shipping-info-box p {
	color: var(--primary);
}

.shipping-info-box p a {
	text-decoration: underline !important;
}

.shipping-info-box p a:hover,
.summary-box .need-contact-content a:hover,
.shopping-cart-title a:hover {
	color: var(--theme-yellow);
}

/* ---- {{ END:: cart page css }} ---- */

/* ---- {{ START:: Contact page css }} ---- */

.contact-area {
	margin-top: -4px;
}

.contact-area .contact-info .working-time h6 {
	padding-left: 30px;
	font-weight: bold;
}

.contact-area .contact-info .working-day {
	padding-left: 20px;
}

.contact-area .contact-info p {
	padding-left: 30px;
}

.contact-area .contact-info .working-time p {
	padding-bottom: 0;
}

.contact-area .contact-info .contact-title {
	padding-left: 30px;
	font-weight: bold;
	font-size: 20px;
}

.contact-area .contact-info p {
	padding-left: 30px;
}

.contact-area .contact-info .working-time p {
	color: var(--black);
	padding-right: 10px;
	font-size: 13px;
}

.contact-area .contact-info .working-time p span {
	color: var(--black);
	padding-left: 2px;
	font-size: 13px;
}

.contact-info {
	height: auto;
}

.contact-info p {
	padding-bottom: 30px;
}

.contact-info ul li {
	border-bottom: 1px solid var(--border-bottom);
	padding-bottom: 12px;
	margin-bottom: 12px;
	display: flex;
	align-items: center;
}

.contact-info ul li:last-child {
	border-bottom: none;
}

.contact-info ul li i {
	font-size: 18px;
	padding-right: 10px;
}

.contact-message .contact-input,
.contact-message .contact-textarea {
	width: 100%;
	border: none;
	padding: 10px 10px;
	border-bottom: 3px solid transparent;
	background-color: var(--sec-white);
	margin-bottom: 30px;
	display: block;
}

.contact-message .contact-textarea {
	height: 110px;
	display: block;
}

.contact-title {
	color: var(--black);
	font-size: 22px;
	font-weight: 500;
	line-height: 1;
	padding-bottom: 28px;
	text-transform: capitalize;
}

/* ---- {{ END:: Contact page css }} ---- */

.product-item {
	overflow: hidden;
}

.product-item:hover .product-thumb .sec-img {
	opacity: 1;
	visibility: visible;
}

.product-item .btn {
	font-size: 14px;
	color: var(--black);
	line-height: 1;
}

.product-item .btn-text {
	font-size: 16px;
	position: relative;
	pointer-events: visible;
	text-transform: capitalize;
}

.product-item .btn-text:before {
	bottom: -4px;
	left: 0;
	width: 100%;
	height: 2px;
	content: "";
	position: absolute;
	background-color: var(--black);
}

.product-item .btn-text:hover {
	color: var(--theme-yellow);
}

.product-item .btn-text:hover:before {
	background-color: var(--theme-yellow);
}

.shop-product-wrap.grid-view .product-item {
	display: block;
	-webkit-animation-name: zoomIn;
	animation-name: zoomIn;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	padding: 0;
}

.shop-product-wrap.list-view .product-item {
	display: none;
}

.shop-product-wrap.grid-view .product-item {
	display: block;
	-webkit-animation-name: zoomIn;
	animation-name: zoomIn;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	padding: 0;
}

.product-item .btn-cart {
	padding: 12px 25px !important;
	background-color: var(--white);
	border-radius: 30px;
	text-transform: capitalize;
	-webkit-box-shadow: 0 2px 1px 0.5px rgba(0, 0, 0, 0.05);
	box-shadow: 0 2px 1px 0.5px rgba(0, 0, 0, 0.05);
}

.product-item .btn-cart:hover {
	color: var(--white) !important;
	background-color: var(--theme-yellow) !important;
}

.product-item:hover .cart-hover {
	bottom: 15px;
	opacity: 1;
	visibility: visible;
}

.product-item .cart-hover {
	bottom: 0;
	left: 0;
	right: 0;
	position: absolute;
	text-align: center;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: 0.4s;
	-o-transition: 0.4s;
	transition: 0.4s;
}

/* ---- {{ END:: Single Card css }} ---- */

/* ---- {{ START:: About us css }} ---- */
.about-us .about-us-hero-section {
	width: 100%;
	height: 818px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-image: url("https://css.brilliantearth.com/static/img/channel/our-movement/about/ir316/hero-hd.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.about-us .about-us-hero-section h2 {
	font-size: 60px;
	font-weight: 400;
	color: var(--white);
	letter-spacing: 0.025em;
	font-family: var(--font-family);
}

.about-us .our-mission {
	padding: 129px 0;
	background-color: var(--theme-yellow);
}

.about-us .our-mission .our-mission-inner-content {
	max-width: 800px;
	width: 100%;
	margin: auto;
	text-align: center;
}

.about-us .our-mission .our-mission-inner-content h2 {
	font-size: 30px;
	font-weight: 400;
	font-family: var(--font-family);
	color: var(--white);
}

.about-us .our-mission .our-mission-inner-content p {
	font-size: 14px;
	font-weight: 400;
	color: var(--white);
	font-family: var(--font-family);
	text-transform: capitalize;
}

.about-us .our-mission .our-mission-inner-content a {
	width: 140px;
	padding: 0.55em 1.2em 0.65em;
	border: 1px solid var(--white);
	margin: auto;
	font-size: 14px;
	text-transform: capitalize;
	color: var(--white);
}

.about-us .content-box {
	width: 100%;
	text-align: center;
}

.about-us .content-box span {
	font-size: 16px;
	font-weight: 400;
	color: var(--primary);
	text-transform: uppercase;
	font-family: var(--font-family);
}

.about-us .content-box h3 {
	font-size: 32px;
	font-weight: 400;
	color: var(--primary);
	font-family: var(--font-family);
	text-transform: capitalize;
	margin-bottom: 25px;
	position: relative;
}

.about-us .content-box h3::after {
	position: absolute;
	content: "";
	background: url(./image/bg/about-bg.png);
	background-position: center;
	background-repeat: no-repeat;
	width: 100%;
	height: 20px;
	left: 0px;
	top: 42px;
}

.about-us .content-box p {
	font-size: 14px;
	font-weight: 400;
	color: var(--primary);
	text-transform: capitalize;
	font-family: var(--font-family);
}

.about-us .responsible-box,
.about-us .three-icon-wrapper,
.about-us .explore-wrapper {
	background-color: var(--theme-light-yellow-rgb);
}

.about-us .responsible-box .responsible-inner-box,
.about-us .three-icon-wrapper .three-icon-inner-content {
	width: 1000px;
	max-width: 90%;
	margin: auto;
	padding-top: 100px;
	padding-bottom: 100px;
}

.about-us .responsible-box .responsible-inner-box img,
.about-us .three-icon-wrapper .three-icon-inner-content img {
	max-width: 150px;
	width: 100%;
	height: 150px;
	margin: auto;
	display: block;
}

.about-us .responsible-box .responsible-inner-box span,
.about-us .three-icon-wrapper .three-icon-inner-content span {
	font-size: 16px;
	font-weight: 400;
	color: var(--primary);
	margin-top: 10px;
	text-transform: uppercase;
}

.about-us .responsible-box .responsible-inner-box h3 {
	font-size: 30px;
	font-weight: 400;
	color: var(--primary);
	text-transform: capitalize;
	font-family: var(--font-family);
}

.about-us .responsible-box .responsible-inner-box p {
	font-size: 14px;
	font-weight: 400;
	color: var(--primary);
	text-transform: capitalize;
	font-family: var(--font-family);
}

.about-us .three-icon-wrapper .three-icon-inner-content p {
	font-size: 24px;
	font-weight: 400;
	color: var(--primary);
	text-transform: capitalize;
	font-family: var(--font-family);
}

.about-us .explore-inner-wrapper {
	width: 100%;
	height: auto;
	margin: auto;
	padding: 100px 0px;
}

.about-us .explore-inner-wrapper h3 {
	font-size: 18px;
	font-weight: 400;
	color: var(--primary);
	margin: auto;
	text-transform: capitalize;
	font-family: var(--font-family);
	position: relative;
	text-align: center;
}

.about-us .explore-inner-wrapper h3::after {
	position: absolute;
	content: "";
	width: 30%;
	height: 1px;
	top: 50%;
	right: 13%;
	background-color: var(--black);
}

.about-us .explore-inner-wrapper h3::before {
	position: absolute;
	content: "";
	width: 30%;
	height: 1px;
	background-color: var(--black);
	top: 50%;
	left: 13%;
}

.about-us .explore-inner-wrapper .education--link-box {
	text-transform: capitalize;
	width: 180px !important;
	height: 180px;
	margin: auto;
	flex-direction: column;
	color: var(--black);
	font-size: 18px;
	position: relative;
	--g: 2px;
	--b: 3px;
	--c: var(--primary);
	padding: calc(var(--g) + var(--b));
	--_c: var(--theme-yellow) 0 25%, var(--c) 0 50%;
	--_g1: repeating-linear-gradient(90deg, var(--_c)) repeat-x;
	--_g2: repeating-linear-gradient(180deg, var(--_c)) repeat-y;
	background: var(--_g1) var(--_p, 25%) 0, var(--_g2) 0 var(--_p, 125%), var(--_g1) var(--_p, 125%) 100%, var(--_g2) 100% var(--_p, 25%);
	background-size: 200% var(--b), var(--b) 200%;
	filter: grayscale(50%);
	transition: 0.3s;
}

.about-us .explore-inner-wrapper .education--link-box:hover {
	--_p: 75%;
	filter: grayscale(0%);
}

.about-us .explore-inner-wrapper .education--link-box img {
	width: 40px;
	height: 40px;
	object-fit: cover;
	margin-bottom: 10px;
}

.responsible-box .engagement-inner--img,
.responsible-box .banner-engagement--slider .engagement-inner--img {
	width: 100%;
	height: 450px;
	object-fit: cover;
}

.responsible-box h2 {
	font-size: 30px;
	font-weight: 500;
	color: var(--primary);
}

.about-us .about-us--banner-slider {
	height: 450px;
	position: relative;
}

.about-us .about-us--banner-slider img {
	height: 100%;
	border-radius: 8px;
}

.about-us .about-banner-engagement--slider .slick-dots {
	bottom: -36px;
	width: 50%;
	right: 0;
}

.about-us .about-banner-engagement--slider .hero-slick--slider-dots li {
	width: 30px;
	height: 30px;
	line-height: 30px;
	border-radius: 50%;
	background-color: var(--theme-light-yellow-rgb);
}

.about-us .about-banner-engagement--slider .hero-slick--slider-dots li .dot--box {
	height: 100%;
	font-size: 14px;
}

.about-us .about-banner-engagement--slider .hero-slick--slider-dots li.slick-active {
	background-color: var(--theme-yellow);
	color: var(--white);
}

.about-us .about-banner-engagement--slider .slick-arrow {
	top: auto;
	bottom: -51px;
	z-index: 2;
	width: 30px;
	height: 30px;
	border-radius: 50%;
}

.about-us .about-banner-engagement--slider .slick-prev {
	left: 66%;
	background-color: var(--theme-yellow);
}

.about-us .about-banner-engagement--slider .slick-next {
	right: 16%;
	background-color: var(--theme-yellow);
}

.about-us .about-banner-engagement--slider .slick-next::before,
.about-us .about-banner-engagement--slider .slick-prev::before {
	content: "";
	display: block;
	width: 20px;
	height: 20px;
	margin: auto;
	background: url(./image/arrow-right.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.about-us .about-banner-engagement--slider .slick-prev::before {
	transform: rotate(-180deg);
}

.about-us .about-banner-engagement--slider .slick-prev.slick-disabled,
.about-us .about-banner-engagement--slider .slick-next.slick-disabled {
	background-color: var(--theme-light-yellow-rgb);
}

.about-us .about-us--banner-slider .about-content--card {
	position: absolute;
	z-index: 1;
	top: 50%;
	right: 10%;
	height: 50%;
	width: 450px;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translate(17%, -50%);
	background-color: var(--white);
	border-radius: 30px 8px 30px 8px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/* ---- {{ END:: About us css }} ---- */

/* ---- {{ START:: Privacy Policy css }} ---- */

.sappDiv h1,
.sappDiv h3,
.sappDiv h4 {
	font-weight: 400;
	color: var(--primary);
	text-transform: capitalize;
	line-height: 1.1;
	font-size: 30px;
	margin: 20px 0px 10px 0px;
}

.sappDiv h1 {
	font-size: 36px;
	font-weight: 600;
	margin-bottom: 20px;
}

.sappDiv h3 {
	font-size: 24px;
	color: var(--theme-yellow);
	text-transform: none;
	letter-spacing: 1.5px;
}

.sappDiv h4 {
	font-size: 18px;
	margin: 10px 0px;
	font-weight: normal;
}

.sappDiv h3 span {
	text-transform: none !important;
	font-size: 24px;
	color: var(--theme-yellow);
	letter-spacing: 1.5px;
}

.sappDiv p {
	margin-bottom: 20px;
	font-size: 16px;
	color: var(--secondary);
	letter-spacing: 1px;
	margin-bottom: 10px;
}

.sappDiv p a {
	text-decoration: underline !important;
	text-underline-offset: 4px;
	color: var(--secondary);
	letter-spacing: 1px;
}

.sappDiv p a:hover {
	color: var(--theme-yellow);
	font-weight: 500;
}

.sappDiv ul {
	padding-left: 40px;
	margin-bottom: 10px;
}

.sappDiv ul li {
	list-style: disc;
	font-size: 16px;
	color: var(--secondary);
	letter-spacing: 1px;
	margin-bottom: 8px;
}

.sappDiv ul.pl-4 {
	padding-left: 65px;
}

.sappDiv ul li.list-style-circle {
	list-style: circle;
}

.policy-main {
	margin-bottom: 40px;
}

/* ---- {{ END:: Privacy Policy css }} ---- */

/* ---- {{ START:: Engagement ring css }} ---- */
.engagement-ring-section .slider-caption p {
	text-align: center;
}

.engagement-ring .row-bg {
	background-color: #f9f9f8;
}

.engagement-ring .row-bg .home-cyo-box {
	padding: 20px 8% 15px;
}

.engagement-ring .row-bg .home-cyo-box h2 {
	font-size: 26px;
	color: var(--theme-yellow);
	text-transform: capitalize;
	letter-spacing: 0.025em;
	font-weight: 400;
}

.engagement-ring .row-bg .home-cyo-box p {
	max-width: 400px;
	width: 100%;
	font-size: 16px;
	letter-spacing: 0.025em;
	font-weight: 400;
	margin: auto;
	color: var(--theme-gray-color);
}

.banner-engagement--slider .img-box,
.diamond-page--slider .img-box {
	max-width: 75%;
	width: 100%;
}

.engagement-ring .img-box img,
.diamond-page--slider .img-box img {
	height: 450px;
	object-fit: cover;
}

.engagement-ring .slider-content--text,
.diamond-page--slider .slider-content--text {
	right: 10px;
	top: 30%;
	background-color: var(--white);
	max-width: 400px;
	width: 100%;
	position: absolute;
	height: auto;
	padding: 12px;
	text-align: center;
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}

.engagement-ring .slider-content--text .home-cyo-box h2,
.diamond-page--slider .slider-content--text .home-cyo-box h2 {
	font-size: 26px;
	font-weight: 400;
	color: var(--theme-yellow);
	font-family: var(--font-family);
	line-height: 32px;
}

.engagement-ring .slider-content--text .home-cyo-box p {
	color: var(--theme-gray-color);
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.ready-to-ship--slider {
	margin: 80px 0px;
}

.ready-to-ship {
	width: 100%;
	height: 590px;
	position: relative;
	z-index: 10;
}

.ready-to-ship .ready-to-ship---img {
	height: 100%;
	object-fit: cover;
	width: 75% !important;
}

.ready-to-ship .minimalist-collection-full-tile {
	position: absolute;
	top: 25%;
	left: auto;
	right: 10px;
	font-size: 16px;
	background-color: var(--white);
	padding: 18px;
	border-radius: 10px;
	text-align: center;
	box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}

.ready-to-ship .minimalist-collection-full-tile > div {
	display: inline-block;
	text-align: left;
}

.ready-to-ship .minimalist-collection-full-tile h3 {
	font-size: 24px;
	font-weight: 400;
	text-align: left;
	letter-spacing: 0.025em;
	margin-top: 10px;
	line-height: 1.1;
	text-transform: uppercase;
	margin-bottom: 25px;
	color: var(--theme-yellow);
}

.ready-to-ship .minimalist-collection-full-tile p {
	max-width: 358px;
	color: var(--theme-gray-color);
	margin-bottom: 25px;
	letter-spacing: 0.025em;
	display: inline-block;
	text-align: left;
	font-size: 16px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.engagement--banner .engagement-inner--img,
.diamond-static-img--banner .diamond-static--img {
	height: 580px;
	width: 100%;
	object-fit: cover;
}

.engagement--banner .home-cyo-box h2,
.diamond-static-img--banner .home-cyo-box h2 {
	font-size: 30px;
	font-weight: 400;
	font-family: var(--font-family);
}

.engagement--banner .home-cyo-box,
.diamond-static-img--banner .home-cyo-box {
	text-align: center;
}

/* ---- {{ END:: Engagement ring css }} ---- */

/* ---- {{ START:: Gemstone page css }} ---- */

.gemstones-page .features-tile .thumbnail--features {
	border: 0;
	padding: 0;
}

.gemstones-page .features-tile .thumbnail {
	display: block;
	height: auto;
	max-width: 100%;
	line-height: 1.428571429;
	background-color: var(--white);
	border-radius: 4px;
	transition: all 0.2s ease-in-out;
}

.gemstones-page .features-tile .thumbnail--features .no-space-xs img {
	height: 350px;
	object-fit: cover;
}

.gemstones-page .features-tile .caption h2 {
	font-size: 30px;
	font-weight: 400;
	font-family: var(--font-family);
	color: var(--theme-bg-light);
	margin-top: 15px;
}

.gemstones-page .features-tile .caption p {
	max-width: 400px;
	width: 100%;
	margin-top: 15px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 15px;
	letter-spacing: 0.025em;
	color: var(--theme-bg-color);
}

.gemstones-page .features-tile .shop-by-color header h2,
.shop-by-style .shop-by-color header h2 {
	font-size: 30px;
	font-family: var(--font-family);
	font-weight: 400;
	margin: 10px 0px;
	text-transform: capitalize;
}

.gemstones-page .features-tile .shopByColorSlider .caption-rzs,
.shop-by-style .shop-by-color .caption-rzs {
	padding: 9px;
	color: #333;
	padding-left: 0;
	padding-right: 0;
	padding-top: 15px;
}

.gemstones-page .features-tile .shopByColorSlider .caption-rzs h3,
.shop-by-style .shop-by-color .caption-rzs h3 {
	font-weight: 500;
	font-size: 17px;
	line-height: 1.1;
	margin-bottom: 10px;
}

.gemstones-page .features-tile .shopByColorSlider .thumbnail-bpv,
.shop-by-style .shop-by-color .shopByColorSlider .thumbnail-bpv {
	display: inline-block;
	display: block;
	height: auto;
	max-width: 100%;
	line-height: 1.428571429;
	background-color: var(--white);
	border: 1px solid var(--disabled);
	border-radius: 4px;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	padding: 0;
	padding-left: 2%;
	padding-right: 2%;
	border: 0;
}

.gemstones-page .features-tile .shop-by-color header,
.shop-by-style .shop-by-color header {
	display: flex;
	margin-bottom: 10px;
	align-items: center;
}

.gemstones-page .features-tile .shop-by-color header button,
.shop-by-style .shop-by-color header button {
	margin-left: auto;
	margin-right: 95px;
	font-size: 17px;
	letter-spacing: 0.05em;
	text-transform: capitalize;
	color: var(--theme-yellow);
	text-decoration: underline;
}

.gemstones-page .mission-tile-container {
	background-color: var(--theme-yellow);
	padding: 50px 0px;
	margin: 30px 0px;
}

.gemstones-page .mission-tile-container .heading h2 {
	font-size: 30px;
	font-weight: 500;
	text-transform: uppercase;
	font-family: var(--font-family);
	color: var(--white);
	margin-bottom: 10px;
}

.gemstones-page .mission-tile-container .mission-tile-content {
	max-width: 745px;
	width: 100%;
	margin: auto;
}

.gemstones-page .mission-tile-container .mission-tile-content .mission-tile-text {
	max-width: 440px;
	width: 100%;
	padding-right: 15px;
	border-right: 1px solid var(--white);
}

.gemstones-page .mission-tile-container .mission-tile-content .mission-tile-text p {
	text-align: right;
	color: var(--white);
}

.gemstones-page .mission-tile-container .mission-tile-content .mission-tile-list {
	padding-left: 15px;
}

.gemstones-page .mission-tile-container .mission-tile-content .mission-tile-list li a {
	position: relative;
	transition: all 0.3s ease-in-out;
}

.gemstones-page .mission-tile-container .mission-tile-content .mission-tile-list li a h2 {
	font-size: 12px;
	font-weight: 400;
	text-transform: uppercase;
	color: var(--white);
	margin-left: 10px;
}

.gemstones-page .mission-tile-container .mission-tile-content .mission-tile-list li {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.gemstones-page .mission-tile-container .mission-tile-content .mission-tile-list li img {
	width: 28px;
	height: 28px;
	object-fit: cover;
}

.gemstones-page .mission-tile-container .mission-tile-content .mission-tile-list li:hover a::after {
	content: "";
	position: absolute;
	top: 50%;
	transform: translate(-50%, 0, 0, 0);
	right: -30px;
	width: 25px;
	height: 2px;
	background-color: var(--white);
	border-radius: 8px;
}

.gemstones-page .shop-by-style {
	padding-bottom: 50px;
}

.gemstones-page .featured-collection-box img {
	height: 500px;
	width: 100%;
}

.gemstones-page .featured-collection-box .btn--custom {
	position: absolute;
	bottom: 10%;
	left: 50%;
	transform: translateX(-50%);
	padding: 0.55em 1.2em 0.65em !important;
	letter-spacing: 0.05em;
	border: 1px solid var(--white);
	color: var(--white);
	border-radius: 0px;
	max-width: 260px;
	width: 100%;
	font-size: 15px;
	font-family: var(--font-family);
}

.gemstones-page .featured-collection-box .btn--custom:hover {
	background-color: var(--white);
	color: var(--primary);
}

.gemstones-page .gemstone-review-box h2,
.gemstones-page .gemstone-education-box h2 {
	margin: 20px 0px;
	font-family: var(--font-family);
	font-weight: 400;
	font-size: 30px;
	color: var(--primary);
}

.gemstones-page .gemstone-review-box .gemstone-review-card img {
	margin-bottom: 15px;
}

.gemstones-page .gemstone-review-box .gemstone-review-card p {
	font-size: 14px;
	font-family: var(--font-family);
	font-weight: 400;
	letter-spacing: 0.025em;
	color: var(--primary);
	line-height: 1.428571429;
}

.gemstones-page .gemstone-review-box .gemstone-review-card cite {
	font-style: normal;
}

.gemstones-page .gemstone-review-box .gemstone-review-card cite a {
	text-decoration: underline !important;
	color: var(--primary);
}

.gemstones-page .gemstone-review-box .gemstone-review-card cite a:hover {
	color: var(--theme-yellow);
}

.gemstones-page .gemstone-education-box {
	margin-top: 60px;
}

.gemstones-page .our-commitment-tile {
	background-color: var(--theme-yellow);
	margin-top: 30px;
}

.our-commitment-tile .showroom-text {
	padding: 40px 1em;
}

.our-commitment-tile .showroom-text .heading h2 {
	margin-bottom: 0.65em;
	font-size: 30px;
	color: var(--white);
	font-weight: 400;
	text-transform: capitalize;
	letter-spacing: 0.025em;
	font-family: var(--font-family);
}

.our-commitment-tile .us-apart-lists {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.our-commitment-tile .us-apart-lists li {
	padding: 0 10px;
	white-space: nowrap;
	overflow: hidden;
}

.our-commitment-tile .us-apart-lists li a {
	text-align: center;
	display: inline-block;
	width: 100%;
	position: relative;
}

.our-commitment-tile .us-apart-lists li a img {
	width: 50px;
	height: 50px;
}

.our-commitment-tile .us-apart-lists li a span {
	display: block;
	font-size: 16px;
	font-weight: 400;
	color: var(--white);
	font-family: var(--font-family);
	white-space: break-spaces;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	height: 80px;
	line-height: 26px;
}

.our-commitment-tile .virtual-bg-img {
	object-fit: cover;
	height: 400px;
	width: 100%;
}

.dimensions_div {
	display: grid;
	grid-template-columns: repeat(5, minmax(0, 1fr));
	grid-gap: 8px;
}

.dimensions_div .stone_container {
	padding: 5px 15px;
}

.dimensions_div .dimensions-inner {
	overflow: hidden;
	border: 0.5px solid var(--theme-gray-color);
	border-radius: 4px;
}

.dimensions_div .dimensions-inner.active {
	border: 0.5px solid var(--theme-yellow);
}

.dimensions_div .dimensions-inner.active .stone_container {
	background-color: var(--theme-light-yellow-rgb);
	text-transform: capitalize;
}

.dimensions_div .dimensions-inner .stone_container {
	position: relative;
	cursor: pointer;
	z-index: 11;
	width: 100%;
	font-size: 12px;
	text-align: center;
}

.dimensions_div .dimensions-inner .stone_container input {
	position: absolute;
	cursor: pointer;
	opacity: 0;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: -1;
}

.color-slider .slick-next {
	right: 0px;
}

.color-slider .slick-prev {
	left: auto;
	right: 10%;
	z-index: 1;
}

.color-slider .slick-prev,
.color-slider .slick-next {
	top: -12px;
}

.color-slider .slick-prev::before {
	transform: rotate(180deg);
}

.color-slider .slick-prev::before,
.color-slider .slick-next::before {
	position: absolute;
	content: "";
	width: 20px;
	height: 20px;
	top: 0px;
	left: 0px;
	background-image: url(./image/arrow-right.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

/* ---- {{ END:: Gemstone page css }} ---- */

/* ---- {{ START:: Product Page CSS }} ---- */

.metal-box .matel-container {
	flex-direction: column;
	cursor: pointer;
	margin: 0px 10px;
	color: var(--theme-gray-color);
}

.metal-box .matel-container input {
	position: absolute;
	opacity: 0;
}

.metal-box .matel-container .checkmark {
	position: relative;
	display: block;
	margin-bottom: 10px;
	width: 22px;
	height: 22px;
	border-radius: 100%;
	border: 1px solid #fff;
}

.metal-box .matel-container .checkmark.Silver {
	background: var(--white-gold);
}

.metal-box .matel-container .checkmark.Gold {
	background: var(--yellow-gold);
}

.metal-box .matel-container .checkmark.rose {
	background: var(--rose-gold);
}

.metal-box .matel-container .checkmark.Platinum {
	background: var(--platinum);
}

.metal-box .matel-container input:checked ~ .checkmark {
	border: 2px solid var(--theme-yellow);
}

.custom-control .img-box {
	width: 52px;
	height: 52px;
	border-radius: 100%;
	border: 1px solid var(--disabled);
	overflow: hidden;
	display: flex;
	align-items: center;
	margin: 0 auto;
}

.custom-control .img-box img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.custom-control .img-box:hover img {
	transform: scale(1.3);
}

.custom-control:hover span {
	color: var(--theme-yellow);
}

.custom-control span {
	color: var(--black);
	transition: all 0.3s ease-in-out;
	font-family: var(--font-family);
}

.engraving-box {
	position: relative;
	width: fit-content;
	cursor: pointer;
}

.engraving-box input {
	position: absolute;
	opacity: 0;
}

.tab-content-heading h3 {
	font-size: 16px;
	font-weight: 400;
	text-transform: capitalize;
	width: fit-content;
	margin-bottom: 8px !important;
	padding-bottom: 8px;
	font-family: var(--font-family);
	position: relative;
}

.tab-content-heading h3::after {
	content: "";
	position: absolute;
	width: 50%;
	height: 2px;
	border-radius: 50px;
	background-color: var(--theme-yellow);
	left: 0px;
	bottom: 0px;
}

.engraving-box span {
	position: relative;
	display: block;
	padding: 5px 15px 5px 30px;
	font-family: var(--font-family);
	font-size: 12px;
	border-radius: 100px;
	overflow: hidden;
	border: 1px solid var(--theme-yellow);
	color: var(--theme-yellow);
}

.engraving-box input:checked ~ span::after {
	position: absolute;
	content: "";
	height: 16px;
	width: 16px;
	left: 16px;
	top: 49%;
	transform: translate(-50%, -50%);
	background-image: url(./image/check.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.engraving-box input:checked ~ .active {
	background-color: var(--theme-yellow);
	color: var(--white);
}

.custom--padding-box {
	padding: 80px 0px 0px 0px;
}

.product-card-container {
	position: sticky;
	top: 154px;
}

.product-card-container .main-heading {
	color: var(--primary);
	font-size: 20px;
	margin-bottom: 10px;
	letter-spacing: 0.025em;
	font-weight: 400;
	font-family: var(--font-family);
}

.product-card-container .diamond-heading h3 {
	font-size: 16px;
	font-weight: 400;
	font-family: var(--font-family);
	color: var(--primary);
	margin-bottom: 10px;
	text-transform: capitalize;
}

.product-card-container .diamond-heading img {
	width: 40px;
	height: 40px;
}

.product-card-container .description {
	font-size: 14px;
	font-weight: 400;
	font-family: var(--font-family);
	letter-spacing: 0.05em;
	margin: 5px 0px 20px 0px;
	position: relative;
}

.product-card-container .description .read-more {
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: 1;
	background: white;
	text-decoration: underline;
	padding-left: 5px;
	cursor: pointer;
}

.product-card-container .total-carat-weight .carat-container {
	width: 36px;
	height: 36px;
	min-width: auto;
	position: relative;
	border-radius: 100%;
	background: var(--white);
	overflow: hidden;
	padding: 0;
	text-align: center;
	line-height: 34px;
	letter-spacing: 0.05em;
}

.product-card-container .total-carat-weight .carat-container input {
	position: absolute;
	opacity: 0;
}

.product-card-container .total-carat-weight {
	margin-bottom: 15px;
}

.product-card-container .total-carat-weight .carat-container label {
	width: 36px;
	height: 36px;
	min-width: auto;
	line-height: 34px;
	border: 2px solid var(--border-color-disable);
	border-radius: 100%;
	cursor: pointer;
	font-size: 12px;
}

.product-card-container .total-carat-weight .carat-container input:checked ~ label,
.product-card-container .diamond-origin-container .diamond-origin-content input:checked ~ label,
.product-card-container .metal-purity-container .diamond-origin-content input:checked ~ label {
	border: 2px solid var(--theme-yellow);
	color: var(--theme-yellow);
}

.product-card-container .metal-container,
.product-card-container .diamond-origin-container,
.product-card-container .metal-purity-container,
.metal-color-container {
	margin-bottom: 10px;
}

.product-card-container .total-carat-weight .carat-heading,
.metal-container .metal-heading,
.diamond-origin-container .diamond-heading,
.metal-purity-container .diamond-heading,
.price-box h1,
.metal-color-container .metal-heading {
	font-size: 16px;
	font-weight: 400;
	font-family: var(--font-family);
	color: var(--primary);
	margin-bottom: 10px;
	text-transform: capitalize;
}

.metal-container .metal-heading span,
.diamond-origin-container .diamond-heading span {
	font-size: 14px;
	color: #333333;
	opacity: 0.5;
}

.metal-container .metal-heading p {
	font-size: 14px;
	font-weight: 400;
	color: var(--primary) !important;
	opacity: 0.5;
}

.metal-heading p,
.diamond-heading p {
	font-size: 14px;
	font-weight: 400;
	color: var(--primary) !important;
	opacity: 0.5;
}

.price-box h1 span {
	font-size: 22px;
	line-height: 22px;
}

.metal-container .metal-content,
.metal-color-container .metal-content {
	grid-gap: 0px 5px;
}

.metal-container .metal-content span,
.metal-color-container .metal-content span {
	width: 34px;
	height: 34px;
	border-radius: 100%;
	overflow: hidden;
	padding: 2px;
	cursor: pointer;
	position: relative;
}

.metal-container .metal-content span.active,
.metal-color-container .metal-content span.active {
	border: 2px solid var(--theme-yellow);
}

.metal-container .metal-content span input,
.metal-color-container .metal-content span input {
	position: absolute;
	opacity: 0;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.metal-container .metal-content span::after,
.metal-color-container .metal-content span::after {
	position: absolute;
	content: "";
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 26px;
	height: 26px;
	border-radius: 100%;
}

.metal-container .metal-content .Platinum::after,
.metal-color-container .metal-content .White::after {
	background: var(--white-gold);
}

.metal-container .metal-content .Gold::after,
.metal-color-container .metal-content .Yellow::after {
	background: var(--yellow-gold);
}

.metal-container .metal-content .Silver::after {
	background: var(--white-gold);
}

.metal-color-container .metal-content .Rose::after {
	background: var(--rose-gold);
}

.product-card-container .diamond-origin-container .diamond-origin-content,
.product-card-container .metal-purity-container .diamond-origin-content {
	position: relative;
}

.product-card-container .diamond-origin-container .diamond-origin-content input,
.product-card-container .metal-purity-container .diamond-origin-content input {
	position: absolute;
	opacity: 0;
}

.product-card-container .diamond-origin-container .diamond-origin-content label,
.product-card-container .metal-purity-container .diamond-origin-content label {
	width: fit-content;
	padding: 5px 9px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 18px;
	border: 2px solid var(--border-color-disable);
	cursor: pointer;
}

.engraving-container button {
	max-width: fit-content;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 5px 10px !important;
	border-radius: 100px;
	outline: none !important;
	text-transform: capitalize;
	position: relative;
	z-index: 12;
}

.engraving-container button::before {
	position: absolute;
	content: "";
	width: 35px;
	height: 100%;
	transition: all 0.3s ease;
	z-index: -1;
	background: var(--theme-light-yellow-rgb);
	left: 0px;
	top: 0px;
	border-radius: 100px;
}

.engraving-container button span,
.card-button button span,
.shipping-and-delivery .free-shipping-text p,
.shipping-and-delivery .delivery-text p {
	font-family: var(--font-family);
	margin-left: 3px;
}

.engraving-container button:hover {
	color: var(--white);
}

.engraving-container button:hover::before {
	width: 100%;
	background: var(--theme-yellow);
	border-radius: 100px;
}

.engraving-container button i {
	outline: none !important;
	font-size: 16px;
}

.engraving-container button i::before {
	line-height: 19px;
}

.card-button button i {
	display: block;
	line-height: 3px;
}

.card-button .gemstone_button {
	min-width: 25%;
	width: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px 20px !important;
	border-radius: 8px;
	outline: none !important;
	text-transform: capitalize;
	position: relative;
	z-index: 12;
	background: var(--theme-yellow);
	color: var(--white);
	height: 34px;
}

.card-button .gemstone_button .spinner-border {
	height: 14px;
	width: 14px;
	margin-left: 8px;
}

.card-button .gemstone_button i {
	display: block;
	line-height: 3px;
}

.whistlist-btn .whistlist {
	min-width: 40px;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	border: 1px solid var(--theme-yellow);
	background: transparent;
	color: var(--theme-yellow);
	padding: 0px !important;
	justify-content: center;
	display: flex;
	align-items: center;
}

.whistlist-btn .whistlist .spinner-border {
	width: 18px;
	height: 18px;
}

.engrave .heading {
	font-size: 30px;
	font-weight: 400;
	text-transform: capitalize;
	font-family: var(--font-family);
}

.engrave .input-box {
	position: relative;
	width: 280px;
	margin: 0 auto;
}

.engrave .input-box span {
	position: absolute;
	top: 0px;
	right: 0.3em;
}

.engrave .input-box .engrave-input {
	padding: 12px;
	width: 100%;
	position: relative;
}

.engrave .engrave-btn button {
	padding: 9px 30px !important;
	border: 1px solid var(--border-color-disable);
	font-size: 15px;
	text-transform: capitalize;
	font-family: var(--font-family);
}

.engrave .engrave-btn button.active {
	border: 1px solid var(--theme-yellow);
	color: var(--theme-yellow);
}

.engrave .submit-box button {
	padding: 0.6em 1.3em 0.6em !important;
	letter-spacing: 0.05em;
	font-size: 13px;
	font-family: var(--font-family);
	font-weight: 400;
	margin-bottom: 15px !important;
	display: block;
	margin: auto;
	background: var(--theme-yellow);
	color: var(--white);
	height: 37px;
	width: 250px;
}

.engrave .submit-box .spinner-border {
	font-size: 13px;
	height: 20px;
	width: 20px;
}

.engrave .submit-box button:last-child {
	margin-bottom: 0px !important;
}

.engrave .submit-box a,
.engrave .description a {
	font-size: 14px;
	font-weight: 400;
	font-family: var(--font-family);
	color: var(--black);
	text-decoration: underline !important;
}

.engrave .submit-box a:hover,
.engrave .description a:hover {
	color: var(--theme-yellow);
}

.engrave .description {
	font-size: 14px;
	font-weight: 400;
	font-family: var(--font-family);
	color: var(--black);
}

.engrave .engrave-left-wrapper .engraved-wrapper {
	height: 290px;
	width: 290px;
	margin: 0 auto;
}

.engrave .engrave-left-wrapper .engraved-img {
	height: 100%;
	width: 100%;
}

.engrave .engraved-content {
	position: absolute;
	top: 0px;
	max-width: 290px;
	width: 100%;
	height: 290px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.engrave .engraved-content .engraved-con {
	position: relative;
	top: 16%;
	left: 0%;
	transform: rotate(0deg);
}

.engrave .engraved-content .engraved-con p {
	font-size: 10.991px;
	color: var(--primary);
	opacity: 0.4;
}

.shipping-and-delivery {
	border-bottom: 1px solid var(--border-color-disable);
}

.shipping-and-delivery .free-shipping-text {
	margin-bottom: 15px;
}

.shipping-and-delivery .free-shipping-text p,
.shipping-and-delivery .delivery-text p {
	font-size: 14px;
	text-transform: capitalize;
}

.shipping-and-delivery .free-shipping-text img,
.shipping-and-delivery .delivery-text img {
	max-width: 22px;
	width: 100%;
	margin-right: 10px;
}

.shipping-and-delivery .delivery-text p span {
	color: var(--theme-yellow);
}

.product-accordion .accordion .accordion-item .accordion-header .accordion-button::before {
	width: 100%;

	background-color: var(--border-color-disable);
}

.product-accordion .accordion .accordion-item .accordion-header .accordion-button {
	border-top: 0px;
	text-transform: capitalize;
}

.product-accordion h3 {
	font-size: 14px;
	font-weight: 400;
	text-transform: uppercase;
	font-family: var(--font-family);
	color: var(--primary);
	letter-spacing: 0.1em;
	margin-top: 20px;
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid var(--border-color-disable);
}

.product-accordion .product-acc-text-content p {
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	font-family: var(--font-family);
	margin-bottom: 10px;
	margin-top: 10px;
	display: flex;
	align-items: center;
	text-transform: capitalize;
}

.product-accordion .product-acc-text-content p span {
	font-size: 12px;
	font-weight: 400;
	text-transform: uppercase;
	font-family: var(--font-family);
}

.product-accordion .accordion-body {
	padding: 12px;
}

.product-accordion .product-acc-sub-title {
	margin: 10px 0px;
	font-size: 12px;
	font-weight: 400;
	font-family: var(--font-family);
	text-transform: capitalize;
}

.product-accordion h3:first-child {
	margin-top: 10px;
}

.product-card-container .multiproduct-details-card .multi-img {
	width: 40px;
	height: 100%;
	object-fit: cover;
}

.product-card-container .multiproduct-details-card .multiproduct-content p {
	font-size: 14px;
	font-weight: 400;
	text-transform: capitalize;
	color: var(--primary);
	font-family: var(--font-family);
	margin: 0px;
}

.product-card-container .multiproduct-details-card .multiproduct-content .multi-ring-price {
	font-size: 16px;
	font-family: var(--font-family);
	font-weight: 400;
	text-transform: capitalize;
	color: var(--primary);
}

.product-accordion .no-details {
	background-color: var(--theme-light-yellow-rgb);
	border-radius: 4px;
	font-size: 14px;
	font-family: var(--font-family);
	font-weight: 400;
}

.center--diamond-wrapper .center--diamond-inner .sub--title {
	text-transform: capitalize;
	font-size: 14px;
	font-weight: 400;
	font-family: var(--font-family);
}

.center--diamond-wrapper .center--diamond-grid {
	display: grid;
	grid-template-columns: repeat(6, minmax(0, 1fr));
	grid-gap: 8px;
	margin-top: 10px;
}

.center--diamond-wrapper .center--diamond-inner .center--diamond-img img {
	width: 34px;
	height: 34px;
	display: block;
	margin: 0 auto;
}

.center--diamond-wrapper .center--diamond-inner .center--diamond-img .diamond--title {
	font-size: 12px;
	text-transform: capitalize;
	text-align: center;
}

/* ---- {{ END:: Product Page CSS }} ---- */

/* ---- {{ START:: Page 404 CSS }} ---- */

.section-container {
	max-width: 1425px;
	width: 100%;
	margin: auto;
}

.section-container.p-l-r {
	padding: 0px 7.5px;
}

.section-container .page-404 {
	text-align: center;
	position: relative;
}

.section-container .page-404 .title-error {
	font-size: 2.916666667in;
	line-height: 1;
	color: var(--primary);
}

.content-page-404 .subtitle {
	position: relative;
	margin-bottom: 25px;
	padding-bottom: 7.5pt;
	font-size: 0.416666667in;
	color: var(--primary);
}

.content-page-404 .subtitle::before {
	position: absolute;
	content: "";
	height: 0.020833333in;
	width: 140px;
	left: calc(50% - 70px);
	background: #e1e1e1;
	bottom: 0px;
}

.content-page-404 .sub-error {
	font-size: 16px;
	margin: 0 auto;
	max-width: 243.75pt;
	width: 100%;
}

.content-page-404 .button {
	display: inline-flex;
	align-items: center;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	position: relative;
	border-radius: 0px;
	background: var(--primary);
	color: var(--white);
	font-size: 10.5pt;
	line-height: 0.520833333in;
	height: 0.520833333in;
	padding: 0px 0.416666667in !important;
	margin-top: 0.3125in;
}

.content-page-404 .button:hover {
	background: var(--theme-yellow);
	color: var(--white);
}

.content-page-404 .button .gg-arrow-long-right {
	box-sizing: border-box;
	position: relative;
	display: block;
	transform: scale(var(--ggs, 1));
	border-top: 2px solid transparent;
	border-bottom: 2px solid transparent;
	box-shadow: inset 0 0 0 2px;
	width: 24px;
	height: 6px;
	margin-left: 5px;
}

.content-page-404 .button .gg-arrow-long-right::after {
	content: "";
	display: block;
	box-sizing: border-box;
	position: absolute;
	width: 6px;
	height: 6px;
	border-top: 2px solid;
	border-right: 2px solid;
	transform: rotate(45deg);
	right: 0;
	bottom: -2px;
}

/* ---- {{ END:: Page 404 CSS }} ---- */

.checkmarks {
	border: 2px solid var(--theme-yellow);
	display: block;
	border-radius: 50%;
	height: 50px;
	width: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.checkmarks img {
	height: 40px;
	width: 40px;
	border-radius: 50%;
}

.rc-slider-handle.rc-slider-handle {
	background: var(--theme-yellow) !important;
	cursor: pointer;
	border: none;
	height: 15px !important;
	margin-top: -5.5px;
	opacity: 1;
	position: absolute;
	width: 15px !important;
}

.bzrPMW {
	white-space: nowrap;
	flex: 0 0 48px;
	background-color: #e5e5e5;
}

.panel-kqf {
	flex: 1 1 0;
	letter-spacing: 0.025em;
}

.info-4yj {
	font-size: 14px;
}

.igfna {
	margin-bottom: 0 !important;
}

.hea-1zb {
	font-size: 19px;
}

.sPapY:hover:not(:disabled) {
	cursor: pointer;
	background-color: transparent !important;
}

.sPapY:focus {
	outline: none;
	background-color: transparent !important;
}

.tab-content {
	transition: 0.9s ease-in-out;
}

.tt-kpr {
	text-transform: none !important;
}

.rc-slider-track {
	position: absolute;
	height: 4px;
	background-color: var(--theme-yellow) !important;
	border-radius: 6px;
}

.rc-slider-dot-active {
	border-color: var(--border-color-active) !important;
}

.tab-pane.active {
	display: block;
	animation: fadeIn 0.5s ease;
}

.rageslider-input input {
	width: 30%;
	padding: 4px 15px;
	border-radius: 100px;
	outline: none;
	border: 1px solid var(--disabled);
}

.custom-control-label .heart-icon {
	cursor: pointer;
}

.validation-error {
	position: absolute;
	text-align: left;
	color: red;
	top: 48px;
	font-size: 12px;
	margin: 0px !important;
}

.validation-errors {
	position: absolute;
	text-align: left;
	color: red;
	top: 111px;
	font-size: 12px;
	margin: 0px !important;
}

.select-validation-error i {
	text-align: left;
	color: red;
	font-size: 12px !important;
	margin-right: 6px;
	display: block;
}

.select-validation-error i::before {
	line-height: 15px;
}

.select-validation-error {
	text-align: left;
	color: red;
	font-size: 12px !important;
	margin-right: 6px;
	display: flex;
	align-items: center;
}

.cSyyNR {
	white-space: nowrap;
	flex: 0 0 48px;
	background-color: rgb(229, 229, 229);
}

.break {
	display: none !important;
}

.empty-page-content {
	padding: 35px 0 55px;
}

.empty-page-content .empty-cart-title {
	margin-bottom: 10px;
	color: #666666;
	font-size: 1.5rem;
}

.empty-page-content .button {
	display: inline-flex;
	align-items: center;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	position: relative;
	border-radius: 0px;
	background: var(--primary);
	color: var(--white);
	font-size: 10.5pt;
	line-height: 0.520833333in;
	height: 0.520833333in;
	padding: 0px 0.416666667in !important;
	margin-top: 0.3125in;
}

.empty-page-content .button .gg-arrow-long-right {
	box-sizing: border-box;
	position: relative;
	display: block;
	transform: scale(var(--ggs, 1));
	border-top: 2px solid transparent;
	border-bottom: 2px solid transparent;
	box-shadow: inset 0 0 0 2px;
	width: 24px;
	height: 6px;
	margin-left: 5px;
}

.empty-page-content .button .gg-arrow-long-right::after {
	content: "";
	display: block;
	box-sizing: border-box;
	position: absolute;
	width: 6px;
	height: 6px;
	border-top: 2px solid;
	border-right: 2px solid;
	transform: rotate(45deg);
	right: 0;
	bottom: -2px;
}

.empty-page-content .button:hover {
	background: var(--theme-yellow);
	color: var(--white);
}

.bymAUH:not(:last-of-type) {
	border-bottom: 1px solid #ced4da !important;
}

.brFloq {
	font-size: 15px !important;
	color: var(--primary);
	font-weight: 500;
}

.jDsDCL,
.jeiDIp {
	display: none !important;
	width: 100px !important;
}

.cSyyNR {
	white-space: nowrap;
	flex: 0 0 48px;
	padding-right: 65px !important;
}

.kubBPN:hover:not(:disabled) {
	cursor: pointer;
	background-color: transparent !important;
}

.kubBPN,
.kbXLgC {
	background-color: transparent !important;
}

.tab-content-heading span.disable {
	border: 1px solid #b6b6b6;
}

/*  ===== {{ START:: Table CSS }} =====  */
.diamond {
	overflow: hidden;
}

.diamond .sc-feUZmu {
	padding-right: 5px;
}

.diamond .bvJPA-D,
.diamond .cSyyNR,
.diamond .kNha-dj,
.diamond .dOLeIw {
	background-color: var(--theme-yellow);
}

.diamond .bvJPA-D:nth-child(1) {
	border-radius: 10px 0px 0px 0px;
}

.diamond .cAuvAX:hover {
	outline: var(--border-color-disable);
}

.diamond .bymAUH:hover {
	background-color: var(--theme-light-yellow-rgb);
	outline: var(--border-color-disable);
}

.diamond .gMrbHW:nth-of-type(n) {
	background: var(--theme-yellow-rgb);
	color: var(--primary);
	border-bottom-color: var(--white);
}

.diamond .gMrbHW:nth-of-type(n) .kubBPN svg {
	color: var(--primary);
}

.diamond .brFloq,
.diamond .cRzJdb,
.diamond .emBIby,
.diamond .cTRXdr,
.diamond .cmEdRq {
	color: var(--white);
}

.diamond .sc-fqkvVR:nth-child(4),
.diamond .sc-fqkvVR:nth-child(5) {
	justify-content: end;
}

.diamond .sc-fqkvVR:nth-child(6),
.diamond .sc-fqkvVR:nth-child(7),
.diamond .sc-fqkvVR:nth-child(8) {
	justify-content: center;
}

.diamond .sc-hmdomO {
	background: var(--theme-light-yellow-rgb);
	overflow: hidden;
	border-radius: 10px;
}

.no-record {
	background: var(--theme-light-yellow-rgb);
	color: var(--primary);
	border-radius: 10px;
	font-size: 18px;
	font-weight: 400;
	padding: 20px;
	text-align: center;
	font-family: var(--font-family);
}

.diamond .sc-hmdomO > p {
	font-size: 22px;
	font-weight: 400;
	padding: 20px;
	font-family: var(--font-family);
}

.diamond .hUGali,
.diamond .ixkAGQ {
	background: var(--theme-light-yellow-rgb);
}

.diamond .hUGali:hover,
.diamond .ixkAGQ:hover {
	background-color: var(--theme-yellow-rgb);
	outline: var(--border-color-disable);
	border-bottom-color: var(--border-color-disable);
}

.diamond .additional-card {
	padding: 5px;
	backdrop-filter: blur(10px);
	box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
	border-radius: 8px;
	margin-bottom: 12px;
}

.diamond .additional-card h3 {
	font-size: 14px;
	font-weight: 400;
	font-family: var(--font-family);
	display: flex;
	align-items: center;
	text-transform: capitalize;
}

.diamond .additional-card h3 img {
	width: 20px;
	height: 20px;
	margin-right: 5px;
	object-fit: cover;
}

.diamond .expand-card {
	z-index: 10;
	padding: 10px;
	border-radius: 8px;
	height: 300px;
}

.diamond .expand-card .video-box {
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: -1;
}

.diamond .expand-card video {
	min-width: 100%;
	object-fit: cover;
	object-position: center;
}

.diamond .expand-card .expand-card-content {
	width: 55%;
	padding: 5px 15px;
}

.diamond .gocYik,
.diamond .ilZlKI {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	overflow: hidden;
}

.diamond .expand-title {
	font-size: 28px;
	font-weight: 400;
	font-family: var(--font-family);
	text-transform: capitalize;
}

.diamond .expand-content p {
	font-size: 16px;
	font-weight: normal;
	display: flex;
	align-items: flex-end;
	font-family: var(--font-family);
	position: relative;
}

.diamond .expand-content p::before {
	position: absolute;
	content: "";
	top: auto;
	bottom: -2px;
	left: 0px;
	width: 6px;
	height: 6px;
	background-color: var(--primary);
	border-radius: 50%;
	animation: run 4s linear infinite;
	z-index: 1;
}

.diamond .expand-content p::after {
	position: absolute;
	content: "";
	width: 45%;
	height: 2px;
	top: auto;
	bottom: 0px;
	left: 0px;
	border-radius: 10px;
	background-color: var(--theme-yellow);
}

.diamond .expand-content p span {
	font-size: 20px;
	font-weight: normal;
	font-family: var(--font-family);
	display: block;
	margin-left: 10px;
	line-height: 30px;
}

.diamond .expand-color-content p span {
	font-size: 20px;
	font-weight: normal;
	font-family: var(--font-family);
	line-height: 30px;
}

.diamond .expand-color-content p {
	font-size: 16px;
	font-weight: normal;
	font-family: var(--font-family);
}

.diamond .fwGovb {
	z-index: 111;
}

@keyframes run {
	0% {
		left: 0;
	}

	50% {
		left: 45%;
	}

	100% {
		left: 0;
	}
}

/*  ===== {{ END:: Table CSS }} =====  */

.diamond .dIyxTF input[type="checkbox"] {
	position: relative;
	border: 2px solid var(--primary);
	border-radius: 2px;
	background: none;
	cursor: pointer;
	line-height: 0;
	outline: 0;
	padding: 0 !important;
	vertical-align: text-top;
	height: 15px;
	width: 15px;
	-webkit-appearance: none;
	opacity: 0.5;
}

.diamond .dIyxTF input[type="checkbox"]:hover {
	opacity: 1;
}

.diamond .dIyxTF input[type="checkbox"]:checked {
	background-color: var(--primary);
	opacity: 1;
}

.diamond .dIyxTF input[type="checkbox"]:before {
	content: "";
	position: absolute;
	right: 45%;
	top: 39%;
	width: 4px;
	height: 10px;
	border: solid var(--white);
	border-width: 0 2px 2px 0;
	transform: rotate(45deg) translate(-50%, -50%);
}

.accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
	background-color: transparent;
	font-size: 18px;
}

.accordion .accordion-item .accordion-header .accordion-button {
	position: relative;
	padding: 10px 0px !important;
}

.accordion .accordion-item .accordion-header .accordion-button::before {
	position: absolute;
	content: "";
	width: 80%;
	height: 1px;
	bottom: 0px;
	background: var(--theme-yellow-rgb);
}

.accordion-button {
	font-size: 18px;
	line-height: 25px;
	font-weight: 400;
	font-family: var(--font-family);
}

.accordion-button:focus {
	z-index: 3;
	outline: 0;
	border-bottom: none;
	box-shadow: none;
}

.accordion-header {
	font-family: "Avenir Medium" !important;
}

.accordion {
	--bs-accordion-border-color: 0px;
}

.accordion-item {
	background: transparent;
}

.accordion-item:last-of-type {
	border-radius: 0px;
}

.accordion-item:first-of-type .accordion-button {
	border-radius: 0px;
	box-shadow: none;
	background: transparent;
}

.accordion-item:last-of-type .accordion-button.collapsed {
	border-radius: 0px;
}

.accordion-button:not(.collapsed) {
	color: var(--primary);
}

.remove_icon {
	font-size: 18px;
	color: red;
	opacity: 0.6;
}

.CustomerLoginForm .password_icon {
	position: relative;
}

.CustomerLoginForm .login_password_icon {
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 16px;
	width: 16px;
	color: #000;
}

.reset_password_icon {
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 16px;
	width: 16px;
	color: #000;
}

.change_password_icon {
	position: absolute;
	right: 10px;
	top: 7px;
	font-size: 16px;
	width: 16px;
	color: #000;
}

.CustomerLoginForm .register_password_icon {
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 16px;
	width: 16px;
}

.tab-content-heading .metal-box span.YellowGold {
	background: var(--yellow-linear);
}

.tab-content-heading .metal-box span.RoseGold {
	background: var(--rose-linear);
}

.tab-content-heading .metal-box span.WhiteGold {
	background: var(--white-linear);
}

/* ===== {{ START:: Wedding rings CSS }} ===== */
.wedding-rings .season-label {
	height: 48px;
	line-height: 48px;
}

.wedding-rings .season-label img {
	width: 30px;
	height: 30px;
}

.wedding-rings .season-label p {
	font-size: 13px;
	text-transform: capitalize;
	font-family: var(--font-family);
}

.wedding-rings .major-bar {
	background-color: var(--theme-yellow);
	padding: 35px 15px;
}

.wedding-rings .major-bar a {
	font-size: 16px;
	text-transform: capitalize;
	font-family: var(--font-family);
	color: var(--white);
}

.wedding-rings .menAndWomenRingComponent {
	padding: 80px 0px 0px 0px;
}

.wedding-rings .menAndWomenRingComponent .hero-section,
.gifts-page .shop-by--price .hero-section {
	justify-content: flex-start !important;
}

.wedding-rings .menAndWomenRingComponent .inner-content {
	width: 100%;
	margin: auto;
	height: auto;
}

.wedding-rings .menAndWomenRingComponent .inner-content h2 {
	font-size: 30px;
	font-weight: 400;
	margin: 15px 0px 10px 0px !important;
	text-transform: capitalize;
	font-family: var(--font-family);
	color: var(--black);
}

.wedding-rings .menAndWomenRingComponent .inner-content p {
	margin: 15px auto;
	color: var(--black);
	font-family: var(--font-family);
	text-transform: capitalize;
	font-size: 16px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.wedding-rings .menAndWomenRingComponent .wedding-slider-img--box img {
	height: 350px;
	object-fit: cover;
}

.wedding-rings .wedding-ring--slider .slick-arrow {
	width: 40px;
	height: 40px;
	z-index: 1;
	top: -30px;
	border-radius: 50%;
	background-color: var(--theme-yellow);
}

.wedding-rings .wedding-ring--slider .slick-prev {
	left: auto;
	right: 55px;
}

.wedding-rings .wedding-ring--slider .slick-next {
	right: 0px;
}

.wedding-rings .wedding-ring--slider .slick-prev::before {
	transform: rotate(-180deg);
}

.wedding-rings .wedding-ring--slider .slick-prev::before,
.wedding-rings .wedding-ring--slider .slick-next::before {
	content: "";
	display: block;
	width: 20px;
	height: 20px;
	margin: auto;
	background: url(./image/arrow-right.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.weddingRings-static---banner .wedding-static--img {
	height: 474px;
	width: 100%;
}

/* ===== {{ END:: Wedding rings CSS }} ===== */

/* ===== {{ START:: Jewelry Page CSS }} ===== */

.main-jewelry .heroSection {
	background-image: url("https://image.brilliantearth.com/media/gateway_page/JewelryGW-Sol-HP_Hero_-_DT_11zon.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 589px;
	display: flex !important;
	align-items: center;
	justify-content: flex-end;
}

.main-jewelry .heroSection .inner-content {
	max-width: 1198px;
	width: 100%;
	height: auto;
}

.main-jewelry .heroSection .inner-content .inner-text {
	max-width: 410px;
	width: 100%;
	height: auto;
	margin: auto;
	padding: 0px 30px;
}

.main-jewelry .heroSection .inner-content .inner-text h2 {
	font-size: 42px;
	text-transform: capitalize;
	font-weight: 400;
	letter-spacing: 0.05em;
	font-family: var(--font-family);
	margin-bottom: 10px;
}

.main-jewelry .heroSection .inner-content .inner-text p {
	font-size: 16px;
	text-transform: capitalize;
	font-family: var(--font-family);
	font-weight: 400;
}

.main-jewelry .heroSection .inner-content .inner-text button {
	max-width: 240px;
	width: 100%;
	height: auto;
	margin: 0 auto;
	padding: 9.1px 16.8px !important;
	border: 1px solid var(--primary);
	font-size: 14px;
	text-transform: capitalize;
}

.main-jewelry .jewelry-category h2 {
	font-size: 26px;
	font-family: var(--font-family);
	font-weight: 400;
	line-height: 28px;
	letter-spacing: 0.025em;
	text-transform: capitalize;
}

.main-jewelry .jewelry-category .jewelry-inner-content {
	overflow: hidden;
}

.main-jewelry .jewelry-category .jewelry-inner-content a {
	text-transform: capitalize;
	color: var(--primary);
	padding: 9px 0px;
}

.main-jewelry .jewelry-category .jewelry-inner-content img {
	width: 300px;
	height: 300px;
	margin: auto;
}

.main-jewelry .jewelry-slider .slick-slide {
	padding: 0px 10px;
}

.main-jewelry .jewelry-slider a {
	color: var(--primary);
}

.main-jewelry .jewelry-slider a p {
	font-size: 17px;
	font-weight: 400;
	color: var(--primary);
	line-height: 24px;
	transition: all 0.3s ease-in-out;
}

.main-jewelry .jewelry-slider a:hover p {
	color: var(--theme-yellow);
}

.more-jewelry h2 {
	font-size: 30px;
	font-weight: 400;
	font-family: var(--font-family);
}

.main-jewelry .live-color {
	position: relative;
	width: 100%;
	height: 600px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.main-jewelry .live-color img {
	height: 100%;
	width: 100%;
}

.main-jewelry .live-color a {
	position: absolute;
	left: 65.3%;
	top: 65.5%;
	width: 260px;
	padding: 0.65em 1.2em 0.65em;
	border: 1px solid var(--white);
	text-align: center;
	background-color: transparent;
	color: var(--white);
	text-transform: capitalize;
	letter-spacing: 0.05em;
	transition: all 0.3s ease-in-out;
	transform: translate(-110%, 100%);
}

.main-jewelry .live-color a:hover {
	background-color: var(--white);
	color: var(--primary);
}

/* ===== {{ END:: Jewelry Page CSS }} ===== */

.image-container {
	position: relative;
	display: inline-block;
}

.image-container .slider-img {
	height: 400px;
	margin: auto;
}

img {
	max-width: 100%;
	height: auto;
}

.form_register-destop {
	margin-top: 50px;
	margin-bottom: 50px;
	padding: 15px !important;
}

.form_register-destop .mailrrr .register-input {
	display: block;
	width: 100%;
	padding: 12px 12px;
	font-size: 14px;
	line-height: 1.428571429;
	color: var(--gray);
	border: 1px solid var(--border-color);
	border-radius: 4px;
}

.form_register-destop .registration-name {
	text-align: center;
	font-family: "ZapfHumanist601BT-Roman";
	font-size: 28px;
	color: var(--secondary);
	text-transform: capitalize;
	letter-spacing: 0.5px;
	margin-top: 0;
}

/* ----- {{ START:: Diamond page CSS }} ----- */

.diamond-page .diamond-ring-content h2 {
	font-size: 30px;
	font-weight: 400;
	font-family: var(--font-family);
	text-transform: capitalize;
}

.diamond-page .diamond-ring-content p {
	font-size: 16px;
	font-weight: 400;
	font-family: var(--font-family);
	margin: 15px 0px;
	text-transform: capitalize;
}

.diamond-page .diamond-ring-content a {
	text-decoration: underline !important;
	font-size: 14px;
	text-transform: capitalize;
	font-weight: 400;
	font-family: var(--font-family);
	color: var(--primary);
}

.diamond-page .diamond--img-box img {
	height: 300px;
	object-fit: cover;
}

.diamond-page .diamond-ring-content {
	margin-top: 25px;
}

.diamond-page .collection h2,
.diamond-page .transformational-tech .mini-container h2 {
	font-size: 30px;
	font-weight: 400;
	font-family: var(--font-family);
	text-transform: capitalize;
}

.diamond-page .collection .collection-content a {
	color: var(--primary);
}

.diamond-page .collection .collection-content h3 {
	font-size: 20px;
	font-weight: 400;
	font-family: var(--font-family);
	text-transform: capitalize;
	transition: all 0.3s ease-in-out;
}

.diamond-page .collection .collection-content p {
	font-size: 14px;
	font-weight: 400;
	text-transform: capitalize;
}

.diamond-page .collection .collection-content:hover a h3 {
	color: var(--theme-yellow);
}

.diamond-page .transformational-tech {
	position: relative;
}

.diamond-page .transformational-tech .mini-container {
	max-width: 680px;
	width: 100%;
	height: auto;
	margin: auto;
}

.diamond-page .transformational-tech .mini-container p {
	font-size: 16px;
	font-weight: 400;
	text-transform: capitalize;
	font-family: var(--font-family);
}

.diamond-page .transformational-tech .mini-container a {
	font-size: 17px;
	font-weight: 400;
	color: var(--primary);
	font-family: var(--font-family);
	text-decoration: underline !important;
	text-transform: capitalize;
	transition: all 0.3s ease-in-out;
	letter-spacing: 0.05em;
}

.diamond-page .transformational-tech .mini-container a:hover {
	color: var(--theme-yellow);
}

/* ----- {{ END:: Diamond page CSS }} ----- */

/* ----- {{ START:: Blog page CSS }} ----- */
.blog-page .blog-header img {
	max-width: 220px;
	width: 100%;
	margin: auto;
}

.blog-page .popular-stories {
	font-size: 24px;
	text-align: center;
	text-transform: capitalize;
	font-family: var(--font-family);
	font-weight: 400;
}

.blog-page .popular-box span {
	font-size: 16px;
	line-height: 1.42;
	margin-top: 15px;
	padding-left: 2em;
	padding-right: 2em;
	text-transform: capitalize;
	font-family: var(--font-family);
	display: block;
	text-align: center;
	color: var(--primary);
}

.blog-page .blog-filter-box .blog-checkbox-content {
	position: relative;
	width: 100%;
	cursor: pointer;
	margin: auto;
}

.blog-page .blog-filter-box .blog-checkbox-content input {
	position: absolute;
	opacity: 0;
	top: 0;
	left: 0;
}

.blog-page .blog-filter-box .blog-checkbox-content span {
	text-transform: capitalize;
	font-size: 16px;
	font-weight: 400;
	text-align: end;
	font-family: var(--font-family);
}

.blog-page .blog-filter-box .blog-checkbox-content label i {
	position: absolute;
	left: 10px;
	top: 4px;
	width: 20px;
	height: 20px;
	text-align: center;
	background-image: url("./image/icon-fillter.png");
	background-repeat: no-repeat;
	background-size: 62px auto;
}

.blog-page .blog-filter-box .blog-checkbox-content label i.icon-style {
	background-position: 0 0;
}

.blog-page .blog-filter-box .blog-checkbox-content label i.icon-love {
	background-position: -21px 0;
}

.blog-page .blog-filter-box .blog-checkbox-content label i.news {
	background-position: -42px 0;
}

.blog-page .blog-filter-select .form-group {
	margin-bottom: 0px !important;
}

.blog-page .blog-search-input {
	position: relative;
}

.blog-page .blog-search-input input {
	border: 0px;
	border-bottom: 1px solid var(--theme-yellow);
	height: 34px;
	padding: 6px 25px 6px 12px;
	font-size: 14px;
}

.blog-page .blog-search-input input::placeholder {
	text-transform: capitalize;
}

.blog-page .blog-search-input button {
	position: absolute;
	top: 5px;
	right: 5px;
	font-size: 16px;
}

.blog-page .blog-card-content button i {
	margin-left: 8px;
}

.blog-page .blog-card-content button i::before {
	line-height: 17px;
}

.blog-page .blog-card-content button {
	text-transform: capitalize;
}

.blog-page .blog-categories {
	margin-bottom: 25px;
}

.blog-page .blog-categories h3,
.blog-page .last-post h3 {
	padding-bottom: 12px;
	margin-bottom: 12px;
	border-bottom: 1px solid var(--border-color-disable);
	font-size: 22px;
	font-weight: 400;
	font-family: var(--font-family);
	text-transform: capitalize;
	position: relative;
}

.blog-page .last-post p a {
	color: var(--primary);
}

.blog-page .blog-categories h3::after,
.blog-page .last-post h3::after {
	position: absolute;
	content: "";
	width: 20%;
	height: 3px;
	bottom: -2px;
	left: 0px;
	border-radius: 100px;
	background-color: var(--theme-yellow);
}

.blog-page .blog-categories ul {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	grid-gap: 5px;
}

.blog-page .blog-categories ul li {
	text-transform: capitalize;
}

.blog-page .blog-categories ul li:last-child {
	margin-bottom: 0px;
}

.blog-page .blog-categories ul li a {
	position: relative;
	display: block;
	padding: 5px 15px;
	font-size: 13px;
	text-transform: capitalize;
	background-color: var(--theme-yellow);
	color: var(--white);
	font-family: var(--font-family);
	border-top-right-radius: 10px;
	border-bottom-left-radius: 10px;
	transition: all 1s;
	width: fit-content;
}

.blog-page .blog-categories ul li a::before,
.blog-page .blog-categories ul li a::after {
	content: " ";
	width: 5px;
	height: 5px;
	position: absolute;
	border: 0px solid var(--white);
	transition: all 1s;
}

.blog-page .blog-categories ul li a::before {
	bottom: 1px;
	right: 1px;
	border-bottom: 1px solid var(--primary);
	border-right: 1px solid var(--primary);
}

.blog-page .blog-categories ul li a::after {
	top: 1px;
	left: 1px;
	border-top: 1px solid var(--primary);
	border-left: 1px solid var(--primary);
}

.blog-page .blog-categories ul li a:hover {
	border-top-right-radius: 0px;
	border-bottom-left-radius: 0px;
	background-color: var(--theme-light-yellow-rgb);
	color: var(--primary);
}

.blog-page .blog-categories ul li a:hover::before,
.blog-page .blog-categories ul li a:hover::after {
	width: 100%;
	height: 100%;
}

.blog-page .last-post .blog-img-box {
	padding: 3px;
	position: relative;
	transition: all 1s;
}

.blog-page .last-post .blog-img-box::before {
	position: absolute;
	content: "";
	width: 40%;
	height: 40%;
	bottom: 0px;
	right: 0px;
	border-radius: 0px 0px 4px 0px;
	border-bottom: 1px solid var(--primary);
	border-right: 1px solid var(--primary);
	transition: all 1s;
}

.blog-page .last-post .blog-img-box::after {
	position: absolute;
	content: "";
	width: 40%;
	height: 40%;
	top: 0px;
	left: 0px;
	border-radius: 4px 0px 0px 0px;
	border-left: 1px solid var(--primary);
	border-top: 1px solid var(--primary);
	transition: all 1s;
}

.blog-page .last-post .blog-img-box:hover img,
.blog-page .last-post .blog-img-box:hover::before,
.blog-page .last-post .blog-img-box:hover::after {
	border-radius: 0px;
}

.blog-page .last-post .blog-img-box:hover::before,
.blog-page .last-post .blog-img-box:hover::after {
	width: 100%;
	height: 100%;
}

.blog-page .last-post .blog-img-box img {
	display: block;
	border-radius: 4px;
	height: 100%;
	width: 100%;
}

.blog-page .last-post .latest-blog-content {
	display: flex;
	align-items: center;
}

.blog-page .last-post .latest-blog-content i {
	margin-right: 10px;
}

.blog-page .last-post span,
.blog-page .blog-right-content span {
	font-size: 10px;
	text-transform: capitalize;
	width: fit-content;
	padding: 2px 10px;
	background-color: var(--theme-light-yellow-rgb);
	border-radius: 4px;
}

.blog-page .last-post p {
	font-size: 12px;
	font-weight: 400;
	color: var(--primary);
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-family: var(--font-family);
	transition: all 0.3s ease-in-out;
}

.blog-page .last-post a:hover p {
	color: var(--theme-yellow);
	transform: scale(1.1);
}

.blog-page .last-post .col-xl-4,
.blog-page .last-post .col-xl-8 {
	margin-bottom: 12px;
}

.blog-page .last-post .row .col-xl-4:nth-last-child(2),
.blog-page .last-post .col-xl-8:last-child {
	margin-bottom: 0px;
}

.blog-page .blog-title {
	margin: 15px 0px;
}

.blog-page .blog-title a {
	color: var(--primary);
	font-size: 20px;
	font-weight: 400;
	font-family: var(--font-family);
}

.blog-page .blog-title a:hover {
	color: var(--theme-yellow);
}

.blog-page .blogs-desc p {
	max-width: 350px;
	width: 100%;
	height: 40px;
	line-height: 20px;
	font-family: var(--font-family);
	font-size: 14px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: initial;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	margin-bottom: 15px;
}

.blog-page .read-more a {
	display: block;
	max-width: 150px;
	width: 100%;
	font-size: 12px;
	font-weight: 400;
	padding: 6px 15px !important;
	background-color: var(--primary);
	border-radius: 0px;
	color: var(--white);
	font-family: var(--font-family);
}

.blog-page .read-more a:hover {
	background-color: var(--theme-yellow);
	color: var(--white);
}

.blog-page .blog-right-content i {
	outline: hidden !important;
	margin-right: 10px;
}

.blog-page .blog-right-content i::before {
	line-height: 19px;
}

.blog-page .blog-content .col-xl-12 {
	padding-bottom: 15px;
	margin-bottom: 15px;
	border-bottom: 1px solid var(--border-color-disable);
}

.blog-page .blog-content .col-xl-12:last-child {
	padding-bottom: 0px;
	margin-bottom: 0px;
	border-bottom: 0px;
}

.blog-details-page .blog-title-box .blog-date {
	max-width: fit-content;
	color: var(--black);
}

.blog-details-page .blog-title-box .blog-date span {
	font-size: 13px;
	font-weight: 400;
	font-family: var(--font-family);
	text-align: center;
	text-transform: capitalize;
}

.blog-details-page .blog-details-title {
	width: 100%;
	font-size: 30px;
	font-weight: 400;
	font-family: var(--font-family);
}

.blog-details-page .blog-title-box {
	border-bottom: 1px solid var(--border-color-disable);
}

.blog-details-page .blog-img-box img {
	height: 405px;
	object-fit: cover;
}

.blog-details-page .back--btn {
	width: fit-content;
	height: auto;
	padding: 4px 15px;
	text-transform: capitalize;
	color: var(--primary);
	font-size: 16px;
	margin-left: auto;
	border: 1px solid var(--theme-yellow-rgb);
	color: var(--primary);
	border-radius: 4px;
}

.blog-details-page .back--btn i {
	margin-right: 8px;
}

.blog-details-page .back--btn i::before {
	line-height: 19px;
}

.blog-page .blog-categories label {
	padding-left: 25px;
	margin-bottom: 5px;
	line-height: 22px;
	text-transform: capitalize;
}

.blog-page .blog-categories label input {
	position: absolute;
	opacity: 0;
}

.blog-page .blog-categories label span {
	position: absolute;
	width: 18px;
	height: 18px;
	background-color: var(--theme-light-yellow-rgb);
	left: 0px;
	top: 2px;
	border-radius: 4px;
	cursor: pointer;
}

.blog-page .blog-categories label span:hover {
	background-color: var(--theme-yellow-rgb);
}

.blog-page .blog-categories label input:checked ~ span {
	background-color: var(--theme-yellow);
}

.blog-page .blog-categories label span::after {
	content: "";
	position: absolute;
	display: none;
	left: 7px;
	top: 3px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}

.blog-page .blog-categories label input:checked ~ span::after {
	display: block;
}

.blog-page .no-blog--data {
	width: 100%;
	height: 30vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.blog-page .no-blog--data p {
	max-width: 1080px;
	width: 100%;
	margin: auto;
	text-align: center;
	padding: 25px;
	font-size: 22px;
	border-radius: 16px;
	background-color: var(--theme-light-yellow-rgb);
}

/* ----- {{ END:: Blog page CSS }} ----- */

/* -----  {{ START:: Filter canvas CSS }}  ----- */
.main-filter-button {
	background-color: var(--theme-yellow);
	font-size: 16px;
	text-transform: capitalize;
	font-family: var(--font-family);
	font-weight: 400;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 111;
	position: fixed;
	bottom: -1%;
	border-radius: 8px;
	color: var(--white);
	width: 100% !important;
	padding: 10px 15px 18px !important;
	border-radius: 20px 20px 0 0 !important;
}

.main-filter-button i::before {
	line-height: 19px;
}

.mobile-filter {
	max-height: 60vh !important;
	height: 100% !important;
	border-radius: 10px 10px 0px 0px;
	overflow: hidden;
}

.mobile-filter .mobile-filter-header {
	background-color: var(--theme-yellow);
	text-transform: capitalize;
	font-size: 20px;
	font-family: var(--font-family);
	font-weight: 400;
	color: var(--white);
}

.mobile-filter .mobile-filter-header button i {
	font-size: 16px;
	outline: none !important;
	color: var(--white);
}

.mobile-filter .mobile-filter-header button i::before {
	line-height: 19px;
}

/* -----  {{ END:: Filter canvas CSS }}  ----- */

/* -----  {{ START:: FAQ's css }}  ---- */

.faq-page h1 {
	font-size: 32px;
	font-weight: 400;
	font-family: var(--font-family);
	color: var(--primary);
	position: relative;
	padding-bottom: 20px;
}

.faq-page h1::after {
	position: absolute;
	content: "";
	width: 65%;
	height: 1px;
	background-color: var(--theme-yellow);
	left: 0px;
	bottom: 0px;
	transform: translate(25%, 0);
}

.faq-page .faq-details h3 {
	font-size: 22px;
	font-weight: 400;
	color: var(--primary);
	font-family: var(--font-family);
	text-transform: capitalize;
}

.faq-page .faq-details h3 span {
	font-size: 30px;
	font-weight: 400;
	color: var(--primary);
	font-family: var(--font-family);
}

.faq-page .faq-details p {
	font-size: 14px;
	font-weight: 400;
	color: var(--primary);
	font-family: var(--font-family);
	text-transform: capitalize;
	margin-bottom: 20px;
}

.faq-page .faq-details p:last-child {
	margin-bottom: 0px;
}

.faq-page .faq-details p span {
	font-size: 16px;
	font-weight: 600;
	color: var(--primary);
	text-transform: capitalize;
	font-family: var(--font-family);
}

.faq-page .col-xl-6 {
	margin-bottom: 12px !important;
}

.faq-page .col-xl-6:last-child,
.faq-page .col-xl-6:nth-last-child(2) {
	margin-bottom: 0px !important;
}

/* -----  {{ END:: FAQ's css }}  ---- */

.card-container .empty-image {
	max-width: 100px;
	width: 100%;
	height: 100px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/* ----- {{ START:: stepper css }} ----- */
.ir346-cyo__con .earring-img,
.ir346-cyo__con .necklace-img {
	width: 30px !important;
	height: 30px !important;
}

/* ----- {{ END:: stepper css }} ----- */
.modal.show {
	z-index: 99999;
}

/* -----  {{ START:: expand component css }}  ----- */
.expand-component {
	border-bottom: 1px solid var(--border-color-disable);
}

.expand-component img {
	max-width: 200px;
	width: 100%;
	height: 200px;
	object-fit: cover;
}

.expand-component .btn---box a {
	padding: 8px 12px;
	font-size: 14px;
	text-align: center;
	color: var(--primary);
	border: 1px solid var(--primary);
}

/* -----  {{ END:: expand component css }}  ----- */
.logo img {
	height: 65px;
}

.widget-logo img {
	height: 100px;
}

/* -----  {{ START:: Gifts page CSS }}  ----- */
.gifts-page .top-jewelry--gifts,
.gifts-page .jewelry-gifts-by--recipient,
.gifts-page .jewelry-gifts--meaning,
.gifts-page .more-way-to--shop {
	padding: 40px 0px 70px 0px;
}

.gifts-page .top-jewelry--gifts .title-content h3,
.gifts-page .jewelry-gifts-by--recipient .title-content h3,
.gifts-page .popular-jewelry--gifts .title-content h3,
.gifts-page .own-earring-and-necklace .caption h3,
.gifts-page .jewelry-gifts--meaning .title-content h3,
.gifts-page .more-way-to--shop .title-content h3 {
	font-size: 26px;
	font-weight: 400;
	text-transform: capitalize;
	color: var(--primary);
	font-family: var(--font-family);
	line-height: 32px;
}

.gifts-page .jewelry-gifts--slider .slick-next,
.gifts-page .jewelry-gifts-by--slider .slick-next,
.gifts-page .popular-jewelry-gifts--slider .slick-next {
	right: 20%;
}

.gifts-page .jewelry-gifts--slider .slick-prev,
.gifts-page .jewelry-gifts-by--slider .slick-prev,
.gifts-page .popular-jewelry-gifts--slider .slick-prev {
	right: 22%;
	left: auto;
}

.gifts-page .jewelry-gifts--slider .slick-arrow {
	top: -40px !important;
}

.gifts-page .jewelry-gifts--slider .slick-next {
	right: 17%;
}

.gifts-page .jewelry-gifts--slider .slick-prev {
	right: 19%;
}

.gifts-page .jewelry-gifts--slider .slick-arrow,
.gifts-page .jewelry-gifts-by--slider .slick-arrow,
.gifts-page .popular-jewelry-gifts--slider .slick-arrow {
	top: -32px;
	z-index: 11;
	width: 30px;
	height: 30px;
	border-radius: 50%;
}

.gifts-page .jewelry-gifts--slider .slick-arrow::before,
.gifts-page .jewelry-gifts-by--slider .slick-arrow::before,
.gifts-page .popular-jewelry-gifts--slider .slick-arrow::before {
	content: "";
	display: block;
	width: 20px;
	height: 20px;
	background-image: url(./image/back.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	top: 0;
	left: 5px;
}

.gifts-page .jewelry-gifts--slider .slick-next::before,
.gifts-page .jewelry-gifts-by--slider .slick-next::before,
.gifts-page .popular-jewelry-gifts--slider .slick-next::before {
	transform: rotate(-180deg);
}

.gifts-page .jewelry-gifts--slider a,
.gifts-page .jewelry-gifts-by--slider a,
.gifts-page .popular-jewelry-gifts--slider a,
.gifts-page .jewelry-gifts--inner a,
.gifts-page .more-way-to--shop .more-way-to-shop--inner a {
	display: block;
	margin: 10px 0px;
	color: var(--primary);
	text-transform: capitalize;
	font-size: 16px;
	line-height: 26px;
	font-family: var(--font-family);
}

.gifts-page .jewelry-gifts--slider a:hover,
.gifts-page .jewelry-gifts-by--slider a:hover,
.gifts-page .popular-jewelry-gifts--slider a:hover,
.gifts-page .jewelry-gifts--inner a:hover,
.gifts-page .more-way-to--shop .more-way-to-shop--inner a:hover {
	color: var(--theme-yellow);
}

.gifts-page .shop-by--price,
.gifts-page .shop-by--occasion {
	width: 100%;
	height: 600px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.gifts-page .shop-by--price .shop-by-price--inner,
.gifts-page .shop-by--occasion .shop-by-occasion--inner {
	margin-top: 10px;
	margin-bottom: 20px;
}

.gifts-page .shop-by--price .shop-by-price--inner h3,
.gifts-page .shop-by--occasion .shop-by-occasion--inner h3 {
	font-size: 26px;
	font-weight: 400;
	font-family: var(--font-family);
	color: var(--white);
	line-height: 32px;
}

.gifts-page .shop-by--price .row .col-xl-6:nth-child(1),
.gifts-page .shop-by--price .row .col-xl-6:nth-child(2),
.gifts-page .shop-by--occasion .row .col-xl-6:nth-child(1),
.gifts-page .shop-by--occasion .row .col-xl-6:nth-child(2) {
	margin-bottom: 12px;
}

.gifts-page .shop-by--price a:hover,
.gifts-page .shop-by--occasion a:hover {
	background-color: var(--white);
	color: var(--primary);
}

.gifts-page .own-earring-and-necklace {
	margin-top: 80px;
}

.gifts-page .own-earring-and-necklace .caption {
	position: absolute;
	max-width: 400px;
	width: 100%;
	left: 80px;
	bottom: 60px;
}

.gifts-page .own-earring-and-necklace .caption p {
	font-size: 14px;
	font-weight: 400;
	font-family: var(--font-family);
	color: var(--primary);
	margin-bottom: 20px;
	white-space: wrap;
}

.gifts-page .own-earring-and-necklace .caption a {
	max-width: 240px;
	width: 100%;
	font-size: 14px;
	border: 1px solid var(--black);
	color: var(--primary);
	text-align: center;
	text-transform: capitalize;
	padding: 9px 16px;
}

.gifts-page .own-earring-and-necklace .caption a:nth-child(3) {
	margin-bottom: 8px;
}

.gifts-page .jewelry-gifts--inner img {
	width: 100%;
	height: auto;
	transition: 0.25s ease-in-out;
}

.gifts-page .jewelry-gifts--inner img:hover {
	transform: scale(1.05);
}

.gifts-page .own-earring-and-necklace .gift-img {
	height: 740px;
	width: 100%;
	object-fit: cover;
}

.gifts-page .top-jewelry--gifts .slick-slider a img {
	width: 288px;
	height: 288px;
	object-fit: cover;
	margin: auto;
}

/* -----  {{ END:: Gifts page CSS }}  ----- */

/* -----  {{ START:: page up button CSS }}  ----- */

.page-up-btn--box {
	position: fixed;
	bottom: 65px;
	right: 18px;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	background-color: var(--theme-yellow);
	z-index: 12;
}

.page-up-btn--box .page-up--btn {
	width: 100%;
	height: 100%;
}

.page-up-btn--box .page-up--btn i::before,
.page-up-btn--box .page-up--btn i {
	line-height: 14px;
	color: var(--white);
}

.page-up-btn--box-1 {
	position: fixed;
	bottom: 25px;
	right: 25px;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	background-color: var(--theme-yellow);

	z-index: 9999;
}

.page-up-btn--box-1 .page-up--btn {
	width: 100%;
	height: 100%;
}

.page-up-btn--box-1 .page-up--btn i::before,
.page-up-btn--box-1 .page-up--btn i {
	line-height: 14px;
}

.progress-circle {
	position: fixed;
	top: 153px;
	width: 100%;
	z-index: 9999;
}

.progress-circle .progress {
	height: 3px;
	border-radius: 0px;
	background-color: transparent;
}

.progress-circle .progress .progress-bar {
	background-color: var(--theme-yellow);
}

/* -----  {{ END:: page up button CSS }}  ----- */

/* -----  {{ START:: Notification Canvas CSS }}  ----- */

.header-configure-area ul li button,
.mobile-menu-toggler .unnotification--btn {
	font-size: 26px;
	line-height: 1;
	color: var(--text-color);
	position: relative;
}

.notification-canvas {
	top: 154px !important;
}

.notification-canvas .notification-clear--button {
	width: fit-content;
	padding: 4px 15px !important;
	border-radius: 4px;
	background-color: var(--theme-yellow);
	text-transform: capitalize;
	color: var(--white);
	transition: all 0.3s ease-in-out;
}

.notification-canvas .notification-clear--button:hover {
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.notification-canvas .notification-card {
	padding: 10px;
	margin-bottom: 8px;
	background-color: var(--theme-light-yellow-rgb);
	border-radius: 8px;
	transition: all 0.3s ease-in-out;
	margin-right: 5px;
}

.notification-canvas .notification-card:last-child {
	margin-bottom: 0px;
}

.notification-canvas .notification-card:hover {
	background-color: var(--theme-yellow-rgb);
}

.notification-canvas .notification-card .notification_data {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 8px;
}

.notification-canvas .notification-card .notification_data button {
	width: 20px;
	height: 20px;
	line-height: 20px;
	border-radius: 50%;
	background-color: var(--red-rgba);
	transition: all 0.3s ease-in-out;
}

.notification-canvas .notification-card .notification_data button:hover {
	background-color: var(--delete-color);
	color: var(--white);
}

.notification-canvas .notification-card .notification_data button i {
	outline: none !important;
}

.notification-canvas .notification-card .notification_data button i::before {
	line-height: 18px;
}

.notification-canvas .notification-card .notification_data .title {
	font-size: 14px;
	text-transform: capitalize;
	color: var(--primary);
	width: fit-content;
	line-height: 20px;
}

.notification-canvas .notification-card .notification_date {
	font-size: 10px;
	background-color: var(--theme-yellow);
	padding: 4px;
	display: block;
	width: fit-content;
	line-height: 11px;
	border-radius: 4px;
	text-transform: capitalize;
	color: var(--white);
	margin-top: 8px;
}

.notification-canvas .no-details {
	height: 80px;
	line-height: 80px;
	border-radius: 8px;
	font-size: 18px;
	background-color: var(--theme-light-yellow-rgb);
	text-transform: capitalize;
	font-weight: 400;
}

/* -----  {{ END:: Notification Canvas CSS }}  ----- */

/*  -----  {{ START:: cut page CSS }}  -----  */

.cut--page h4,
.details--page h4 {
	font-size: 30px;
	font-weight: 500;
	color: var(--theme-yellow);
	text-transform: capitalize;
}

.cut--page button {
	padding: 6px 12px !important;
	color: var(--white);
	background-color: var(--theme-yellow);
	font-size: 16px;
	text-transform: capitalize;
	transition: all 0.3s ease-in-out;
	border-radius: 12px 0px 12px 0px;
}

.cut--page button:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.cut--page p {
	font-size: 16px;
	color: var(--black);
	margin-bottom: 12px;
}

.cut--page p a {
	color: var(--black);
	transition: all 0.3s ease-in-out;
}

.cut--page p a:hover {
	color: var(--theme-yellow);
}

.cut--page .cut-inner--page,
.cut--page .cut-shap--section,
.cut--page .cut-link--box,
.details--page .details-inner--page {
	background-color: var(--theme-light-yellow-rgb);
}

.cut--page .cut-inner--page img {
	max-width: 250px;
	height: 250px;
	margin: auto;
	display: block;
	object-fit: cover;
}

.cut--page .cut-shap--section a {
	text-transform: capitalize;
	color: var(--black);
	transition: all 0.3s ease-in-out;
}

.cut--page .cut-shap--section a:hover {
	color: var(--theme-yellow);
}

.cut--page .cut-link--box {
	border-radius: 8px;
}

.cut--page .cut-link--box p {
	font-size: 13px;
}

.details--page {
	padding: 80px 0px 0px 0px;
}

.details--page p,
.details--page ol li {
	font-size: 16px;
	font-weight: 400;
	color: var(--black);
	text-transform: capitalize;
}

.details--page .before-after-slider__delimiter-icon {
	background-image: url(./image/arrows-h.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: 65%;
}

.details--page .before-after-slider__delimiter {
	width: 3px;
}

.details--page .before-after-slider__second-photo-container,
.details--page .before-after-slider__second-photo-container img {
	width: 100%;
	height: 100%;
}

/*  -----  {{ END:: cut page CSS }}  -----  */

.loader-container {
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
}

.cart-container-loader {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loader-container .bg-shadow {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	z-index: -1;
	background-color: rgba(0, 0, 0, 0.1);
}

@keyframes notification {
	0% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		transform: scale(2);
		opacity: 0;
	}
}

.main_rangeSlider {
	background: #f9f9f8;
	padding: 10px 0 40px 0;
}

.main_rangeSlider h6 {
	font-weight: 400;
	font-size: 14px;
	text-transform: capitalize;
}

.main_rangeSlider .rc-slider-rail,
.main_rangeSlider .rc-slider-track {
	background-color: #dedede !important;
}

.main_rangeSlider .rc-slider-dot-active {
	border-color: var(--theme-yellow) !important;
}

.reset_wrapper h2 {
	font-family: var(--font-family) !important;
}

.reset_wrapper .forget_password_cancel {
	font-size: 14px;
	-webkit-transition: 0.3s;
	transition: 0.3s;
	color: var(--thm-black);
	text-decoration: underline;
}

.reset_wrapper .btn--primary.style2 {
	display: inline-block;
	width: 100%;
	background: var(--theme-yellow);
	color: var(--white);
	font-size: 12px;
	line-height: 40px;
	font-weight: 500;
	height: 50px;
	text-transform: uppercase;
	padding: 5px 20px !important;
	border-radius: 0;
	border: 0px;
	-webkit-transition: all 0.2s linear;
	transition: all 0.2s linear;
	-webkit-transition-delay: 0.1s;
	transition-delay: 0.1s;
}

.dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: none !important;
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
}

.engrave-text-1 {
	font-family: "Avenir-Medium" !important;
}

.engrave-text-2 {
	font-family: "Lucida" !important;
}

.text-on-border {
	border: 1px solid var(--theme-yellow);
}

.text-engraving-color {
	color: var(--theme-yellow);
}

.map-area .map--box {
	width: 100%;
	height: 500px;
}

.rc-slider-handle:focus-visible,
.rc-slider-handle:active {
	border-color: none !important;
	box-shadow: none !important;
	cursor: pointer !important;
}

.return-order-error {
	color: #ff0000;
}

.mainSlider .heroSliderImage_1 .title--box,
.hero-section--banner .title--box {
	padding: 12px;
	width: fit-content;
	height: auto;
	margin: auto;
	border-radius: 8px;
}

.jewellery--loder,
.jewellery--loder .loader-container {
	height: 100%;
}

.diamond--loader,
.gemstone--loader,
.color-diamond--loader {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loader-overlays {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #fff;
	display: none;
	justify-content: center;
	align-items: center;
	z-index: 9999;
}

.loader-overlays.visible {
	display: flex;
}

.loaders {
	padding: 20px;
	border-radius: 5px;
}

.login-register-form .spinner-border {
	display: block;
	margin: 0 auto;
	height: 25px;
	width: 25px;
}

.faq-page .no-faq--data {
	width: 100%;
	height: 30vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.faq-page .no-faq--data p {
	max-width: 1080px;
	width: 100%;
	margin: auto;
	text-align: center;
	padding: 25px;
	font-size: 22px;
	border-radius: 16px;
	background-color: var(--theme-light-yellow-rgb);
}

.mobile-main-header .mobile-menu-toggler .user-name-div {
	line-height: 1;
	margin-right: 10px;
	font-size: 18px;
}

.mobile-main-header .mobile-menu-toggler .user-name-div .user-name {
	font-size: 12px;
	font-weight: 500;
	margin: 0 !important;
	padding: 10px;
	padding-bottom: 0px;
	text-transform: capitalize;
}

.mobile-main-header .mobile-menu-toggler .user-name-div i {
	font-size: 18px;
	font-weight: 500;
	line-height: 25px;
}

.blog-content img {
	width: 100%;
	height: 100px;
	object-fit: cover;
}

.product-card-container .ring-size-width {
	width: 170px;
}

.shopping-cart-table .shopping-diamond-content .product--stockid {
	line-height: 35px;
}

.shopping-cart-table .shopping-content .ring-size-select {
	width: 160px;
}

.shopping-cart-table .shopping-content .shopping-inner-content .multiproduct-select {
	width: 200px;
}

.heroButton--slider:hover {
	background-color: var(--theme-yellow-color-rgba);
}

.iiz__btn {
	background: transparent !important;
}

.iiz__hint:before {
	display: none !important;
}

.handring--wrapper .ring--image {
	position: absolute;
	top: 24%;
	left: 19.5%;
	width: 45%;
	height: 45%;
}

/* input CSS start */

.diamond--shape-wrapper {
	display: grid;
	grid-template-columns: repeat(10, minmax(0, 1fr));
	grid-gap: 0px 5px;
}

.main--input-wraper {
	grid-gap: 0px 8px;
}

.input--disable,
.input--enable {
	width: 34px;
	height: 34px;
	overflow: hidden;
	border-radius: 100%;
	cursor: pointer;
}

.input--disable .custom--input,
.input--enable .custom--input {
	position: absolute;
	opacity: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.input--disable {
	border: 2px solid transparent;
}

.input--enable {
	border: 2px solid var(--theme-yellow);
}

.static--input-enable,
.static--input-disable {
	padding: 3px 10px;
	border-radius: 50px;
	font-size: 12px;
	cursor: pointer;
}

.static--input-disable,
.width--input-disable {
	border: 2px solid #d3d3d3;
}

.static--input-enable,
.width--input-enable {
	border: 2px solid var(--theme-yellow);
}

.static--input-enable .custom--input,
.static--input-disable .custom--input {
	position: absolute;
	opacity: 0;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	cursor: pointer;
}

.width--input-disable,
.width--input-enable {
	width: 40px;
	height: 40px;
	overflow: hidden;
	border-radius: 50%;
	cursor: pointer;
}

.width--input-enable .width--input,
.width--input-disable .width--input {
	position: absolute;
	cursor: pointer;
	opacity: 0;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.diamond--shape-wrapper .diamond--enable,
.diamond--shape-wrapper .diamond--disable {
	overflow: hidden;
	border-radius: 4px;
	cursor: pointer;
	width: 34px;
	height: 34px;
	margin: auto;
}

.diamond--shape-wrapper .diamond--enable {
	border: 2px solid var(--theme-yellow);
}

.diamond--shape-wrapper .diamond--disable {
	border: 2px solid #dedede;
}

.diamond--shape-wrapper .diamond--enable input,
.diamond--shape-wrapper .diamond--disable input {
	position: absolute;
	top: 0px;
	left: 0px;
	opacity: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.diamond--shape-wrapper .diamond--disable img,
.diamond--shape-wrapper .diamond--enable img {
	width: 100%;
	height: 100%;
}

/* input CSS end */

.stone--details {
	position: absolute;
	background-color: var(--white);
	color: var(--secondary);
	text-align: start;
	padding: 1px;
	font-size: 10px;
}

.stone--details.text-overlayRound1,
.stone--details.text-overlayMarquise1,
.stone--details.text-overlayPrincess1,
.stone--details.text-overlayOval1,
.stone--details.text-overlayCushion1 {
	left: 50%;
}

.stone--details.text-overlayPrincess5,
.stone--details.text-overlayEmerald5,
.stone--details.text-overlayHeart5,
.stone--details.text-overlayMarquise5,
.stone--details.text-overlayAsscher5 {
	top: 9%;
}

.stone--details.text-overlayCushion6,
.stone--details.text-overlayRadiant6,
.stone--details.text-overlayPrincess6,
.stone--details.text-overlayAsscher6,
.stone--details.text-overlayHeart3,
.stone--details.text-overlayEmerald6 {
	left: 50%;
}

.stone--details.text-overlayCushion4,
.stone--details.text-overlayEmerald4,
.stone--details.text-overlayRadiant4 {
	top: 60%;
}

.stone--details.text-overlayRound6,
.stone--details.text-overlayCushion3,
.stone--details.text-overlayRadiant3 {
	top: 83%;
}

.stone--details.text-overlayPrincess2,
.stone--details.text-overlayPrincess4,
.stone--details.text-overlayEmerald2 {
	top: 69%;
}

.stone--details.text-overlayAsscher5,
.stone--details.text-overlayCushion5 {
	left: 33.6%;
}

.stone--details.text-overlayRound2,
.stone--details.text-overlayCushion2 {
	left: 10%;
}

.stone--details.text-overlayRound3,
.stone--details.text-overlayAsscher1 {
	left: 40%;
}

.stone--details.text-overlayEmerald3,
.stone--details.text-overlayRadiant3 {
	left: 44.6%;
}

.stone--details.text-overlayOval5,
.stone--details.text-overlayRadiant5 {
	top: 8%;
}

.stone--details.text-overlayOval2,
.stone--details.text-overlayPear2 {
	top: 67%;
}

.stone--details.text-overlayCushion3,
.stone--details.text-overlayOval3,
.stone--details.text-overlayMarquise3 {
	left: 41%;
}

.stone--details.text-overlayPrincess4,
.stone--details.text-overlayAsscher4,
.stone--details.text-overlayMarquise4 {
	left: 76.6%;
}

.stone--details.text-overlayPrincess5,
.stone--details.text-overlayPear5 {
	left: 34.5%;
}

.stone--details.text-overlayRadiant4,
.stone--details.text-overlayOval4 {
	left: 78%;
}

.stone--details.text-overlayOval2,
.stone--details.text-overlayPear2 {
	left: 20%;
}

.stone--details.text-overlayEmerald4,
.stone--details.text-overlayPear4 {
	left: 76.7%;
}

.stone--details.text-overlayAsscher4,
.stone--details.text-overlayEmerald3 {
	top: 76.4%;
}

.stone--details.text-overlayPear5,
.stone--details.text-overlayCushion5 {
	top: 10%;
}

.stone--details.text-overlayCushion1,
.stone--details.text-overlayRadiant1 {
	top: 13%;
}

.stone--details.text-overlayRadiant6,
.stone--details.text-overlayCushion6 {
	top: 60.5%;
}

.stone--details.text-overlayHeart4,
.stone--details.text-overlayHeart6 {
	top: 74.7%;
}

.stone--details.text-overlayPrincess6,
.stone--details.text-overlayMarquise2 {
	top: 63.5%;
}

.stone--details.text-overlayRound1 {
	top: 16.5%;
	transform: translate(-46%, -6.5%);
}

.stone--details.text-overlayRound2 {
	top: 65.9%;
	transform: translate(16%, 83.1%);
}

.stone--details.text-overlayRound3 {
	top: 83.9%;
	transform: translate(32%, 0%);
}

.stone--details.text-overlayRound4 {
	top: 67.9%;
	left: 74%;
	transform: translate(17%, 244%);
}

.stone--details.text-overlayRound5 {
	top: 7.9%;
	left: 20.3%;
	transform: translate(-62.7%, 7.9%);
}

.stone--details.text-overlayRound6 {
	left: 43.7%;
}

.stone--details.text-overlayOval1 {
	top: 22%;
}

.stone--details.text-overlayOval3 {
	top: 77%;
}

.stone--details.text-overlayOval4 {
	top: 70%;
}

.stone--details.text-overlayOval5 {
	left: 28.3%;
}

.stone--details.text-overlayOval6 {
	top: 57.3%;
	left: 42.3%;
	transform: translate(0%, 0%);
}

.stone--details.text-overlayCushion2 {
	top: 70%;
	left: 25%;
	transform: translate(-65%, -15%);
}

.stone--details.text-overlayCushion4 {
	left: 76.5%;
}

.stone--details.text-overlayPear1 {
	top: 21.9%;
	left: 47%;
	transform: translate(15%, -12.9%);
}

.stone--details.text-overlayPear3 {
	top: 78%;
	left: 54.9%;
	transform: translate(-30%, 0%);
}

.stone--details.text-overlayPear4 {
	top: 71.2%;
}

.stone--details.text-overlayPear6 {
	top: 35.7%;
	left: 52%;
	transform: translate(-25%, -15%);
}

.stone--details.text-overlayRadiant1 {
	left: 44.1%;
}

.stone--details.text-overlayRadiant2 {
	top: 69.5%;
	left: 19%;
}

.stone--details.text-overlayRadiant5 {
	left: 33.7%;
}

.stone--details.text-overlayPrincess1 {
	top: 15.5%;
}

.stone--details.text-overlayPrincess2 {
	left: 17%;
}

.stone--details.text-overlayPrincess3 {
	top: 81%;
	left: 42%;
	transform: translate(-25%, 0%);
}

.stone--details.text-overlayMarquise1 {
	top: 25%;
}

.stone--details.text-overlayMarquise2 {
	left: 22%;
}

.stone--details.text-overlayMarquise3 {
	top: 74%;
}

.stone--details.text-overlayMarquise4 {
	top: 66.4%;
}

.stone--details.text-overlayMarquise5 {
	left: 19.4%;
}

.stone--details.text-overlayMarquise6 {
	top: 82%;
	left: 42.5%;
}

.stone--details.text-overlayAsscher1 {
	top: 13.5%;
}

.stone--details.text-overlayAsscher2 {
	top: 70.3%;
	left: 21%;
}

.stone--details.text-overlayAsscher3 {
	top: 84%;
	left: 44.4%;
}

.stone--details.text-overlayAsscher6 {
	top: 60.4%;
}

.stone--details.text-overlayHeart1 {
	top: 17%;
	left: 48.5%;
}

.stone--details.text-overlayHeart2 {
	top: 64.8%;
	left: 29%;
}

.stone--details.text-overlayHeart3 {
	top: 79%;
}

.stone--details.text-overlayHeart4 {
	left: 76%;
}

.stone--details.text-overlayHeart5 {
	left: 13%;
}

.stone--details.text-overlayHeart6 {
	left: 42.6%;
}

.stone--details.text-overlayEmerald1 {
	top: 21%;
	left: 43%;
	transform: translate(-5%, -10%);
}

.stone--details.text-overlayEmerald2 {
	left: 24%;
}

.stone--details.text-overlayEmerald5 {
	left: 33.5%;
}

.stone--details.text-overlayEmerald6 {
	top: 72%;
}

.my-account-loader {
	height: 333px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.education-silder--box a {
	display: block;
	height: 250px;
	width: 250px;
	margin: auto;
}

.education-silder--box .education-card-content img {
	display: block;
	height: 100%;
	width: 100%;
}

.education-silder--box .education-card-content {
	height: 100%;
	width: 100%;
}

.education-silder--box .education-card-content span {
	height: 300px;
	width: 100%;
}

.education-silder--box .education-card-content img {
	height: 300px;
	width: 300px;
}

.card-container .react-card-image-div span {
	height: 100% !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center;
}

.shop-by-style-container .disable span {
	display: flex !important;
	align-items: center;
}

.shop-by-style-container .checkmarks span {
	display: flex !important;
	align-items: center;
}

.card-container .react-card-image-div span img {
	max-width: 290px;
	width: 100%;
	display: block;
	height: 290px;
	margin: auto;
	border-radius: 20px;
}

.offcanvas {
	--bs-offcanvas-border-color: none;
}

.bg-layer-banner {
	background: rgb(0, 0, 0);
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.3295693277310925) 60%, rgba(0, 0, 0, 0) 100%);
}

.bg-layer-banner-hero {
	background: rgba(194, 153, 88, 0.5);
}
