/* ====== {{ START:: Responsive CSS  }} ====== */

@media all {
	.breadcrumb_main_div .style-pi63e {
		color: #ffffff !important;
		font-weight: 300;
		margin: 0;
		font-size: 36px;
	}

	.breadcrumb_main_div .breadcrumb-77p .crub_name {
		text-transform: capitalize;
	}

	.breadcrumb_main_div .style-pi63e .crub_name {
		text-transform: uppercase !important;
	}

	.breadcrumb_main_div .container-i2x {
		width: 100%;
		padding-right: var(--bs-gutter-x, 0.75rem);
		padding-left: var(--bs-gutter-x, 0.75rem);
		margin-right: auto;
		margin-left: auto;
	}

	.breadcrumb_main_div .row-ht9 {
		--bs-gutter-x: 1.5rem;
		--bs-gutter-y: 0;
		display: flex;
		flex-wrap: wrap;
		margin-top: calc(var(--bs-gutter-y) * -1);
		margin-right: calc(var(--bs-gutter-x) * -0.5);
		margin-left: calc(var(--bs-gutter-x) * -0.5);
	}

	.breadcrumb_main_div .breadcrumb-hiz h2 {
		padding-bottom: 12px;
		color: #ffffff;
		text-transform: capitalize;
	}

	.breadcrumb_main_div ul {
		padding: 0;
		margin-top: 0;
		margin-bottom: 1rem;
		list-style: none;
	}

	.breadcrumb_main_div .breadcrumb-77p ul {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.breadcrumb_main_div li {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	.breadcrumb_main_div a {
		color: #0d6efd;
		text-decoration: none;
		outline: none;
		padding: 0;
		margin: 0;
		display: inline-block;
		-webkit-transition: 0.3s;
		transition: 0.3s;
		-webkit-transition: all 0.3s ease-in-out;
	}

	.breadcrumb_main_div a:hover,
	a:active {
		color: #0a58ca;
		text-decoration: none;
		outline: none;
		-webkit-transition: 0.3s;
		transition: 0.3s;
		-webkit-transition: all 0.3s ease-in-out;
	}
}

@media only screen and (max-width: 767.98px) {
	.footer-top {
		margin-top: 20px !important;
	}

	.small-product-banner {
		display: block !important;
		transform: translateX(0%) !important;
	}



	.small-product-banner .bg-black {
		opacity: 0.5 !important;
	}

	.btn--reset-1 {
		display: none;
	}

	.gifts-page .jewelry-gifts--slider .slick-next,
	.gifts-page .jewelry-gifts-by--slider .slick-next,
	.gifts-page .popular-jewelry-gifts--slider .slick-next {
		right: 18%;
	}

	.gifts-page .shop-by--price,
	.gifts-page .shop-by--occasion {
		height: 400px;
	}

	.sappDiv p,
	.sappDiv h3,
	.sappDiv ul li,
	.sappDiv p a,
	.sappDiv h1,
	.sappDiv h3 span {
		letter-spacing: 0px;
	}

	.policy-item {
		margin-bottom: 8px;
	}

	.section-title .title {
		font-size: 26px;
	}

	.hot-deals-item .price-box {
		font-size: 21px;
		padding-top: 8px;
	}

	.banner-content {
		right: 20px;
	}

	.banner-text1 {
		font-size: 14px;
		padding-bottom: 10px;
	}

	.banner-content {
		right: 20px;
		text-align: right !important;
	}

	.banner-text1 {
		font-size: 14px;
		padding-bottom: 10px;
	}

	.banner-text2 {
		font-size: 17px;
		padding-bottom: 10px;
	}

	.categories-group-wrapper {
		margin-bottom: 60px;
	}

	.cart-update-option .apply-coupon-wrapper button {
		display: block;
		width: 100%;
		margin-top: 15px;
		border-radius: 0;
	}

	.cart-update-option .cart-update {
		margin-top: 15px;
	}

	.cart-update-option .cart-update .btn {
		width: 100%;
	}

	.order-summary-details {
		margin-top: 52px;
	}

	.contact-info {
		margin-top: 60px;
	}

	.product-details-des {
		margin-top: 58px;
	}

	.product-details-des .price-box .price-regular {
		color: #c29958;
	}

	.product-details-des.quick-details {
		margin-top: 30px;
	}

	.product-details-des.quick-details .product-name {
		font-size: 18px;
	}

	.product-review-info .tab-content .review-description {
		display: block;
	}

	.product-review-info .tab-content .review-description .tab-thumb {
		-webkit-flex-basis: 220px;
		-ms-flex-preferred-size: 220px;
		flex-basis: 220px;
	}

	.product-review-info .tab-content .review-description .tab-des {
		padding-left: 14px;
		-webkit-flex-basis: calc(100% - 220px);
		-ms-flex-preferred-size: calc(100% - 220px);
		flex-basis: calc(100% - 220px);
	}

	.sidebar-wrapper {
		margin-top: 62px;
	}

	.top-bar-right {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.product-content-list {
		padding-left: 0;
		max-width: 100%;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
		margin-top: 30px;
	}

	.about-thumb {
		text-align: center;
	}

	.about-thumb img {
		width: 100%;
	}

	.about-title {
		font-size: 26px;
		color: #222222;
		margin-bottom: 25px;
	}

	.about-sub-title {
		font-size: 16px;
	}

	.ur-cta-section .bg-shape {
		display: none;
	}

	.section-padding {
		padding-top: 63px;
		padding-bottom: 63px;
	}

	.newsletter-wrapper {
		margin-top: 8px;
	}

	.newsletter-inner {
		margin-top: 5px;
	}

	.footer-payment {
		text-align: center;
		margin-top: 30px;
	}

	.about-us .about-title {
		font-size: 26px;
		color: #222222;
		margin-bottom: 25px;
	}

	.about-us .about-sub-title {
		font-size: 16px;
	}

	.stepping-bar .ir346-cyo__con .avenir-medium:nth-child(2),
	.stepping-bar .ir346-cyo__con .ir346-cyo__text {
		font-size: 10px !important;
		padding: 0px 8px !important;
	}

	.stepping-bar .ir346-cyo__con .avenir-medium {
		font-size: 12px;
	}
}

@media only screen and (max-width: 575.98px) {
	.policy-item {
		display: block;
		text-align: center;
	}

	.product-details-des .price-box {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.banner-content {
		right: 55px;
	}

	.banner-text1 {
		font-size: 15px;
		padding-bottom: 15px;
	}

	.policy-icon {
		padding-right: 0;
		margin-bottom: 10px;
	}

	.banner-content {
		right: 55px;
	}

	.banner-text1 {
		font-size: 15px;
		padding-bottom: 15px;
	}

	.banner-text2 {
		font-size: 25px;
		padding-bottom: 34px;
	}

	.review-form h5 {
		font-size: 16px;
	}

	.myaccount-content {
		padding: 20px 15px;
	}
}

@media only screen and (max-width: 475px) {
	.dimensions_div {
		flex-wrap: wrap;
		justify-content: center;
	}

	.stone_container {
		width: 100%;
	}

	.stone_container span {
		width: 100%;
		text-align: center;
	}

	.inner-tab-content {
		overflow-x: auto;
	}

	.inner-tab-content .label_container {
		margin-right: 5px;
	}

	.inner-tab-content .label_container:last-child {
		margin-right: 0px;
	}

	.diamond-page .transformational-tech .mini-container h2 {
		font-size: 24px;
	}

	.diamond-page .transformational-tech .mini-container p {
		font-size: 14px;
	}

	.main-jewelry .live-color {
		background-image: none;
		padding: 0;
	}

	.main-jewelry .live-color a {
		left: 49.5%;
		top: 86%;
		transform: translateX(-50%);
	}

	.main-jewelry .live-color img {
		display: block;
	}

	.mainSlider .heroSliderImage .slide-title,
	.mainSlider .heroSliderImage_1 .slide-title,
	.mainSlider .heroSliderImage_2 .slide-title {
		font-size: 30px;
		text-align: center;
	}

	.mainSlider .heroSliderImage .slide-desc,
	.mainSlider .heroSliderImage_2 .slide-desc,
	.mainSlider .heroSliderImage_1 .slide-desc {
		font-size: 16px;
		text-align: center;
	}

	.mainSlider .heroSliderImage_2,
	.mainSlider .heroSliderImage,
	.mainSlider .heroSliderImage_1 {
		height: 400px !important;
	}

	.mainSlider .heroSliderImage .btn-hero,
	.mainSlider .heroSliderImage_2 .btn-hero,
	.mainSlider .heroSliderImage_1 .btn-hero {
		margin-top: 25px !important;
		display: block;
		width: 50%;
		margin: auto;
	}

	.mainSlider .heroSliderImage {
		background-position: 10%;
	}

	.ring_main_div .ring_slider h2 {
		background-size: 100%;
	}

	.tab-content-heading .shape-box,
	.shape-box {
		flex-wrap: wrap;
		grid-gap: 5px 10px;
	}

	.color-slider .slick-prev,
	.color-slider .slick-next {
		top: -30px;
	}

	.origin-content {
		justify-content: center;
	}

	.rageslider-input input {
		width: 35%;
	}

	.diamond .sc-hmdomO>p {
		text-align: center;
	}

	.product-details-inner .slick-dots {
		display: flex !important;
		top: auto;
		bottom: 0px;
		width: 100%;
		flex-direction: unset;
		height: auto;
		position: relative;
	}

	.product-details-inner .slick-list {
		margin-left: 0px !important;
	}

	.product-details-inner .slick-dots li {
		margin-bottom: 0px;
	}

	.form_register-destop {
		padding: 20px;
	}

	.shopping-account-content {
		flex-wrap: wrap;
	}

	.section-container .page-404 .title-error {
		font-size: 100px;
	}

	.content-page-404 .subtitle {
		font-size: 30px;
	}

	.diamond .expand-title {
		font-size: 20px;
	}

	.dimensions_div .dimensions-inner .stone_container {
		font-size: 12px;
	}

	.lookbook-content .item .item-lookbook .content-lookbook {
		left: -65px;
		bottom: 50px;
	}

	.lookbook-content .item .item-lookbook:hover .content-lookbook {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 8px;
	}

	.sappDiv h1 {
		font-size: 28px;
	}

	.sappDiv h3,
	.sappDiv h3 span {
		font-size: 18px;
	}

	.sappDiv ul li,
	.sappDiv p,
	.sappDiv p a {
		font-size: 14px;
		margin-bottom: 5px;
	}
}

@media only screen and (max-width: 375px) {
	.card-container .card .card-content .price--box {
		inset: auto auto 15px 269px !important;
	}

	.metal-box {
		flex-wrap: wrap;
		justify-content: center !important;
		grid-gap: 10px;
		margin-bottom: 20px;
	}

	.lookbook-content .item .item-lookbook .content-lookbook .item-thumb a {
		display: block;
	}

	.lookbook-content .item .item-lookbook .content-lookbook .item-thumb {
		width: fit-content;
	}

	.lookbook-content .item .item-lookbook .content-lookbook .item-thumb img {
		margin-left: 0px;
		display: block;
		height: 60px;
		min-width: 60px !important;
		border-radius: 8px 0 0 8px;
	}

	.lookbook-content .item .item-lookbook .content-lookbook-bottom .item-title a {
		display: block;
		font-size: 15px;
	}

	.lookbook-content .item .item-lookbook .content-lookbook {
		left: -65px;
		bottom: 50px;
	}

	.lookbook-content .item .item-lookbook:hover .content-lookbook {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 8px;
	}

	.lookbook-content .item .item-lookbook .content-lookbook .content-lookbook-bottom {
		margin-top: 0px;
	}

	.section {
		padding: 0;
	}

	.lookbook-items .m-b-80 {
		margin-bottom: 0px !important;
	}

	.lookbook-intro-wrap .lookbook-intro .title {
		font-size: 22px;
	}

	.lookbook-intro-wrap .lookbook-intro {
		margin-top: 15px;
	}
}

@media only screen and (max-width: 479.98px) {
	.banner-content {
		right: 20px;
	}

	.banner-text1 {
		font-size: 14px;
		padding-bottom: 10px;
	}

	.banner-text2 {
		font-size: 14px;
		padding-bottom: 10px;
		line-height: 22px;
	}

	.banner-content {
		right: 20px;
	}

	.banner-text1 {
		font-size: 20px !important;
		padding-bottom: 10px;
	}

	.contact-info ul li {
		-webkit-box-align: baseline;
		-webkit-align-items: baseline;
		-ms-flex-align: baseline;
		align-items: baseline;
	}

	.product-review-info .nav.review-tab li a {
		font-size: 14px;
	}

	.about-content {
		padding: 52px 0 0 0;
	}

	.mobile-menu {
		margin-top: 15px;
	}

	.offcanvas-widget-area {
		padding-bottom: 0;
	}

	.about-content {
		padding: 52px 0 0 0 !important;
	}

	.about-us .about-us-hero-section {
		height: 320px;
		background-size: auto 100%;
	}

	.about-us .about-us-hero-section h2 {
		font-size: 42px;
		text-align: center;
	}

	.about-us .our-mission {
		padding: 50px 12px;
	}

	.about-us .our-mission .our-mission-inner-content h2 {
		font-size: 20px;
	}

	.about-us .our-mission .our-mission-inner-content h2:nth-child(2) {
		margin-top: 25px !important;
		margin-bottom: 10px !important;
	}

	.about-us .content-box {
		width: 100%;
		margin: 0px;
	}

	.about-us .content-box span {
		font-size: 14px;
		color: #ffffff;
	}

	.about-us .content-box h3 {
		font-size: 20px;
		margin-bottom: 20px !important;
	}

	.about-us .content-box p {
		font-size: 12px;
		line-height: 20px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: initial;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		height: 40px;
	}

	.about-us .responsible-box .responsible-inner-box img,
	.about-us .three-icon-wrapper .three-icon-inner-content img {
		max-width: 50px;
		width: 100%;
		height: 50px;
	}

	.about-us .responsible-box .responsible-inner-box span,
	.about-us .three-icon-wrapper .three-icon-inner-content span {
		font-size: 12px;
	}

	.about-us .responsible-box .responsible-inner-box,
	.about-us .three-icon-wrapper .three-icon-inner-content {
		max-width: 100%;
	}

	.about-us .explore-inner-wrapper h3::before {
		left: 20px;
		width: 20%;
	}

	.about-us .explore-inner-wrapper h3::after {
		right: 20px;
		width: 20%;
	}

	.about-us .responsible-box .responsible-inner-box h3 {
		font-size: 16px;
		margin-top: 30px !important;
		margin-bottom: 5px !important;
	}

	.about-us .responsible-box .responsible-inner-box,
	.about-us .three-icon-wrapper .three-icon-inner-content {
		padding: 50px 0px;
	}

	.about-us .three-icon-wrapper .three-icon-inner-content p {
		font-size: 14px;
	}

	.slider--container .slick-prev {
		left: auto;
		right: 12%;
		z-index: 11;
	}

	.slider--container .slick-next {
		right: 0px;
	}

	.slider--container .slick-next,
	.slider--container .slick-prev {
		top: -20px;
	}

	.about-us .about-us--banner-slider .about-content--card {
		width: 87%;
		right: 19px;
		transform: translate(0, 0);
		height: 40%;
		border-radius: 10px;
		top: 58%;
	}

	.about-us .about-banner-engagement--slider .slick-dots {
		width: 100%;
	}

	.about-us .about-banner-engagement--slider .slick-prev {
		left: 5%;
	}

	.about-us .about-banner-engagement--slider .slick-next {
		right: 5%;
	}

	.about-us .content-box h3::after {
		top: 25px;
	}

	.about-us .about-us--banner-slider .about-content--card .content-box a {
		padding: 5px 12px;
		font-size: 13px;
	}

	.about-us .about-us--banner-slider {
		height: 350px;
	}

	.mainSlider .heroSliderImage .slide-desc,
	.mainSlider .heroSliderImage_2 .slide-desc,
	.hero-section--banner .slide-desc,
	.mainSlider .heroSliderImage_1 .slide-desc {
		font-size: 16px;
	}

	.weddingRings-static---banner .wedding-static--img {
		height: 350px;
		object-fit: cover;
	}

	.weddingRings-static---banner {
		margin-bottom: 10px;
		margin-top: 50px;
	}

	.mainSlider .heroSliderImage_1 .slide-desc {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}

	.heroSliderImage_1 .slide-title {
		line-height: 37px !important;
		max-height: 74px;
		height: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		width: 100%;
	}
}

@media only screen and (max-width: 1199.98px) {
	.ur-cta-content h2 {
		line-height: 1.2em;
	}

	.image-container {
		height: 375px !important;
		width: 375px !important;
		display: block !important;
		margin: auto;
	}

	.image-container .slider-img {
		height: 375px !important;
	}

	.lookbook-items {
		display: none;
	}

	.mainSlider .heroSliderImage_1 {
		height: 500px;
	}
}

@media only screen and (max-width: 767.98px),
only screen and (max-width: 479.98px) {
	.banner-text2 span {
		display: inline-block;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.banner-content {
		right: 30px;
	}

	.engagement-ring-last-banner {
		margin-top: 200px !important;
	}

	.btn--reset-1 {
		display: none;
	}

	.dimensions_div {
		display: flex !important;
		flex-wrap: wrap;
	}

	.banner-content {
		right: 30px;
	}

	.banner-text2 {
		font-size: 15px;
		padding-bottom: 15px;
	}

	.categories-group-wrapper {
		margin-bottom: 78px;
	}

	.order-summary-details {
		margin-top: 70px;
	}

	.contact-info {
		margin-top: 77px;
	}

	.contact-info p {
		padding-bottom: 10px;
	}

	.product-details-des {
		margin-top: 76px;
	}

	.product-details-des.quick-details {
		margin-top: 0;
	}

	.product-details-des.quick-details .product-name {
		font-size: 20px;
	}

	.product-review-info .tab-content .review-description {
		padding: 15px 0;
	}

	.sidebar-wrapper {
		margin-top: 80px;
	}

	.about-thumb {
		text-align: center;
	}

	.main-jewelry .live-color a {
		width: 155px;
	}

	.main-jewelry .live-color {
		padding-bottom: 45%;
	}

	.about-us .about-us-hero-section {
		height: 330px;
		background-size: auto 100%;
	}

	.about-us .our-mission {
		padding: 50px 0px;
	}

	.about-us .our-mission .our-mission-inner-content h2 {
		font-size: 20px;
		padding: 0px 30px;
	}

	.about-us .our-mission .our-mission-inner-content h2:nth-child(2) {
		margin-top: 25px !important;
		margin-bottom: 10px !important;
	}

	.about-us .responsible-box .responsible-inner-box img,
	.about-us .three-icon-wrapper .three-icon-inner-content img {
		max-width: 50px;
		width: 100%;
		height: 50px;
	}

	.policy-item {
		justify-content: center;
	}

	.wedding-rings .menAndWomenRingComponent .inner-content h2 {
		font-size: 22px;
	}

	.wedding-rings .menAndWomenRingComponent .inner-content p {
		font-size: 13px;
	}

	.wedding-rings .menAndWomenRingComponent>div img {
		height: 100%;
	}

	.hero-section .hero-content .hero-body .btn-hero {
		font-size: 13px;
		max-width: 200px !important;
		margin: 0 auto;
		display: block;
	}

	.wedding-rings .major-bar {
		padding: 22px 15px;
	}

	.wedding-rings .major-bar a {
		font-size: 14px;
	}

	.wedding-rings .season-label img {
		width: 25px;
		height: 25px;
	}

	.diamond .expand-title {
		font-size: 18px;
	}

	.diamond .expand-color-content p span,
	.diamond .expand-content p span {
		font-size: 16px;
		line-height: 24px;
	}

	.expand-color-content {
		flex-wrap: wrap;
	}

	.diamond .expand-color-content p {
		margin-bottom: 0px;
	}

	.dimensions_div .stone_container {
		padding: 5px 10px;
	}

	.dimensions_div .dimensions-inner .stone_container {
		font-size: 11px;
	}

	.product-details-inner .slick-list {
		margin-left: 0px !important;
	}

	.education-container .education-silder--box .slick-prev {
		left: 0px;
	}

	.education-container .education-silder--box .slick-next {
		right: 0px;
	}

	.mobile-menu-toggler .unnotification--btn i,
	.mobile-menu-toggler .notification--btn i {
		font-size: 20px !important;
	}

	.mobile-main-header .mobile-menu-toggler .notification--btn {
		font-size: 20px !important;
	}

	.mobile-main-header .mobile-menu-toggler .notification--btn::before {
		top: calc(50% - 15px);
		left: calc(50% - 2px);
	}

	.mobile-main-header .mobile-menu-toggler .notification--btn::after {
		top: calc(50% - 16px);
		left: calc(50% - 6px);
	}

	.mobile-menu-toggler .unnotification--btn .notification {
		width: 15px;
		height: 15px;
		line-height: 15px;
		right: -3px;
	}

	.education-container .slick-arrow {
		top: 50% !important;
	}

	.shopping-cart-title {
		justify-content: center;
	}

	.main-jewelry .jewelry-category h2 {
		text-align: center;
	}

	.jewelry-category .slick-next {
		right: -28px !important;
	}

	.feature-container {
		padding: 20px 0px !important;
	}

	.jewellery-gift-by-style {
		margin-top: 50px !important;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.mobile-main-header .mobile-menu-toggler .mobile-menu-btn {
		margin-left: 3px;
	}

	.progress-circle {
		position: fixed;
		top: 83px;
		width: 100%;
	}

	.btn--reset-1 {
		display: none;
	}

	.card-button .gemstone_button {
		min-width: 16%;
	}

	.tab-box .nav {
		margin: 30px 0px;
	}

	.header-configure-area ul li button .notification,
	.mobile-menu-toggler .unnotification--btn .notification {
		top: -3px;

		right: -3px;
	}

	.mobile-main-header .mobile-menu-toggler .notification--btn .notification {
		top: -3px;
		right: -3px;
		font-size: 10px;
	}

	.mobile-main-header .mobile-menu-toggler .notification--btn::before {
		top: calc(50% - 16px);
		left: calc(50% - 2px);
	}

	.mobile-main-header .mobile-menu-toggler .notification--btn::after {
		top: calc(50% - 20px);
		left: calc(50% - 6px);
	}

	.policy-icon {
		padding-right: 12px;
	}

	.main-menu ul li ul.megamenu {
		max-width: 100%;
	}

	.mobile-best-select .mobile-select {
		width: 75%;
	}

	.main-menu ul li:hover ul.megamenu {
		transform: translate(-50%, 17px);
	}

	.mobile-price--box {
		position: absolute;
		bottom: 15px;
		right: 10px;
		margin: 0px;
		line-height: 18px;
		font-size: 14px;
		font-weight: 400;
		padding: 4px 10px;
		background-color: rgba(194, 153, 88, 0.9);
		border-radius: 8px;
		color: #fff;
	}

	.card-container .card .card-img {
		object-fit: cover !important;
		border-radius: 10px;
		width: 290px;
		height: 290px;
	}

	.slider--container .slick-prev,
	.slider--container .slick-next {
		top: -55px;
	}

	.slider--container .slick-prev {
		z-index: 1;
		left: auto;
		right: 75px;
	}

	.mobile-slider--container .slick-prev {
		right: 50px !important;
	}

	.slider--container .slick-next {
		right: 32px;
	}

	.policy-item {
		justify-content: center;
	}

	.mobile-flex-box {
		display: flex;
		align-items: baseline;
		grid-gap: 0px 10px;
	}

	.summary-box,
	.shipping-info-box {
		width: 50%;
	}

	.quantity-counter button {
		width: 24px;
		height: 24px;
		font-size: 16px;
		line-height: 24px;
	}

	.mobile-page-link {
		font-size: 18px !important;
		font-weight: 400;
		text-transform: capitalize;
	}

	.mobile-sidebar.offcanvas {
		top: 0px;
	}

	.mobile-menu-toggler .unnotification--btn,
	.mobile-main-header .mobile-menu-toggler .mini-cart-wrap i,
	.mobile-main-header .mobile-menu-toggler .mini-cart-heart i {
		margin-right: 10px !important;
		font-size: 20px;
	}

	.mobile-main-header .mobile-menu-toggler .mini-cart-wrap .notification,
	.mobile-main-header .mobile-menu-toggler .mini-cart-heart .notification {
		top: -3px;
		left: 8px;
	}

	.shopping-cart-table .shopping-content h3 {
		max-width: 450px;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: initial;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.dimensions_div {
		display: flex !important;
		flex-wrap: wrap;
	}

	.quantity-counter {
		margin-top: 20px;
	}

	.mobile-select-box .css-b62m3t-container {
		width: 100% !important;
	}

	.header-configure-area ul li.user-hover .user-name-div i {
		font-size: 18px;
	}

	.header-configure-area ul li.user-hover .user-name-div i::before {
		line-height: 19px;
	}

	.header-configure-area ul li.user-hover .user-name-div .user-name {
		padding: 4px;
		margin-right: 4px !important;
	}

	.header-configure-area .nav {
		align-items: last baseline !important;
	}

	.header-configure-area ul li a i::before {
		line-height: 19px;
	}

	.main-menu ul li:hover ul.megamenu .submenu-title .subtitle-link {
		font-size: 14px;
		text-transform: capitalize;
	}

	.mobile-menu-flex {
		display: flex;
		align-items: baseline;
		grid-gap: 0px 10px;
	}

	.mobile-submenu {
		width: 50%;
	}

	.education-container .slick-slide {
		padding: 0px 8px;
	}

	.education-container .slick-prev {
		left: auto;
		z-index: 1;
		right: 10%;
	}

	.education-container .slick-next {
		right: 20px;
	}

	.education-container .slick-next,
	.education-container .slick-prev {
		top: 154px;
	}

	.education-container .slick-next::before,
	.education-container .slick-prev::before {
		content: "";
		background-image: url("./image/back.png");
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		width: 20px;
		height: 20px;
		display: block;
	}

	.mobile-setect .css-b62m3t-container {
		width: 100% !important;
	}

	.slider-container .slider-content .slick-next,
	.style-slider-container .style-slider-content .slick-next {
		right: 7% !important;
	}

	.gemstones-page .features-tile .caption a {
		margin-bottom: 30px;
	}

	.shape-container .shape-content .shop-content-link {
		flex-wrap: wrap;
		align-items: first baseline !important;
		justify-content: space-around !important;
	}

	.shape-container .shape-content .shop-content-link li a span {
		font-size: 12px !important;
		width: 70px;
	}

	.hero-section .hero-content .hero-body h1 {
		font-size: 30px !important;
	}

	.hero-section .hero-content .hero-body p,
	.hero-section .hero-content .hero-body {
		text-align: center;
	}

	.complete-ring-select {
		width: 24% !important;
	}

	.hero-section {
		height: 360px !important;
	}

	.list-group-item-heading {
		width: 730px !important;
	}

	.product-details-inner .slick-list {
		margin-left: 0px !important;
	}

	.mainSlider .heroSliderImage .slide-title,
	.hero-section--banner .slide-title,
	.mainSlider .heroSliderImage_2 .slide-title,
	.mainSlider .heroSliderImage_1 .slide-title {
		font-size: 30px;
	}

	.mainSlider .heroSliderImage .slide-desc,
	.mainSlider .heroSliderImage_2 .slide-desc,
	.hero-section--banner .slide-desc,
	.mainSlider .heroSliderImage_1 .slide-desc {
		font-size: 18px;
	}

	.hero-section--banner .title--box {
		width: 500px;
	}

	.weddingRings-static---banner .wedding-static--img {
		height: 400px;
	}

	.style-slider-container .style-slider-content .slider-inner-content .slider-content-link img {
		height: 200px;
	}

	.education-container .education-silder--box .slick-prev {
		left: -14px;
	}

	.education-container .education-silder--box .slick-next {
		right: -22px;
	}

	.shopping-cart-title {
		justify-content: center;
	}

	.tab-content-slider .slick-prev {
		left: -35px;
	}

	.tab-content-slider .slick-next {
		right: -35px;
	}

	.custom--padding-box {
		padding: 20px 0px 0px 0px;
	}

	.banner-content .banner-text1 {
		font-size: 23px;
	}

	.section-padding {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	.notification-canvas {
		top: 0px !important;
	}
}

@media only screen and (max-width: 767.98px),
only screen and (min-width: 768px) and (max-width: 991px) {
	.cart-update-option .apply-coupon-wrapper {
		width: auto;
	}

	.gifts-page .jewelry-gifts--slider .slick-next,
	.gifts-page .jewelry-gifts-by--slider .slick-next,
	.gifts-page .popular-jewelry-gifts--slider .slick-next {
		right: 17%;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767.98px) {
	.contact-area {
		margin-bottom: -5px;
	}

	.product-details-des.box-layout {
		margin-top: 0;
	}

	.product-details-des.box-layout p {
		max-width: 100%;
		padding: 0 15px;
	}

	.product-details-des.sidebar-sticky {
		margin-top: 0;
	}

	.about-content {
		text-align: center;
		padding: 52px 30px 0;
		margin-bottom: -6px;
	}

	.footer-top [class*="col-"] .widget-item {
		margin-bottom: 30px;
	}

	.about-content {
		text-align: center;
		padding: 52px 30px 0;
		margin-bottom: -6px;
	}

	.filter-tabs .tabs-btn-box {
		margin: auto;
	}

	.filter-tabs::after {
		transform: translate(-50%, 0px);
	}

	.hero-section {
		height: 330px !important;
	}

	.slider-container .slider-content .slick-next,
	.style-slider-container .style-slider-content .slick-next {
		right: 10% !important;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
	.header-configure-area ul li {
		margin-left: 14px;
	}
}

@media only screen and (min-width: 1200px),
only screen and (min-width: 992px) and (max-width: 1199px) {
	.cart-update-option {
		margin-top: 0;
		border-top: 0 solid transparent;
	}

	.breadcrumb_main_div h2 {
		font-size: 40px;
		line-height: 1.2;
	}
}

@media only screen and (min-width: 768px) {
	.imitate-table-up>div {
		display: table-cell;
		vertical-align: middle;
		float: none;
	}

	.breadcrumb_main_div .container-i2x {
		max-width: 540px;
	}

	.imitate-table-up>div {
		display: table-cell;
		vertical-align: middle;
		float: none;
	}

	.mobile-main-header .mobile-menu-toggler .notification--btn,
	.mobile-main-header .mobile-menu-toggler .unnotification--btn {
		position: relative;
		font-size: 22px;
		margin-right: 10px !important;
		color: var(--text-color);
		line-height: 1;
	}

	.mobile-main-header .mobile-menu-toggler .mini-cart-heart {
		line-height: 1;
		margin-left: 0px;
	}
}

@media only screen and (max-width: 576px) {

	.gifts-page .jewelry-gifts--slider .slick-next,
	.gifts-page .jewelry-gifts-by--slider .slick-next,
	.gifts-page .popular-jewelry-gifts--slider .slick-next {
		right: 14%;
	}

	.breadcrumb_main_div .container-i2x {
		max-width: 540px;
	}

	.diamond .expand-title {
		font-size: 18px;
	}

	.diamond .expand-color-content p span {
		font-size: 16px;
	}

	.expand-color-content {
		flex-wrap: wrap;
		grid-gap: 5px;
	}

	.diamond .expand-color-content p {
		margin-bottom: 0px;
	}

	.rdt_ExpanderRow .btn-zdr {
		padding: 12px;
		font-size: 12px;
	}

	.filter-tabs .tabs-btn-box {
		flex-wrap: wrap;
		flex-direction: column;
		border-radius: 8px;
		margin: 0;
		width: 100%;
	}

	.filter-tabs .tabs-btn-box button {
		border-radius: 8px;
	}

	.filter-tabs .tabs-btn-box button:nth-last-child(2) {
		margin: 10px 0px;
	}

	.shape-box {
		flex-wrap: wrap;
	}

	.round-filter-btn {
		position: absolute;
		top: 60px;
		right: 0px;
		z-index: 12;
	}

	.filter-tabs::after {
		transform: translate(0, 0);
		left: 0px;
		width: 100%;
	}

	.stepping-bar .ir346-cyo__con .avenir-medium,
	.stepping-bar .ir346-cyo__con .ir346-cyo__link,
	.stepping-bar .ir346-cyo__info .ir346-cyo__product {
		font-size: 10px;
	}

	.stepping-bar .ir346-cyo__info {
		padding: 0 !important;
	}
}

@media only screen and (min-width: 992px) {
	.breadcrumb_main_div .container-i2x {
		max-width: 960px;
	}

	.breadcrumb_main_div h2 {
		font-size: 40px;
		line-height: 1.2;
	}

	.col-bg1 {
		width: 100%;
	}


}

@media only screen and (max-width: 992px) {
	.feature-card .caption {
		position: initial !important;
		text-align: center;
		margin: 0 auto;
	}

	.feature-card .caption p {
		max-height: initial !important;
		display: block !important;
	}

	.feature-card .feature-btn a {
		margin: 0 auto;
	}

	.feature-card .caption h2 {
		color: var(--theme-yellow) !important;
	}

	.feature-card .caption p {
		color: var(--theme-gray-color) !important;
	}

	.feature-card .bg-layer-banner {
		display: none !important;
	}

}

@media only screen and (min-width: 1200px) {
	.breadcrumb_main_div .container-i2x {
		max-width: 1140px;
	}

	.breadcrumb_main_div .col-5xs {
		width: 100%;
	}

	.breadcrumb_main_div h2 {
		font-size: 48px;
		line-height: 1.2;
	}
}

@media only screen and (min-width: 1400px) {
	.breadcrumb_main_div .container-i2x {
		max-width: 1320px;
	}

	.mobile-best-select .mobile-select {
		width: 75%;
	}
}

@media (min-width: 1200px) and (max-width: 1399px) {
	.mobile-price--box {
		position: absolute;
		bottom: 15px;
		right: 10px;
		margin: 0px;
		line-height: 18px;
		font-size: 14px;
		font-weight: 400;
		padding: 4px 10px;
		background-color: rgba(194, 153, 88, 0.9);
		border-radius: 8px;
		color: #fff;
	}

	.list-group-item-heading {
		width: 350px !important;
	}
}

@media (min-width: 1280px) and (max-width: 1440px) {
	.main-menu ul li a {
		font-size: 11px;
	}

	.header-configure-area {
		padding-right: 0px;
	}
}

@media only screen and (min-width: 1600px) {
	.sticky.is-sticky {
		padding: 0 85px;
	}
}

@media (min-width: 992px) and (max-width: 1280px) {
	.main-menu ul li a {
		font-size: 10px;
		padding: 10px;
	}

	.header-configure-area {
		padding-right: 0px;
	}

	.header-area {
		position: sticky;
		top: 0px;
		z-index: 999;
		background-color: #ffffff;
	}
}

@media (min-width: 1200px) and (max-width: 1223px) {
	.header-configure-area ul li.user-hover .user-name-div .user-name {
		font-size: 10px;
		white-space: nowrap;
	}
}

@media (min-width: 320px) and (max-width: 480px) {
	.engagement--banner .engagement-inner--img {
		height: 350px;
	}

	.complete-ring-select {
		width: 65% !important;
	}

	.shape-container {
		margin-bottom: 50px;
	}

	.wedding-rings .menAndWomenRingComponent {
		padding: 20px 0px;
	}

	.cut--page .cut-inner--page img {
		width: 50% !important;
		height: 50%;
	}

	.cut--page h4,
	.details--page h4 {
		font-size: 16px;
		margin-bottom: 15px;
	}

	.ready-to-ship {
		height: 316px;
	}

	.engagement-ring .row-bg .home-cyo-box {
		padding: 20px 8% 30px;
	}

	.engagement-ring .row-bg .home-cyo-box h2 {
		font-size: 22px;
	}

	.engagement-ring .row-bg .home-cyo-box p {
		font-size: 13px;
	}

	.gifts-page .own-earring-and-necklace .caption {
		max-width: 100%;
		width: 100%;
		left: 0;
		bottom: 0;
		padding: 15px;
	}

	.btn--reset-1 {
		display: none;
	}

	.progress-circle {
		position: fixed;
		top: 82px;
		width: 100%;
	}

	.mobile-menu-toggler .unnotification--btn {
		margin-right: 10px;
	}

	.card-button .gemstone_button {
		padding: 7px 20px !important;
		margin-bottom: 0px !important;
	}

	.card-button button span {
		font-size: 10px;
	}

	.order-details .order-details-box ul li {
		width: 50% !important;
		margin-left: 0 !important;
		font-size: 12px !important;
		padding: 5px 0;
	}

	.order-details .order-details-box ul li span {
		font-size: 12px !important;
		margin-top: 0 !important;
	}

	.order-details .order-details-box ul li:nth-child(3) {
		border-left: 0 !important;
	}

	.order-details .order-details-box ul li:nth-child(1),
	.order-details .order-details-box ul li:nth-child(2) {
		border-bottom: 1.5px dashed var(--theme-yellow);
	}

	.mobile-price--box {
		display: block !important;
		position: absolute;
		bottom: 15px;
		right: 10px;
		margin: 0px;
		line-height: 18px;
		font-size: 14px;
		font-weight: 400;
		padding: 4px 10px;
		background-color: rgba(194, 153, 88, 0.9);
		border-radius: 8px;
		color: #fff;
	}

	.shopping-cart-title {
		flex-wrap: wrap;
	}

	.shopping-cart-table .shopping-content h3 {
		max-width: fit-content;
		font-size: 12px;
		width: 80%;
	}

	.shopping-cart-table .shopping-content .shopping-inner-content span,
	.shopping-cart-table .shopping-content .shopping-diamond-inner span {
		font-size: 12px;
	}

	.mobile-cart-text {
		flex-wrap: wrap;
		flex-direction: column;
		align-items: flex-start !important;
	}

	.shopping-cart-table .shopping-content p {
		font-size: 16px;
	}

	.shopping-cart-table .shopping-image a:nth-child(1) {
		max-width: 100%;
		margin-bottom: 10px;
	}

	.shopping-cart-table .shopping-image span {
		width: 100% !important;
	}

	.notification-canvas {
		top: 80px !important;
	}

	.shopping-cart-table .shopping-content {
		background: rgba(194, 153, 88, 0.1);
		border-radius: 8px;
	}

	.summary-box .need-contact-content {
		flex-wrap: wrap;
	}

	.shopping-cart-table .shopping-image a img {
		display: block;
		margin: auto;
	}

	.slider--container .slick-next,
	.slider--container .slick-prev {
		top: 134px !important;
	}

	.education-container .education-silder--box .slick-prev {
		left: 12px;
	}

	.education-container .education-silder--box .slick-next {
		right: -44px;
	}

	.empty-page-content .button {
		font-size: 11px;
	}

	.metal-box .matel-container {
		font-size: 12px;
	}

	.lcOxyP {
		width: 100% !important;
	}

	.shopping-cart-title h3 {
		width: 100%;
		font-size: 20px;
		text-align: center;
	}

	.shopping-cart-title>div {
		width: 100%;
		justify-content: center;
		margin-top: 15px;
	}

	.mobile-select-box {
		justify-content: center !important;
	}

	.mobile-select-box .css-b62m3t-container,
	.mobile-select .css-b62m3t-container {
		width: 100% !important;
	}

	.tab-content-slider .slick-next {
		right: 0px;
	}

	.tab-content-slider .slick-prev {
		left: 0px;
		z-index: 1;
	}

	.education-container .slick-slide {
		padding: 0px 4px;
	}

	.education-container .slick-prev {
		left: auto;
		z-index: 1;
		right: 20%;
	}

	.education-container .slick-next {
		right: 20px !important;
	}

	.education-container .slick-next,
	.education-container .slick-prev {
		top: -25px !important;
	}

	.education-container .education-silder--box .slick-arrow {
		width: 30px;
		height: 30px;
	}

	.education-container .education-silder--box .slick-prev::before {
		width: 14px;
		height: 14px;
	}

	/* .mobile-main-header .mobile-menu-toggler .notification--btn {
		color: var(--text-color);
		font-size: 20px;
		margin-right: 11px;
	} */

	.education-container .education-silder--box .slick-next::before {
		width: 14px;
		height: 14px;
	}

	.education-container .slick-next::before,
	.education-container .slick-prev::before {
		content: "";
		background-image: url("./image/back.png");
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		width: 20px;
		height: 20px;
		display: block;
	}

	.mobile-setect {
		justify-content: center !important;
	}

	.gemstones-page .features-tile .shop-by-color header button,
	.shop-by-style .shop-by-color header button {
		margin-right: 0px;
		font-size: 14px;
	}

	.gemstones-page .features-tile .shop-by-color header h2,
	.shop-by-style .shop-by-color header h2 {
		font-size: 22px;
	}

	.gemstones-page .features-tile .caption a {
		font-size: 14px;
		margin-bottom: 20px;
	}

	.faq-page h1 {
		font-size: 22px;
	}

	.faq-page .faq-details h3 {
		font-size: 20px;
	}

	.faq-page .faq-details p span {
		font-size: 14px;
	}

	.faq-page .faq-details p {
		font-size: 13px;
	}

	.blog-details-page .blog-img-box img {
		height: 250px;
		object-fit: contain;
	}

	.blog-details-page .mobile-width {
		width: 100% !important;
	}

	.blog-page .blog-right-content,
	.blog-page .blog-categories {
		margin-top: 15px;
	}

	.custom--padding-box {
		padding: 0px;
		margin-top: 30px;
	}

	.diamond-static-img--banner .home-cyo-box {
		padding: 10px;
		margin-top: 30px;
	}

	.feature-container {
		padding: 25px 0px !important;
	}

	.feature-container .feature-card .heroButton--slider {
		margin: auto;
	}

	.my-account-wrapper .tab-content {
		margin-top: 50px;
	}

	.ready-to-ship .ready-to-ship---img {
		width: 100% !important;
	}

	.ready-to-ship .minimalist-collection-full-tile {
		background-color: transparent !important;
		top: 30%;
	}

	.ready-to-ship {
		padding: 10% 0px;
		margin-top: 100px !important;
	}

	.minimalist-collection-full-tile .herosection--btn {
		margin: auto;
	}

	.ready-to-ship .minimalist-collection-full-tile {
		padding: 0px;
	}

	.diamond-static-img--banner .diamond-static--img {
		object-fit: contain;
		height: 350px;
	}

	.feature-container .feature-card a.img--box img {
		object-fit: cover !important;
		height: 350px !important;
	}

	.style-slider-container .style-slider-content .slider-inner-content .slider-content-link img {
		height: 345px !important;
	}

	.jewelry-category .slick-prev {
		left: 0px;
	}

	.jewelry-category .slick-next {
		right: 0px;
	}

	.jwellery-section {
		margin-top: 80px !important;
	}

	.container .slider-title-context {
		justify-content: center;
		align-items: center !important;
	}

	.main-jewelry .jewelry-category h2 {
		text-align: center;
	}

	.gifts-page .top-jewelry--gifts {
		padding-top: 120px;
		padding-bottom: 112px;
	}

	.top-jewelry--gifts .jewelry-gifts--slider p {
		text-align: center;
		margin-top: 10px;
	}

	.gifts-page .shop-by--occasion {
		margin-top: 80px;
	}

	.hero-section--banner .slide-title {
		font-size: 30px;
	}

	.style-slider-container .style-slider-content .slider-title-context h2 {
		font-size: 22px !important;
	}

	.ready-to-ship {
		padding: 0px 0px !important;
	}

	.cut--page .cut-link--box p {
		font-size: 12px;
		display: flex !important;
		flex-direction: column;
	}
}

@media (min-width: 481px) and (max-width: 991px) {
	.mobile-price--box {
		display: block !important;
		position: absolute;
		bottom: 15px;
		right: 10px;
		margin: 0px;
		line-height: 18px;
		font-size: 14px;
		font-weight: 400;
		padding: 4px 10px;
		background-color: rgba(194, 153, 88, 0.9);
		border-radius: 8px;
		color: #fff;
	}

	.feature-container .feature-card a.img--box img {
		height: 360px;
	}

	.ready-to-ship .ready-to-ship---img {
		position: relative;
		height: 360px;
	}

	.ready-to-ship {
		padding-bottom: 17%;
		margin: 0px !important;
	}

	.mainSlider .heroSliderImage .slide-title,
	.hero-section--banner .slide-title,
	.mainSlider .heroSliderImage_2 .slide-title,
	.mainSlider .heroSliderImage_1 .slide-title {
		font-size: 32px;
	}

	.mainSlider .heroSliderImage .slide-desc,
	.mainSlider .heroSliderImage_2 .slide-desc,
	.hero-section--banner .slide-desc,
	.mainSlider .heroSliderImage_1 .slide-desc {
		font-size: 18px;
	}

	.engagement--banner .engagement-inner--img,
	.diamond-static-img--banner .diamond-static--img {
		height: 400px;
		object-fit: cover;
	}

	.our-commitment-tile .showroom-text {
		padding: 20px;
	}

	.jewelry-category .slick-prev,
	.tab-content-slider .slick-prev {
		left: 5px;
	}

	.jewelry-category .slick-next,
	.tab-content-slider .slick-next {
		right: 5px;
	}
}

@media (min-width: 481px) and (max-width: 768px) {
	.mobile-main-header .mobile-menu-toggler .mini-cart-wrap {
		margin-right: 10px !important;
	}

	.mobile-main-header .mobile-menu-toggler .mobile-menu-btn {
		margin-left: 10px;
	}

	.slider--container .slick-prev {
		left: auto;
		right: 6%;
		z-index: 1;
	}

	.slider--container .slick-next {
		right: 0px;
	}

	.slider--container .slick-next,
	.slider--container .slick-prev {
		top: -55px;
	}

	.card-container .card .card-img {
		object-fit: cover !important;
		border-radius: 8px;
	}

	.shopping-cart-table .shopping-content h3 {
		max-width: 320px;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: initial;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.metal-box .matel-container {
		font-size: 11px;
	}

	.mobile-flex-box {
		display: flex;
		align-items: baseline;
		grid-gap: 0px 10px;
	}

	.mobile-flex-box .summary-box,
	.mobile-flex-box .shipping-info-box {
		width: 50%;
	}

	.quantity-counter {
		margin-top: 20px;
	}

	.mobile-select-box .css-b62m3t-container,
	.mobile-setect .css-b62m3t-container {
		width: 100% !important;
	}

	.lookbook-content .item .item-lookbook:hover .content-lookbook {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 8px;
	}

	.lookbook-content .item .item-lookbook .content-lookbook .item-thumb {
		width: fit-content;
	}

	.lookbook-content .item .item-lookbook .content-lookbook .item-thumb img {
		margin-left: 0px;
		display: block;
		height: 60px;
		min-width: 60px !important;
		border-radius: 8px 0 0 8px;
	}

	.lookbook-content .item .item-lookbook .content-lookbook-bottom .item-title a {
		display: block;
		font-size: 15px;
	}

	.lookbook-content .item .item-lookbook .content-lookbook {
		left: -65px;
		bottom: 50px;
	}

	.mobile-slider--container .slick-prev {
		right: 12%;
	}

	.education-container .slick-slide {
		padding: 0px 8px;
	}

	.education-container .slick-prev {
		left: auto;
		z-index: 1;
		right: 10%;
	}

	.education-container .slick-next {
		right: 20px;
	}

	.education-container .slick-next,
	.education-container .slick-prev {
		top: -66px;
	}

	.education-container .slick-next::before,
	.education-container .slick-prev::before {
		content: "";
		background-image: url("./image/back.png");
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		width: 20px;
		height: 20px;
		display: block;
	}

	.rageslider-input input {
		width: 40%;
	}

	.gemstones-page .features-tile .caption a {
		margin-bottom: 20px;
	}

	.gemstones-page .slick-dots {
		position: relative;
		top: 0;
	}

	.gemstones-page .features-tile .shop-by-color header button,
	.shop-by-style .shop-by-color header button {
		margin-right: 0px;
	}

	.gemstones-page {
		padding-top: 0px !important;
	}

	.hero-section .hero-content .hero-body {
		padding-right: 0px !important;
	}

	.hero-section .hero-content {
		max-width: 400px;
	}

	.lcOxyP {
		width: 100% !important;
	}

	.faq-page .faq-details h3 {
		font-size: 20px;
	}

	.faq-page .faq-details p span {
		font-size: 14px;
	}

	.faq-page .faq-details p {
		font-size: 13px;
	}

	.cut--page .cut-inner--page img {
		width: 100%;
		height: auto;
	}

	.cut--page h4,
	.details--page h4 {
		font-size: 15px;
	}

	.cut--page p,
	.details--page p,
	.details--page ol li {
		font-size: 13px !important;
	}

	.product-area .product-thumb {
		width: 100%;
		height: 100%;
	}

	.about-us .explore-inner-wrapper .education--link-box {
		width: 100% !important;
		height: 150px;
		margin-bottom: 12px;
	}

	.order-delivery--box {
		max-width: 100% !important;
	}

	.mobile-main-header .mobile-menu-toggler .mini-cart-wrap i,
	.mobile-main-header .mobile-menu-toggler .mini-cart-heart i,
	.mobile-menu-toggler .unnotification--btn i {
		font-size: 18px;
	}

	.header-configure-area ul li button .notification,
	.mobile-menu-toggler .unnotification--btn .notification {
		top: -3px;
	}

	.mobile-main-header .mobile-menu-toggler .mini-cart-wrap i::before,
	.mobile-main-header .mobile-menu-toggler .mini-cart-heart i::before,
	.mobile-menu-toggler .unnotification--btn i::before {
		line-height: 21px;
	}

	.mobile-main-header .mobile-menu-toggler .mini-cart-wrap .notification,
	.mobile-main-header .mobile-menu-toggler .mini-cart-heart .notification {
		top: -4px;
	}

	.section-padding {
		padding-top: 50px;
		padding-bottom: 50px;
	}
}

@media (min-width: 320px) and (max-width: 400px) {
	.hero-section--banner .slide-title {
		font-size: 30px;
		line-height: 30px;
	}

	.engagement--banner .engagement-inner--img,
	.diamond-static-img--banner .diamond-static--img,
	.ready-to-ship .ready-to-ship---img {
		height: 100%;
		object-fit: cover;
		border-radius: 0px;
		width: 100% !important;
	}

	.feature-container .feature-card a.img--box img {
		height: 300px !important;
		object-fit: cover;
		border-radius: 0px;
		width: 100%;
	}

	.feature-card--slider {
		margin-bottom: 40px;
	}

	.feature-card--slider .slick-dots {
		bottom: -41px;
	}

	.feature-card--slider .slick-next {
		bottom: -51px;
	}

	.feature-card--slider .slick-prev {
		bottom: -36px;
	}

	.ready-to-ship .ready-to-ship---img {
		position: relative;
	}

	.slider-container .slider-content .slider-title-context h2,
	.style-slider-container .style-slider-content .slider-title-context h2 {
		width: 100% !important;
		text-align: center !important;
	}

	.card-container .card .card-content .price--box {
		inset: auto auto auto auto !important;
	}

	.gifts-page .jewelry-gifts--slider .slick-prev,
	.gifts-page .jewelry-gifts-by--slider .slick-prev,
	.gifts-page .popular-jewelry-gifts--slider .slick-prev {
		right: 9%;
	}

	.gifts-page .jewelry-gifts--slider .slick-next,
	.gifts-page .jewelry-gifts-by--slider .slick-next,
	.gifts-page .popular-jewelry-gifts--slider .slick-next {
		right: 3%;
	}

	.gifts-page .top-jewelry--gifts .title-content h3,
	.gifts-page .jewelry-gifts-by--recipient .title-content h3,
	.gifts-page .popular-jewelry--gifts .title-content h3,
	.gifts-page .own-earring-and-necklace .caption h3,
	.gifts-page .jewelry-gifts--meaning .title-content h3,
	.gifts-page .more-way-to--shop .title-content h3 {
		font-size: 22px;
	}

	.mobile-price--box {
		display: block !important;
		position: absolute;
		bottom: 10px;
		right: 10px;
		margin: 0px;
		line-height: 18px;
		font-size: 14px;
		font-weight: 400;
		padding: 4px 10px;
		background-color: rgba(194, 153, 88, 0.9);
		border-radius: 4px;
		color: #fff;
	}

	.card-container .card .card-img {
		object-fit: cover !important;
		border-radius: 8px;
	}

	.mobile-slider--container .slick-prev {
		right: 15%;
	}

	.tab-content-heading {
		margin-left: 15px !important;
		margin-right: 15px !important;
	}

	.rageslider-input input {
		width: 40% !important;
	}

	.rc-slider-mark-text {
		font-size: 10px;
	}

	.ready-to-ship .minimalist-collection-full-tile p,
	.ready-to-ship .minimalist-collection-full-tile h3 {
		text-align: center;
	}

	.ready-to-ship .minimalist-collection-full-tile p {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.ready-to-ship .minimalist-collection-full-tile .btn--custom {
		margin: auto;
		max-width: 280px;
	}

	.blog-page .last-post .row .col-xl-4:nth-last-child(2) {
		margin-bottom: 12px;
	}

	.mobile-main-header .mobile-menu-toggler .mini-cart-heart a {
		display: block;
	}

	.mobile-menu-toggler .unnotification--btn .notification {
		top: -10px;
	}

	.mobile-main-header .mobile-menu-toggler .mini-cart-wrap i,
	.mobile-main-header .mobile-menu-toggler .mini-cart-heart i,
	.mobile-menu-toggler .unnotification--btn i {
		font-size: 18px;
		display: block;
	}

	.mobile-main-header .mobile-menu-toggler .mini-cart-wrap i::before,
	.mobile-main-header .mobile-menu-toggler .mini-cart-heart i::before,
	.mobile-menu-toggler .unnotification--btn i::before {
		line-height: 21px;
	}

	.mobile-main-header .mobile-menu-toggler .mini-cart-wrap .notification,
	.mobile-main-header .mobile-menu-toggler .mini-cart-heart .notification {
		top: -9px;
	}

	.ring_main_div {
		padding-top: 50px;
	}

	.banner-statistics-area .slick-arrow {
		background-color: #c29958;
	}

	.banner-statistics-area .slick-prev {
		right: 16%;
	}

	.product-caption {
		border-radius: 0px 0px 8px 8px;
		padding: 14px 0px;
		margin-top: 33px;
	}

	.product-caption .product-name a,
	.product-caption .product-name a:hover,
	.price-regular {
		color: var(--theme-yellow);
		font-weight: 500;
	}

	.footer-bottom .copy-wright {
		font-size: 12px;
		padding: 4px 20px;
	}

	.product-thumb {
		width: 100%;
		height: 272px;
	}

	.cut--page p {
		font-size: 12px;
	}

	.cut--page .cut-link--box p {
		flex-wrap: wrap;
	}

	.order-details .mobile-address--data {
		flex-wrap: wrap;
	}

	.order-details .order-details-box {
		max-width: 100% !important;
		margin-right: 0px !important;
	}

	.mobile-menu-toggler .unnotification--btn {
		margin-right: 10px;
	}

	.details--page p,
	.details--page ol li {
		font-size: 12px;
	}

	.ready-to-ship--slider {
		height: 400px;
		margin: 50px 0px;
	}

	.ready-to-ship--slider .slick-slide,
	.ready-to-ship--slider .slick-track,
	.ready-to-ship--slider .slick-list {
		height: 100%;
	}

	.ready-to-ship {
		padding-top: 0px;
		padding-bottom: 0px;
		height: 100%;
	}

	.ready-to-ship .minimalist-collection-full-tile {
		left: 0px;
		padding-right: 0px;
		width: 100%;
	}

	.feature-container .feature-card .caption p {
		-webkit-line-clamp: 2;
		margin: 10px 0px;
	}

	.banner-engagement--slider .slick-dots {
		bottom: -42px;
	}

	.banner-engagement--slider .slick-next {
		bottom: -52px;
	}

	.banner-engagement--slider .slick-prev {
		bottom: -37px;
	}

	.feature-container {
		padding: 0px !important;
	}

	.wedding-rings .menAndWomenRingComponent .inner-content h2 {
		font-size: 20px;
	}

	.responsible-box .container {
		padding: 24px !important;
	}

	.engagement-ring .slider-content--text,
	.diamond-page--slider .slider-content--text {
		max-width: 250px !important;
		transform: translate(-4%, -50%);
	}

	.hero-section--banner {
		height: 450px !important;
	}

	.feature-container .feature-card .caption h2,
	.feature-container .feature-card .caption p {
		box-shadow: none !important;
	}

	.diamond-static-img--banner .home-cyo-box h2 {
		font-size: 22px;
		margin-top: 25px;
	}

	.feature-container .feature-card .feature-btn .heroButton--slider {
		margin: auto;
	}

	.feature-container .feature-card .caption p {
		margin: 0px;
	}

	.feature-container .feature-card .caption {
		margin: 10px 0px;
	}

	.feature-container .feature-card .caption h2 {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		max-height: 70px;
	}

	.jewelry-category .slick-prev {
		left: -9px;
	}

	.jewelry-category .slick-arrow {
		background: var(--theme-yellow);
	}

	.jewelry-category .slick-next {
		right: -9px;
	}

	.main-jewelry .jewelry-category h2 {
		font-size: 22px;
		text-align: center;
	}

	.jwellery-section {
		margin-top: 80px !important;
	}

	.product-caption .product-name a {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box !important;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		height: 40px !important;
		line-height: 20px;
		color: var(--black);
	}

	.engagement-ring .row-bg .home-cyo-box h2,
	.ready-to-ship .minimalist-collection-full-tile h3 {
		font-size: 20px !important;
	}

	.engagement-ring .row-bg .home-cyo-box p {
		font-size: 15px;
	}

	.container .style-slider-content .slider-title-context h2 {
		font-size: 20px !important;
	}

	.ready-to-ship {
		padding: 20px 0px !important;
	}

	.cut--page .cut-inner--page {
		padding: 22px !important;
	}
}

@media (min-width: 375px) {
	.image-container .slider-img {
		height: 300px;
	}
}

@media (min-width: 425px) {
	.image-container .slider-img {
		height: 300px;
	}
}

@media (min-width: 1200px) {
	.image-container {
		height: 90% !important;
		width: 90% !important;
		display: block !important;
		margin: auto;
	}

	.image-container .slider-img {
		height: 500px !important;
		object-fit: cover;
	}

	.image-container .slider-img img {
		height: 100%;
		width: 100%;
	}

	.product-details-inner .slick-list {
		margin-left: 0px !important;
	}
}

@media (min-width: 1024px) {
	.product-details-inner .slick-dots {
		position: relative;
		flex-direction: unset;
		justify-content: center;
		bottom: 0;
		width: 100%;
	}

	.product-details-inner .slick-dots li {
		margin-bottom: 0px;
	}
}

@media (min-width: 1440px) {
	.product-details-inner .slick-dots {
		position: relative;
		flex-direction: unset;
		justify-content: center;
		bottom: 0;
		width: 100%;
	}

	.product-details-inner .slick-dots li {
		margin-bottom: 0px;
	}
}

@media (min-width: 320px) {
	.image-container .slider-img {
		height: 300px;
	}
}

@media only screen and (max-width: 767.98px) {

	.slider-container .slider-content .slick-next,
	.style-slider-container .style-slider-content .slick-next {
		right: 15% !important;
	}

	.blog-page .last-post a:hover p {
		transform: scale(1);
	}

	.diamond .expand-title {
		font-size: 16px;
	}

	.diamond .expand-content p,
	.diamond .expand-content p span,
	.diamond .expand-color-content p span,
	.diamond .expand-color-content p {
		font-size: 14px;
		line-height: 22px;
	}

	.panel-kqf .accordion-button,
	.panel-kqf .accordion-button:not(.collapsed) {
		font-size: 14px !important;
	}

	.image-container {
		height: 400px !important;
		width: 400px !important;
	}

	.image-container .slider-img {
		height: 400px !important;
	}

	.product-details-inner .slick-list {
		margin-left: 0 !important;
	}

	.product-details-inner .slick-dots {
		position: relative;
		bottom: 0px;
		width: 100%;
		flex-direction: unset;
		justify-content: center;
		margin-top: 15px;
	}

	.list-group-item-heading {
		width: 330px !important;
	}

	.stepping-bar .stepping-bar--inner {
		flex: 0 0 51% !important;
	}

	.stepping-bar .stepping-bar--inner .stepping-bar-span--text {
		font-size: 14px !important;
	}

	.stepping-bar .stepping-bar--inner .stepping-bar-link--text {
		font-size: 12px !important;
		justify-content: center;
	}

	.order-details .mobile-address--data {
		flex-wrap: wrap;
	}

	.order-details .order-details-box {
		max-width: 100% !important;
		margin-right: 0px !important;
	}
}

@media (max-width: 991px) {
	.diamond-ring-content {
		margin-top: 15px;
	}

	.stepping-bar .ir346-cyo__con {
		flex: 0 0 50% !important;
	}

	.slider--container .slick-prev,
	.slider--container .slick-next {
		top: -55px;
	}

	.slider--container .slick-prev {
		z-index: 1;
		left: auto;
		right: 75px;
	}

	.mobile-slider--container .slick-prev {
		right: 50px !important;
	}

	.slider--container .slick-next {
		right: 32px;
	}

	.stepping-bar .stepping-bar--inner .stepping-bar-content-inner--text {
		max-width: 100% !important;
	}

	.stepping-bar .stepping-bar--inner .stepping-bar-span--text {
		font-size: 14px !important;
	}

	.search--box-wrapper {
		margin-left: 0px !important;
	}

	.search--box-wrapper .search--data-box {
		top: 155px !important;
	}
}

@media (min-width: 768px) and (max-width: 900px) {
	.diamond .expand-content p span {
		font-size: 16px;
		line-height: 24px;
	}

	.complete-ring-select {
		width: 30% !important;
	}

	.progress-circle {
		position: fixed;
		top: 83px;
		width: 100%;
	}

	.notification-canvas {
		top: 80px !important;
	}

	.my-account-wrapper .myaccount-content h5 {
		margin: 15px 0px;
	}

	.tab-box .nav {
		margin-bottom: 35px;
	}

	.jewelry-category .slick-prev,
	.tab-content-slider .slick-prev {
		left: -28px;
	}

	.tab-content-slider .slick-next {
		right: -28px;
	}

	.diamond-static-img--banner .home-cyo-box {
		padding: 44px 10px;
	}

	.engagement-ring .ring-style-container {
		height: 200px;
		margin-top: 25px !important;
	}

	.ready-to-ship .ready-to-ship---img {
		width: 100% !important;
	}

	.feature-container .feature-card .caption {
		bottom: 208px;
	}

	.ready-to-ship .minimalist-collection-full-tile {
		top: 65% !important;
		left: 50% !important;
		background-color: transparent !important;
	}

	.blog-page .last-post p {
		white-space: normal;
	}

	.diamond-page .transformational-tech {
		margin-top: 125px !important;
	}

	.gemstone-color {
		margin-top: 20px !important;
	}

	.ready-to-ship {
		height: 500px;
	}

	.card-container .react-card-image-div img {
		width: 100%;
		height: 77%;
	}
}

@media (min-width: 900px) and (max-width: 1100px) {
	.feature-container .feature-card .caption {
		bottom: 150px !important;
	}

	.cut--page h4,
	.details--page h4 {
		font-size: 20px;
	}

	.cut--page p,
	.details--page p,
	.details--page ol li {
		font-size: 14px;
	}

	.cut--page .cut-inner--page img {
		max-width: 200px;
		height: 200px;
	}

	.about-us .about-banner-engagement--slider .slick-dots {
		width: 100%;
	}

	.about-us .about-us--banner-slider .about-content--card {
		transform: translate(10%, -50%);
	}

	.about-us .content-box h3 {
		font-size: 30px;
	}

	.about-us .content-box h3::after {
		top: 40px;
	}

	.about-us .about-banner-engagement--slider .slick-prev {
		left: 36%;
	}

	.about-us .about-banner-engagement--slider .slick-next {
		right: 36%;
	}

	.search--box-wrapper {
		margin-left: 0px !important;
	}

	.search--box-wrapper .search--data-box {
		top: 73px !important;
	}

	.jewelry-category .slick-prev {
		left: -39px;
	}

	.jewelry-category .slick-next {
		right: -41px;
	}
}

@media (min-width: 1200px) and (max-width: 1440px) {
	.header-configure-area .nav {
		flex-wrap: nowrap;
		align-items: center !important;
	}

	.about-us .content-box h3 {
		font-size: 30px;
	}

	.about-us .about-banner-engagement--slider .slick-dots {
		width: 100%;
	}

	.about-us .about-banner-engagement--slider .slick-next {
		right: 40%;
	}

	.about-us .about-banner-engagement--slider .slick-prev {
		left: 40%;
	}

	.about-us .content-box h3::after {
		top: 40px;
	}
}

@media (max-width: 1380px) {
	.lookbook-content .item .item-lookbook {
		display: none;
	}
}

@media (max-width: 991px) and (min-width: 475px) {
	.feature-container .feature-card {
		height: 560px !important;
		margin: 8px 0;
	}

	.feature-container .feature-card img {
		height: 100%;
		object-fit: cover;
		width: 100%;
	}

	.about-us .content-box h3 {
		font-size: 30px;
	}

	.about-us .about-banner-engagement--slider .slick-dots {
		width: 100%;
	}

	.about-us .about-banner-engagement--slider .slick-next {
		right: 30%;
	}

	.about-us .about-banner-engagement--slider .slick-prev {
		left: 30%;
	}

	.about-us .about-us--banner-slider .about-content--card {
		transform: translate(0, 0);
		right: 18%;
		height: 45%;
		border-radius: 8px;
	}
}

@media (max-width: 600px) {

	.slider--container .slick-next,
	.slider--container .slick-prev {
		top: 134px !important;
	}

	.diamond-shape_slider.slider--container .slick-next,
	.diamond-shape_slider.slider--container .slick-prev {
		top: -28px !important;
	}

	.list-group-item-heading {
		width: 300px !important;
	}
}

@media (max-width: 525px) {
	.stepping-bar .stepping-bar--inner .stepping-bar-span--text {
		font-size: 12px !important;
	}

	.stepping-bar .stepping-bar--inner {
		flex: 0 0 53% !important;
	}
}

@media (max-width: 475px) {
	.image-container {
		height: 300px !important;
		width: 300px !important;
	}

	.image-container .slider-img {
		height: 300px !important;
	}

	.list-group-item-heading {
		width: 240px !important;
	}
}

@media (max-width: 425px) {
	.mobile-main-header .mobile-menu-toggler .mobile-menu-btn {
		margin-left: 12px;
	}

	.mobile-menu-toggler .unnotification--btn i {
		font-size: 20px;
	}

	.mobile-menu-toggler .notification--btn i {
		font-size: 20px;
	}

	.mobile-menu-toggler .notification--btn .notification,
	.mobile-main-header .mobile-menu-toggler .mini-cart-wrap .notification,
	.mobile-main-header .mobile-menu-toggler .mini-cart-heart .notification,
	.mobile-menu-toggler .unnotification--btn .notification {
		top: -3px;
		right: -2px;
	}

	.mobile-main-header .mobile-menu-toggler .mini-cart-wrap i {
		font-size: 20px;
	}

	.mobile-main-header .mobile-menu-toggler .mini-cart-heart i {
		font-size: 20px;
	}

	.engrave .engraved-content {
		max-width: 180px;
		height: 180px;
	}

	.engrave .engrave-left-wrapper .engraved-wrapper {
		height: 180px;
		width: 180px;
	}

	.list-group-item-heading {
		width: 200px !important;
	}

	.engrave .input-box {
		width: 220px;
	}

	.engrave .heading {
		font-size: 20px;
	}
}

@media (min-width: 320px) and (max-width: 390px) {
	.list-group-item-heading {
		width: 125px !important;
	}
}

@media (min-width: 820px) and (max-width: 915px) {
	.mobile-main-header .mobile-menu-toggler {
		width: 20%;
		justify-content: space-around;
	}

	.mobile-menu-toggler .unnotification--btn,
	.mobile-main-header .mobile-menu-toggler .mini-cart-wrap a,
	.mobile-main-header .mobile-menu-toggler .mini-cart-heart a {
		font-size: 24px;
	}

	.mobile-menu-toggler .unnotification--btn i,
	.mobile-main-header .mobile-menu-toggler .mini-cart-wrap a i,
	.mobile-main-header .mobile-menu-toggler .mini-cart-heart a i {
		display: block;
	}

	.mobile-menu-toggler .unnotification--btn .notification,
	.mobile-main-header .mobile-menu-toggler .mini-cart-wrap .notification {
		width: 16px;
		height: 16px;
	}

	.mobile-main-header .mobile-menu-toggler .mini-cart-wrap .notification,
	.mobile-main-header .mobile-menu-toggler .mini-cart-heart .notification {
		top: -3px;
	}

	.mobile-menu-toggler .unnotification--btn {
		margin-right: 0px;
	}
}

@media (min-width: 540px) and (max-width: 600px) {
	.mobile-main-header .mobile-menu-toggler .mini-cart-heart {
		margin-left: 0px;
	}

	.banner-content .banner-text1 {
		font-size: 22px;
	}

	.banner-content .banner-text2 {
		font-size: 18px;
	}

	.custom--padding-box {
		padding: 30px 0px 0px 0px;
	}
}

@media (min-width: 320px) and (max-width: 440px) {
	.mobile-menu-toggler .unnotification--btn {
		font-size: 22px;
	}

	.stepping-bar {
		height: auto !important;
		flex-wrap: wrap;
		flex-direction: row;
		max-width: 100% !important;
	}

	.stepping-bar .stepping-bar--inner.stepping-bar-left--content {
		margin-bottom: 8px;
	}

	.stepping-bar .stepping-bar--inner.stepping-bar-right--content,
	.stepping-bar .stepping-bar--inner.stepping-bar-left--content {
		border-radius: 8px !important;
		width: 100%;
		padding: 8px;
	}

	.stepping-bar .stepping-bar--inner {
		flex: 0 0 100% !important;
	}
}

@media (min-width: 330px) and (max-width: 390px) {
	.product-caption .product-name a {
		margin-top: 10px;
	}
}

@media (max-width: 1024px) {
	.stone--details.text-overlayRound1 {
		transform: translate(-50%, -16.5%);
	}

	.stone--details.text-overlayRound4 {
		transform: translate(6%, -50%);
	}

	.stone--details.text-overlayRound2 {
		transform: translate(-21%, 60%);
	}

	.stone--details.text-overlayRound3 {
		transform: translate(-10%, 0%);
	}

	.stone--details.text-overlayRound6 {
		transform: translate(-9%, -5.3%);
	}

	.stone--details.text-overlayOval1 {
		transform: translate(-50%, -15%);
	}

	.stone--details.text-overlayOval2 {
		transform: translate(-37%, 15%);
	}

	.stone--details.text-overlayOval3 {
		transform: translate(0%, 15%);
	}

	.stone--details.text-overlayOval4 {
		transform: translate(10%, 15%);
	}

	.stone--details.text-overlayOval5 {
		transform: translate(-23%, 15%);
	}

	.stone--details.text-overlayCushion1 {
		transform: translate(-30%, -15%);
	}

	.stone--details.text-overlayCushion3 {
		transform: translate(-15%, 0%);
	}

	.stone--details.text-overlayCushion5 {
		transform: translate(-20%, 0%);
	}

	.stone--details.text-overlayCushion4 {
		transform: translate(7%, 0%);
	}

	.stone--details.text-overlayPear2 {
		transform: translate(-20%, 0%);
	}

	.stone--details.text-overlayPear4 {
		transform: translate(5%, 0%);
	}

	.stone--details.text-overlayPear5 {
		transform: translate(-20%, 0%);
	}

	.stone--details.text-overlayPrincess1 {
		transform: translate(-30%, -20%);
	}

	.stone--details.text-overlayPrincess2 {
		transform: translate(-30%, 0%);
	}

	.stone--details.text-overlayPrincess4 {
		transform: translate(10%, 40%);
	}

	.stone--details.text-overlayPrincess5 {
		transform: translate(-20%, 0%);
	}

	.stone--details.text-overlayEmerald2 {
		transform: translate(-35%, 0%);
	}

	.stone--details.text-overlayEmerald3 {
		transform: translate(-15%, 0%);
	}

	.stone--details.text-overlayEmerald4 {
		transform: translate(8%, 0%);
	}

	.stone--details.text-overlayEmerald5 {
		transform: translate(-22%, 0%);
	}

	.stone--details.text-overlayEmerald6 {
		transform: translate(-22%, -15%);
	}

	.stone--details.text-overlayMarquise1 {
		transform: translate(-32%, -13%);
	}

	.stone--details.text-overlayMarquise2 {
		transform: translate(-40%, 5%);
	}

	.stone--details.text-overlayMarquise3 {
		transform: translate(-32%, 5%);
	}

	.stone--details.text-overlayMarquise5 {
		transform: translate(-32%, 5%);
	}

	.stone--details.text-overlayMarquise6 {
		transform: translate(-20%, -5%);
	}

	.stone--details.text-overlayAsscher1,
	.stone--details.text-overlayRadiant1,
	.stone--details.text-overlayHeart1 {
		transform: translate(10%, -10%);
	}

	.stone--details.text-overlayAsscher2,
	.stone--details.text-overlayRadiant2,
	.stone--details.text-overlayHeart2 {
		transform: translate(-40%, 5%);
	}

	.stone--details.text-overlayAsscher3,
	.stone--details.text-overlayRadiant3,
	.stone--details.text-overlayHeart3 {
		transform: translate(-17%, 5%);
	}

	.stone--details.text-overlayAsscher5,
	.stone--details.text-overlayRadiant5 {
		transform: translate(-23%, 5%);
	}

	.stone--details.text-overlayAsscher6,
	.stone--details.text-overlayRadiant6,
	.stone--details.text-overlayHeart6 {
		transform: translate(-20%, -10%);
	}

	.stone--details.text-overlayHeart4 {
		transform: translate(-10%, -50%);
	}

	.stone--details.text-overlayHeart5 {
		transform: translate(-35%, 5%);
	}
}

/* ====== {{ END:: Responsive CSS  }} ====== */